

 

import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { mediaService } from '../../../services/media.service';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));
  
    
const Results = ({ className, media, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const headCells = [
    {field: 'idMedia', headerName: 'Id Media',flex:1, description:'The id used for media.'},
    {field: 'mediaName', headerName: 'Media Name',  flex: 1, description:'The name of the media.',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={"button_campagne"}
            // style={{ marginLeft: 16 }}
            onClick={() => {  editName(params.row.idMedia,params)}}
          > {params.value}
          </Button>
        </div>
      ),},
    {field: 'mediaActive', headerName: 'Media active', flex: 1, description:'1 the media is active, 0 not.',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={"button_campagne"}
            // style={{ marginLeft: 16 }}
           onClick={() => {  editActive(params.row.idMedia,params)}}
          > {params.value==1?("Attivo"):("Disattivo")}
          </Button>
        </div>
      ), },
    {field: 'link', headerName: 'Link', flex:2, renderCell: (params) => (
         <strong>
           {params.row.mediaActive==1?(


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {navigator.clipboard.writeText(params.value)   } }
          >
            {params.value}
          </Button>):("Media disattivo")}


        </strong>
      ),
    },{field: 'linkApi', headerName: 'Link per attribute promo', flex:2, renderCell: (params) => (
        <strong>
          {params.row.mediaActive==1?(


            <Button
              variant="contained"
              className={"button_campagne"}
              size="small"
              // style={{ marginLeft: 16 }}
              onClick={() => {navigator.clipboard.writeText(params.value)   } }
            >
              {params.value}
            </Button>):("Media disattivo")}


        </strong>
      ),
    },    {field: 'download', headerName: 'download', flex:2, renderCell: (params) => (
        <strong>
          {params.row.mediaActive==1?(


            <Button
              variant="contained"
              className={"button_campagne"}
              size="small"
              // style={{ marginLeft: 16 }}
              onClick={() => {download(params)   } }
            >
              Download
            </Button>):("Media disattivo")}


        </strong>
      ),
    }
    ];  



  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    > 
    <PerfectScrollbar>
        <Box minWidth={1050}>
        <div id='allAward'>
        <DataGrid fontSize='7'
            columns={headCells} 
            rows={media}
            pageSize={50}
            rowsPerPageOptions={[5, 10, 20, 50]}
            showToolbar
            
            // components={{
            //   Toolbar: GridToolbar,
            // }}
            />
        </div> 
        </Box>
        
      </PerfectScrollbar>
      
    </Card>
  );
};
let editActive = (idMedia, p) => {

  const MySwal = withReactContent(Swal);

  const { value: newMediaActive } =  Swal.fire({
    title: 'Modifica Media Active per IdMedia ' + idMedia,
    html:"<select id='MediaActive' >\n" +
      "            <option value=\"1\">Attivo</option>\n" +
      "            <option value=\"0\">Disattivo</option>\n" +
      "\n" +
      "          </select>",
    showCancelButton: true,

  }).then((ret) =>{
    if (ret.isConfirmed){

      mediaService.UpdateMediaActive(p.row.idMedia,parseInt(document.getElementById("MediaActive").value)).then(data => {
        if(data==1) {
          const {value: newplafond} = Swal.fire({
            title: 'MediaActive Updato',
            showCancelButton: false,
          }).then((ret) => {
            window.location.reload();
          })

        }else{
          const { value: newplafond } =  Swal.fire({
            title: "Errore nell'update del MediaActive" ,
            showCancelButton: false,

          })
        }


      });

    }


  })



}



let download = (p) => {

  const link = document.createElement("a");
  link.href = p.row.link;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link)

}








let editName = (idMedia, p) => {

  const MySwal = withReactContent(Swal);

  const { value: newMediaActive } =  Swal.fire({
    title: 'Modifica Media Name per IdMedia ' + idMedia,
    input: 'text',
    inputLabel: 'Inserire il Media Name',
    inputValue: p.value,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'Inserire almeno un valore'
      }
    }
  }).then((ret) =>{
    if (ret.isConfirmed){

      mediaService.UpdateMediaName(p.row.idMedia,ret.value).then(data => {
        if(data==1) {
          const {value: newplafond} = Swal.fire({
            title: 'Media Name Updato',
            showCancelButton: false,
          }).then((ret) => {
            window.location.reload();
          })

        }else{
          const { value: newplafond } =  Swal.fire({
            title: "Errore nell'update del Media Name" ,
            showCancelButton: false,

          })
        }


      });

    }


  })



}

Results.propTypes = {
  className: PropTypes.string,
  media: PropTypes.array.isRequired
};

export default Results;
