import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { FaCarAlt } from 'react-icons/fa';
import { FaMotorcycle } from 'react-icons/fa';
import { ImTruck } from 'react-icons/im';
import { FaTractor } from 'react-icons/fa';

 

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const PracticesForBusiness = ({ className,
  ratioPracticesAuto,
  ratioPracticesAgri,
  ratioPracticesTruck,
  ratioPracticesMoto,
  ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();  

  const data = {
    datasets: [
      {
        data: [ratioPracticesAuto, ratioPracticesAgri, ratioPracticesTruck, ratioPracticesMoto],
        backgroundColor: [
          colors.lime[600],
          colors.blue[600],
          colors.green[600],
          colors.orange[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Auto', 'Agri', 'Truck', 'Moto']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  }; 
  const devices = [
    {
      title: 'Auto',
      value: ratioPracticesAuto,
      icon: FaCarAlt,
      color: colors.lime[600]
    },
    {
      title: 'Truck',
      value: ratioPracticesTruck,
      icon: ImTruck,
      color: colors.blue[600]
    },
    {
      title: 'Agri',
      value: ratioPracticesAgri,
      icon: FaTractor,
      color: colors.green[600]
    },
    {
      title: 'Moto',
      value: ratioPracticesMoto,
      icon: FaMotorcycle,
      color: colors.orange[600]
    }
    
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Pratiche per business" />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

PracticesForBusiness.propTypes = {
  className: PropTypes.string
};

export default PracticesForBusiness;
