import configData from './config.json';
import {authHeader, handleResponse, handleDownload, API_URL} from '../helpers';
import { downloadService} from 'src/services/download.service';

export const voucherService = { 
    GetVoucherByCode,
    GetVoucherByReseller,
    CreateVoucherFile,
    GetVoucherFileById
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();

function GetVoucherByCode(VoucherCode, IdReseller) {
    return guardedPromise("GetPromoByVoucher", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/voucher/utility/get-voucher-by-codeVoucher?CodeVoucher=${VoucherCode}&IdReseller=${IdReseller}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for campaigns");
        })
        .catch(error => reject(error));
}));
}

function GetVoucherByReseller(IdReseller) {
    return guardedPromise("GetVoucherByReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/VouchersCreateVirtualLog/utility/get-voucher-by-reseller?IdReseller=${IdReseller}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response");
        })
        .catch(error => reject(error));
}));
} 
 

function CreateVoucherFile(values, IdCurrentUser) { 
    var IdPromo = Number(values?.IdPromo);
    var QtaVoucher = Number(values.QtaVoucher) ;
    var IdPromoAward = values.IdPromoAward;
    var IdReseller = IdCurrentUser;
    

    return guardedPromise("CreateVoucherFile", () => new Promise((resolve, reject) => { 
        const requestOptions = {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            headers: {'Content-Type': 'application/json', ...authHeader()},     
            // funziona // headers: {'Content-Type': 'application/json',  'Authorization': `Bearer ${authHeaderShort()}`},     
            // headers: authHeader(),     
            body: JSON.stringify({ IdPromo, 
                QtaVoucher, 
                IdPromoAward,
                IdReseller
            })
        };
        fetch(`${API_URL}/Voucher/utility/create-voucher-file/`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
        }));
}



function GetVoucherFileById(LogId, PromoId, UserId, VoucherFileName) {
    return guardedPromise("GetVoucherFileById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/VouchersCreateVirtualLog/utility/get-voucher-file/?IdLog=${LogId}&IdReseller=${UserId}&IdPromo=${PromoId}&VoucherFileName=${VoucherFileName}`, requestOptions)
        .then(handleDownload)
        .then(blob => {
            if (blob.type=='application/pdf') { 
                downloadService.downloadVoucherFile(blob, VoucherFileName);
                return resolve(blob);
            }
            if (blob.type=='application/json') { 
                return resolve();
            }
        })
        .catch(error => reject(error));
    }));
}





// function download(blob, ReportName) {
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.style.display = 'none';
//     a.href = url;
//     // the filename you want
    
//     var currentdate = new Date().toLocaleDateString('it-IT', {
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric',
//       });
//     a.download = ReportName + ' ' + currentdate;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//     window.URL.revokeObjectURL(url);
//   }
