import configData from './config.json';
import {authHeader, handleResponse, authHeaderShort, API_URL} from '../helpers';


export const awardService = {
    GetAllAward,
    GetAwardById,
    GetPromoAward,
    CreateAward,
    UpdateAward
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();

function GetAllAward() {
        return guardedPromise("GetAllAward", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/award`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
    }));
}

function GetAwardById(AwardId) {
    return guardedPromise("GetAwardById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/award/`+AwardId, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for awards");
        })
        .catch(error => reject(error));
    }));
}

function GetPromoAward(PromoId) {
    return guardedPromise("GetPromoAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/Promo/${PromoId}/Awards`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for awards");
        })
        .catch(error => reject(error));
    }));
}
 

function CreateAward(values) {
    var AwardName =  values.AwardName;
    var AwardDescription =  values.AwardDescription; 
 

    return guardedPromise("CreateAward", () => new Promise((resolve, reject) => {
         console.log(authHeader());
        const requestOptions = {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            headers: {'Content-Type': 'application/json', ...authHeader()},     
            // funziona // headers: {'Content-Type': 'application/json',  'Authorization': `Bearer ${authHeaderShort()}`},     
            // headers: authHeader(),     
            body: JSON.stringify({ AwardName, AwardDescription})
        };
        console.log(`${API_URL}/award/`);
        console.log(requestOptions);
        fetch(`${API_URL}/award/`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    console.log(responseData);
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
        }));
}






function UpdateAward(AwardId, values) {
    var AwardName =  values.AwardName;
    var AwardDescription =  values.AwardDescription; 

    console.log("dati "+JSON.stringify(values))

    return guardedPromise("UpdateAward", () => new Promise((resolve, reject) => {
        // const requestOptions = { method: 'GET', headers: authHeader() };

        const requestOptions = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            // headers: [{'Content-Type': 'application/json'}, authHeader()],     
            headers: {'Content-Type': 'application/json',  ...authHeader()},     
            // headers: authHeader(),     
            body: JSON.stringify({ AwardName, AwardDescription})
        };
        console.log(requestOptions);
        fetch(`${API_URL}/award/`+AwardId, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    console.log(responseData);
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
        }));
}
