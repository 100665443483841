import React,  { useState }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'; 
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';
import { DatePickerField } from '../../../../FormFields';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginTop: theme.spacing(3)
  },
}));


export default function CommonCampaignData(props) {
  const {
    formField: {     
      PromoInternalName,
      PromoExternalName,
      CodiceAziendaleConvenzionePromoAttribute,
      NomeAziendaConvenzionePromoAttribute,
      DataInizioRichiestaCodicePromoAttribute,
      DataFineRichiestaCodicePromoAttribute,
      DataFineRiscossioneCodicePromoAttribute,
      DataFineRiscossioneCodice_FirstopPromoAttribute,
      DominiAziendaliEmailConvenzionePromoAttribute
   }
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
    

  const HandleSubmit = (e) => {
    e.preventDefault();
    // navigate('/registration', {codeConvention}, { replace: true });
   // codeConventionService.check(codeConvention.value);
    // authenticationService.login(account.username, account.password)
    //     .then(
    //         user => {
    //           // console.log(this.props.location.state);
    //           //   const { from } = this.props.location.state || { from: { pathname: "/" } };
    //           //   this.props.history.push(from);
    //           navigate('/app/dashboard', { replace: true });
    //         },
    //         error => {
    //           hasError = error;
    //           console.log(hasError);
    //         }
    //     );
  }

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
  }

  return (
    <Grid item xs={12} md={12} spacing={5}>
    {/* <Typography variant="h6" className={classes.typografy}>
      DATI GENERALI CONVENZIONE
    </Typography> */}
    {/* <Typography variant="h8" gutterBottom>
      INSERISCI I TUOI DATI PER RICEVERE IL VOUCHER
    </Typography>  */}
    <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={12}>
            </Grid>
          <Grid item xs={12} sm={4}> 
              <InputField name={PromoInternalName.name} label={PromoInternalName.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField name={PromoExternalName.name} label={PromoExternalName.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField name={CodiceAziendaleConvenzionePromoAttribute.name} label={CodiceAziendaleConvenzionePromoAttribute.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}> 
              <DatePickerField
                name={DataInizioRichiestaCodicePromoAttribute.name}
                label={DataInizioRichiestaCodicePromoAttribute.label}
                format="dd/MM/yyyy HH:mm"
                // minDate={new Date()}
                // maxDate={new Date('2050/12/31')}
                fullWidth
          />
            </Grid>
            <Grid item xs={12} sm={6}> 
              <DatePickerField
                name={DataFineRichiestaCodicePromoAttribute.name}
                label={DataFineRichiestaCodicePromoAttribute.label}
                format="dd/MM/yyyy HH:mm"
                // minDate={new Date()}
                // maxDate={new Date('2050/12/31')}
                fullWidth
          />
            </Grid>
            <Grid item xs={12} sm={6}> 
              <DatePickerField
                name={DataFineRiscossioneCodicePromoAttribute.name}
                label={DataFineRiscossioneCodicePromoAttribute.label}
                format="dd/MM/yyyy HH:mm"
                // minDate={new Date()}
                // maxDate={new Date('2050/12/31')}
                fullWidth
          />
            </Grid>
            <Grid item xs={12} sm={6}>
            <DatePickerField
                name={DataFineRiscossioneCodice_FirstopPromoAttribute.name}
                label={DataFineRiscossioneCodice_FirstopPromoAttribute.label}
                format="dd/MM/yyyy HH:mm"
                fullWidth
          />
               
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputField name={DominiAziendaliEmailConvenzionePromoAttribute.name} label={DominiAziendaliEmailConvenzionePromoAttribute.label} fullWidth />
            </Grid>
           
      </Grid> 
</Grid>
  );
}

