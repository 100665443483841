import * as Yup from 'yup';
import moment from 'moment';
import editPracticeFormModel from './editPracticeFormModel';
const {
  formField: { 
    IdPromo,
    PromoInternalName,
    PromoExternalName,     
    ProspectName,
    VoucherCode,
    AwardRefund,
    DateIns, 
    PracticeNrBo,
    PracticeStateBkb,
    QtaPneumaticiUno,
    QtaPneumaticiDue,
    QtaPneumaticiTre
  }
} = editPracticeFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  // Yup.object().shape({
  //   [VoucherCode.name]: Yup.string()
  //         .required(`${VoucherCode.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [IdPromo.name]: Yup.string()
  //         .required(`${IdPromo.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [PromoInternalName.name]: Yup.string()
  //         .required(`${PromoInternalName.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [PromoExternalName.name]: Yup.string()
  //         .required(`${PromoExternalName.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [IdProspect.name]: Yup.string()
  //         .required(`${IdProspect.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [ProspectName.name]: Yup.string()
  //         .required(`${ProspectName.requiredErrorMsg}`)
  // })
];

 