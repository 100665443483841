import * as Yup from 'yup';
import moment from 'moment';
import campaignFormModel from './campaignFormModel';
const {
  formField: {
    PromoInternalName,
    PromoExternalName,
    CodiceAziendaleConvenzionePromoAttribute,
    NomeAziendaConvenzionePromoAttribute,
    DataInizioRichiestaCodicePromoAttribute,
    DataFineRichiestaCodicePromoAttribute,
    DataFineRiscossioneCodicePromoAttribute,
    DataFineRiscossioneCodice_FirstopPromoAttribute,
    DominiAziendaliEmailConvenzionePromoAttribute
  }
} = campaignFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;


export default [
  // Yup.object().shape({
  //   [PromoInternalName.name]: Yup.string()
  //         .required(`${PromoInternalName.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [PromoExternalName.name]: Yup.string()
  //         .required(`${PromoExternalName.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [CodiceAziendaleConvenzionePromoAttribute.name]: Yup.string()
  //         .required(`${CodiceAziendaleConvenzionePromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [NomeAziendaConvenzionePromoAttribute.name]: Yup.string()
  //         .required(`${NomeAziendaConvenzionePromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [DataInizioRichiestaCodicePromoAttribute.name]: Yup.date()
  //         .required(`${DataInizioRichiestaCodicePromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [DataFineRichiestaCodicePromoAttribute.name]: Yup.date()
  //         .required(`${DataFineRichiestaCodicePromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [DataFineRiscossioneCodicePromoAttribute.name]: Yup.date()
  //         .required(`${DataFineRiscossioneCodicePromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [DataFineRiscossioneCodice_FirstopPromoAttribute.name]: Yup.date()
  //         .required(`${DataFineRiscossioneCodice_FirstopPromoAttribute.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [DominiAziendaliEmailConvenzionePromoAttribute.name]: Yup.string()
  //         .required(`${DominiAziendaliEmailConvenzionePromoAttribute.requiredErrorMsg}`),
  // }),
];





    // ,
    // [zipcode.name]: Yup.string()
    //   .required(`${zipcode.requiredErrorMsg}`)
    //   .test(
    //     'len',
    //     `${zipcode.invalidErrorMsg}`,
    //     val => val && val.length === 5
    //   ),
    // [country.name]: Yup.string()
    //   .nullable()
    //   .required(`${country.requiredErrorMsg}`)
  
  // Yup.object().shape({
  //   [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
  //   [cardNumber.name]: Yup.string()
  //     .required(`${cardNumber.requiredErrorMsg}`)
  //     .matches(visaRegEx, cardNumber.invalidErrorMsg),
  //   [expiryDate.name]: Yup.string()
  //     .nullable()
  //     .required(`${expiryDate.requiredErrorMsg}`)
  //     .test('expDate', expiryDate.invalidErrorMsg, val => {
  //       if (val) {
  //         const startDate = new Date();
  //         const endDate = new Date(2050, 12, 31);
  //         if (moment(val, moment.ISO_8601).isValid()) {
  //           return moment(val).isBetween(startDate, endDate);
  //         }
  //         return false;
  //       }
  //       return false;
  //     }),
  //   [cvv.name]: Yup.string()
  //     .required(`${cvv.requiredErrorMsg}`)
  //     .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  // })

