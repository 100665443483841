import configData from './config.json';
import {authHeader, handleResponse, handleDownload, API_URL} from '../helpers';
import { getBase64 } from '../utils/utility';
import { downloadService} from 'src/services/download.service';

export const practiceService = {
    GetAllPractice,
    GetAllPracticeBo,
    GetPractice,
    GetPracticeBo,
    CreatePractice,
    GetPracticeAttachment,
    EditPractice,
    EditPracticeBo,
    GetGiustificativoRetail,
    GetGiustificativo
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();

function GetAllPractice() {
        return guardedPromise("GetAllPractice", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/practice`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for practices");
            })
            .catch(error => reject(error));
    }));
} 


function GetAllPracticeBo(currentUserId, selectedPracticeStateIds,promoSelected) {

  return guardedPromise("GetAllPractice", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
        if (selectedPracticeStateIds.length == 0) {
            selectedPracticeStateIds = ['None'];
        }
        else if (selectedPracticeStateIds.length == 6) {
            selectedPracticeStateIds = ['All'];
        }
    if(promoSelected==0){
        fetch(`${API_URL}/practice/internal/practiceByState/${selectedPracticeStateIds.join(';')}`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for practices");
            })
            .catch(error => reject(error));
    } else{

      fetch(`${API_URL}/practice/GetPraticeByIdPromo?IdPromo=`+promoSelected+"&SelectedPracticeState="+selectedPracticeStateIds.join(';'), requestOptions)
        .then(handleResponse)
        .then(responseData => {
          if (responseData) {
            return resolve(responseData);
          }
          reject("Empty Response for practices");
        })
        .catch(error => reject(error));

    }
}));
} 

function GetPractice(IdPractice) {
    return guardedPromise("GetPractice", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/practice/${IdPractice}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for practices");
        })
        .catch(error => reject(error));
}));
} 


function CreatePractice(values, IdUser, IdAward) { 
    var IdPromo = values.IdPromo;
    var CodeVoucher = values.VoucherCode;
    var IdProspect = values.IdProspect;
    var IdReseller = IdUser;
    var IdPromoAward1 = IdAward;
    var IdPromoAward2 = 0;
    var IdPromoAward3 = 0;

    return guardedPromise("CreatePractice", () => new Promise((resolve, reject) => { 
        const requestOptions = {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            headers: {'Content-Type': 'application/json', ...authHeader()},     
            // funziona // headers: {'Content-Type': 'application/json',  'Authorization': `Bearer ${authHeaderShort()}`},     
            // headers: authHeader(),     
            body: JSON.stringify({ IdPromo, 
                CodeVoucher,
                IdProspect,
                IdReseller,
                IdPromoAward1,
                IdPromoAward2,
                IdPromoAward3
            })
        };
        fetch(`${API_URL}/practice/`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
        }));
}

function GetPracticeAttachment(idPractice, IdReseller, IdPromo, IdPracticeAttachment, fileNameAttachment) {
    return guardedPromise("GetPracticeAttachment", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
     
    fetch(`${API_URL}/practice/${idPractice}/Attachment?IdReseller=${IdReseller}&IdPromo=${IdPromo}&IdPracticeAttachment=${IdPracticeAttachment}`, requestOptions)
        .then(handleDownload)
        .then(blob => {
            if (blob) {
                downloadService.downloadAttachment(blob, fileNameAttachment);
                return resolve();
            } 
        })
        .catch(error => reject(error));
}));
} 


function EditPractice(id, idUser, values, dropzoneAreaAttachments, practiceAttachments) { 
    var IdPractice = id;
    var IdPromo = values.IdPromo;
    var IdReseller = idUser;
    var Qta1 = values.QtaPneumaticiUno;
    var Qta2 = values.QtaPneumaticiDue;
    var Qta3 = values.QtaPneumaticiTre;
    var IdPromoTyre1 = values.ModelPneumaticiUno?.id;
    var IdPromoTyre2 = values.ModelPneumaticiDue?.id;
    var IdPromoTyre3 = values.ModelPneumaticiTre?.id;
    return guardedPromise("EditPractice", () => new Promise((resolve, reject) => {
        const sendData = (data) => {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', ...authHeader() },
                body: JSON.stringify(data)
            };
            fetch(`${API_URL}/practice/internal/edit`, requestOptions)
                .then(handleResponse)
                .then(responseData => {
                    if (responseData) {
                        return resolve(responseData);
                    }
                    reject("Empty Response for Edit");
                })
                .catch(error => reject(error));
        }
        const dataToSend = {
            IdPractice,
            IdPromo,
            IdReseller,
            Qta1,
            Qta2,
            Qta3,
            IdPromoTyre1,
            IdPromoTyre2,
            IdPromoTyre3
        };

        let promises = dropzoneAreaAttachments.map(async file =>  {
            const fileBased64 = await getBase64(file);
            return Promise.resolve({ fileName: fileBased64.fileName, base64: fileBased64.base64, status: "added" });
        });

        Promise.all(promises).then(files => {
            dataToSend.files = files;
            const practiceAttachmentsToSend = practiceAttachments.map(attachment => { return { fileName: attachment.fileName, status: attachment.status } });
            if (practiceAttachmentsToSend && practiceAttachmentsToSend.length>0)
            {
                dataToSend.files.push(...practiceAttachmentsToSend);
            }
            
            sendData(dataToSend);
        }, reason => {
            console.log(reason);
        });
    }));
}




function GetPracticeBo(IdPractice) {
    return guardedPromise("GetPracticeBo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/practice/internal/${IdPractice}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for practices");
        })
        .catch(error => reject(error));
}));
}




function GetGiustificativoRetail(IdPromo,IdReseller) {
  return guardedPromise("GetGiustificativoRetail", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/practice/GetGiustificativoRetail?IdPromo=`+IdPromo+`&IdReseller=`+IdReseller, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for practices");
      })
      .catch(error => reject(error));
  }));
}

function GetGiustificativo(IdPromo,IdReseller) {
  return guardedPromise("GetGiustificativo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/practice/GetGiustificativo?IdPromo=`+IdPromo+`&IdReseller=`+IdReseller, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for practices");
      })
      .catch(error => reject(error));
  }));
}












function EditPracticeBo(id, idUser, values, dropzoneAreaAttachments, practiceAttachments) { 
    var IdPractice = id;
    var IdUser = idUser;
    var IdPromo = values.IdPromo;
    var IdReseller = values.IdReseller;

    
    var PracticeState = values.PracticeState.id;
    var PracticeNote = values.PracticeNote;
    var PurchaseAmount = parseFloat(values?.PurchaseAmount>0?values?.PurchaseAmount:0);
    var AwardRefund = parseFloat(values?.AwardRefund>0?values?.AwardRefund:0);
 

    var Qta1 = values.QtaPneumaticiUno;
    var Qta2 = values.QtaPneumaticiDue;
    var Qta3 = values.QtaPneumaticiTre;
    var IdPromoTyre1 = values.ModelPneumaticiUno?.id;
    var IdPromoTyre2 = values.ModelPneumaticiDue?.id;
    var IdPromoTyre3 = values.ModelPneumaticiTre?.id;

    var InvoiceService1Attribute = values.InvoiceService1Attribute;
    var InvoiceService2Attribute = values.InvoiceService2Attribute;
    
    return guardedPromise("EditPracticeBo", () => new Promise((resolve, reject) => {
        const sendData = (data) => {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', ...authHeader() },
                body: JSON.stringify(data)
            };
            fetch(`${API_URL}/practice/internal/edit-bo`, requestOptions)
                .then(handleResponse)
                .then(responseData => {
                    if (responseData) {
                        return resolve(responseData);
                    }
                    reject("Empty Response for Edit");
                })
                .catch(error => reject(error));
        }
 

        const dataToSend = {
            IdPractice,
            IdReseller,
            IdPromo,
            IdUser,
            PracticeState,
            PracticeNote,
            PurchaseAmount,
            AwardRefund,
            Qta1,
            Qta2,
            Qta3,
            IdPromoTyre1,
            IdPromoTyre2,
            IdPromoTyre3,
            InvoiceService1Attribute,
            InvoiceService2Attribute,
    

        };

        let promises = dropzoneAreaAttachments.map(async file =>  {
            const fileBased64 = await getBase64(file);
            return Promise.resolve({ fileName: fileBased64.fileName, base64: fileBased64.base64, status: "added" });
        });

        Promise.all(promises).then(files => {
            dataToSend.files = files;
            const practiceAttachmentsToSend = practiceAttachments.map(attachment => { return { fileName: attachment.fileName, status: attachment.status } });
            if (practiceAttachmentsToSend && practiceAttachmentsToSend.length>0)
            {
                dataToSend.files.push(...practiceAttachmentsToSend);
            }
            
            sendData(dataToSend);
        }, reason => {
            console.log(reason);
        });
    }));
}
