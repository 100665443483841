import configData from './config.json';
import {API_URL, authHeader, handleResponse} from '../helpers';
import moment from 'moment';

export const conventionService = {
    GetAllConvention,
    GetConventionById,
    CreateConvention,
    UpdateConvention
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  

const guardedPromise = preventConcurrentExec();


function GetAllConvention(OnlyOpenView) {
        return guardedPromise("GetAllConvention", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/convention?OnlyOpenView=`+OnlyOpenView, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for conventions");
            })
            .catch(error => reject(error));
    }));
}

function GetConventionById(ConventionId) {
    return guardedPromise("GetConventionById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/convention/`+ConventionId, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for conventions");
        })
        .catch(error => reject(error));
    }));
} 

function CreateConvention(values,checked,mediaImg,mediaLogo, mediaEmail,numMaxRegistrazioni,infoConvenzione,awardsData, resellersData, tyresData) {
    var IdPromo = 0;
    var PromoInternalName = values.PromoInternalName;
    var PromoExternalName = values.PromoExternalName;
    var CompanyConventionCodePromoAttribute = values.CompanyConventionCodePromoAttribute;
    var CompanyNameConventionPromoAttribute = values.CompanyNameConventionPromoAttribute;
    var CompanyEmailDomainConventionPromoAttribute
    if(checked) {
      CompanyEmailDomainConventionPromoAttribute ='*'
    }else{
      CompanyEmailDomainConventionPromoAttribute = values.CompanyEmailDomainConventionPromoAttribute;
    }
    var StartVoucherRequestPromo = moment(values.StartVoucherRequestPromo).format('YYYY-MM-DD');
    var EndVoucherRequestPromo = moment(values.EndVoucherRequestPromo).format('YYYY-MM-DD');
    var EndVoucherRedemeedPromo = moment(values.EndVoucherRedemeedPromo).format('YYYY-MM-DD');
    var EndVoucherRedemeedFirstopPromo = moment(values.EndVoucherRedemeedFirstopPromo).format('YYYY-MM-DD');
    var StartViewPromo = moment(values.StartViewPromo).format('YYYY-MM-DD');
    var EndViewPromo = moment(values.EndViewPromo).format('YYYY-MM-DD');
    var EndPracticeRbo = moment(values.EndPracticeRbo).format('YYYY-MM-DD');
    var mediaImg=mediaImg+""
    var mediaLogo=mediaLogo+""
    var mediaEmail=mediaEmail+""
    var numMaxRegistrazioni=numMaxRegistrazioni+""
    var infoConvenzione=infoConvenzione+""
    var AwardSetRequest = awardsData.map(
        a => {
            return {
                IdAward: a.idAward,
                AwardSelected: a.awardSelected,
                AwardValue: a.awardValue > 0 ? a.awardValue : 0,
                AwardOnlyForFs: a.awardOnlyForFs,
                AwardPreSelected: a.awardPreSelected
            }
        }
    );

    var ResellerSetRequest = resellersData.map(
        r => {
            return {
                IdReseller: r.idReseller,
                ResellerSelected: r.resellerSelected, 
            }
        }
    );

    var TyreSetRequest = tyresData.map(
        t => {
            return {
                IdTyre: t.idTyre,
                TyreSelected: t.tyreSelected
            }
        }
    );
    return guardedPromise("CreateConvention", () => new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', ...authHeader()},     
            body: JSON.stringify({  
                PromoInternalName,
                PromoExternalName,
                CompanyConventionCodePromoAttribute,
                CompanyNameConventionPromoAttribute,
                CompanyEmailDomainConventionPromoAttribute,
                mediaImg,
                mediaLogo,
                mediaEmail,
                numMaxRegistrazioni,
                infoConvenzione,
                StartVoucherRequestPromo,
                EndVoucherRequestPromo,
                EndVoucherRedemeedPromo,
                EndVoucherRedemeedFirstopPromo,
                StartViewPromo,
                EndViewPromo,
                EndPracticeRbo,
                
                
                AwardSetRequest,
                ResellerSetRequest,
                TyreSetRequest

            })
        };

        fetch(`${API_URL}/convention/`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for convention");
            })
            .catch(error => reject(error));
        }));
}
 
function UpdateConvention(IdConvention, values,checked,mediaImg,mediaLogo,mediaEmail, numMaxRegistrazioni,infoConvenzione,awardsData, resellersData, tyresData) {
    var IdPromo = 0;
    var PromoInternalName = values.PromoInternalName;
    var PromoExternalName = values.PromoExternalName;
    var CompanyConventionCodePromoAttribute = values.CompanyConventionCodePromoAttribute;
    var CompanyNameConventionPromoAttribute = values.CompanyNameConventionPromoAttribute;
    var CompanyEmailDomainConventionPromoAttribute
    if(checked) {
      CompanyEmailDomainConventionPromoAttribute ='*'
    }else{
      CompanyEmailDomainConventionPromoAttribute = values.CompanyEmailDomainConventionPromoAttribute;
    }
    var StartVoucherRequestPromo = moment(values.StartVoucherRequestPromo).format('YYYY-MM-DD');
    var EndVoucherRequestPromo = moment(values.EndVoucherRequestPromo).format('YYYY-MM-DD');
    var EndVoucherRedemeedPromo = moment(values.EndVoucherRedemeedPromo).format('YYYY-MM-DD');
    var EndVoucherRedemeedFirstopPromo = moment(values.EndVoucherRedemeedFirstopPromo).format('YYYY-MM-DD');
    var StartViewPromo = moment(values.StartViewPromo).format('YYYY-MM-DD');
    var EndViewPromo = moment(values.EndViewPromo).format('YYYY-MM-DD');
    var EndPracticeRbo = moment(values.EndPracticeRbo).format('YYYY-MM-DD');
    var mediaImg=mediaImg+""
    var mediaLogo=mediaLogo+""
    var mediaEmail=mediaEmail+""
    var numMaxRegistrazioni=numMaxRegistrazioni+""
    var infoConvenzione=infoConvenzione+""

    var AwardSetRequest = awardsData.map(
        a => {
            return {
                IdAward: a.idAward,
                AwardSelected: a.awardSelected,
                AwardValue: a.awardValue > 0 ? a.awardValue : 0,
                AwardOnlyForFs: a.awardOnlyForFs,
                AwardPreSelected: a.awardPreSelected
            }
        }
    );

    var ResellerSetRequest = resellersData.map(
        r => {
            return {
                IdReseller: r.idReseller,
                ResellerSelected: r.resellerSelected, 
            }
        }
    );

    var TyreSetRequest = tyresData.map(
        t => {
            return {
                IdTyre: t.idTyre,
                TyreSelected: t.tyreSelected
            }
        }
    );
 

    return guardedPromise("UpdateConvention", () => new Promise((resolve, reject) => {
        // const requestOptions = { method: 'GET', headers: authHeader() };

        const requestOptions = {
            method: 'PUT',  
            headers: {'Content-Type': 'application/json',  ...authHeader()},   
            body: JSON.stringify({  
                PromoInternalName,
                PromoExternalName,
                CompanyConventionCodePromoAttribute,
                CompanyNameConventionPromoAttribute,
                CompanyEmailDomainConventionPromoAttribute,
                mediaImg,
                mediaLogo,
                mediaEmail,
                numMaxRegistrazioni,
                infoConvenzione,
                StartVoucherRequestPromo,
                EndVoucherRequestPromo,
                EndVoucherRedemeedPromo,
                EndVoucherRedemeedFirstopPromo,
                StartViewPromo,
                EndViewPromo,
                EndPracticeRbo,
                
                
                AwardSetRequest,
                ResellerSetRequest,
                TyreSetRequest})
        }; 

 
        fetch(`${API_URL}/convention/`+IdConvention, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for convention");
            })
            .catch(error => reject(error));
        }));
}

