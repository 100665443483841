import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';

import { resellerService } from '../../../services/reseller.service';
import { authenticationService } from 'src/services/authentication.service';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  error: {
    alignItems: 'left',
    color: colors.red[600],
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  ok: {
    alignItems: 'left',
    color: colors.green[600],
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1)
  }
}));

 

const Iban = ({ className, ...rest }) => {
  const classes = useStyles();
  const [iban, setIban] = useState();
  let [isSubmitting, setIsSubmitting] = useState(false); 
  let [hasError, setHasError] = useState({
    value: false,
    description:''
  });
  let [isOk, setIsOk] = useState({
    value: false,
    description:''
  });
  const [currentUser, setCurrentUser] = useState();
   
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => { 
    if (!currentUser) return;
    if (currentUser.role!='Reseller') return;
    console.log(currentUser);
    resellerService.GetReseller(currentUser.id).then((data) => {
      setIban(data?.iban)
    });    
  }, [currentUser])
 
  
  let handleChange = (e) => {  
    let value = e.target.value; 
    setIban(value);
  }
  
 

  let handleSubmit = (event) => {
    setIsSubmitting(true);    
    setHasError({value:false, description: ''});
    setIsOk({value:false, description: ''});
    event.preventDefault();
    resellerService.UpdateReseller(currentUser.id, iban)
      .then(() => {
        setIsOk({value:true, description: 'Salvataggio effettuato con successo.'}); 
      })
      .catch(error => {
        setHasError({value:true, description: error});
            
      });
        
    
  }

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Aggiornamento Iban"
          title="Iban"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Iban"
            margin="normal"
            name="iban"
            onChange={handleChange}
             type="text"
            value={iban}
             variant="outlined" 
             InputLabelProps={{ shrink: true }}
          />

          
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {hasError.value && (
            <div className={classes.error}>{hasError.description}</div>
          )}
          {isOk.value && (
            <div className={classes.ok}>{isOk.description}</div>
          )}
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Aggiorna
          </Button>
 
        </Box>
      </Card>
    </form>
  );
};

Iban.propTypes = {
  className: PropTypes.string
};

export default Iban;
