import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link
} from '@material-ui/core';



import moment from 'moment';
import { Formik, Form } from 'formik';

import { useParams, useNavigate } from 'react-router-dom';

import validationSchema from './FormModel/validationSchema';
import conventionFormModel from './FormModel/conventionFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonConventionData from './Forms/CommonConventionData';
import AwardsJoined from './Forms/AwardsJoined';  
import ResellersJoined from './Forms/ResellersJoined';
import TyresJoined from './Forms/TyresJoined';

import { mediaService } from '../../../services/media.service';
import { authenticationService } from '../../../services/authentication.service';
import { conventionService } from '../../../services/convention.service';
import { promoService } from '../../../services/promo.service';
import { downloadUploadService } from '../../../services/downloadUpload.service';
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(1)
    },
  }));

const steps = ['Dati generali', 'Premio', 'Rivenditori', 'Pneumatici']; 
const { formId, formField } = conventionFormModel;


let _mediaDataLogo=[]
let _mediaDataImg=[]
let _mediaDataEmail=[]


//let checkMedia=true;


export default function AddEditConvention() {

  let [checkEmail, setCheckEmail] = useState(false);
  const navigate = useNavigate();
  const { ConventionId } = useParams();
  const isAddMode = !ConventionId;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  //mediaDataLogo="";
  //mediaDataImg="";

  const [checkMedia, setCheckMedia] =useState(false);
  const [checkMaxRegistrazioni, setCheckMaxRegistrazioni] = useState(false);

  const [checkInfo, setCheckInfo] = useState(false);
  const [infoConvenzione, setInfoConvenzione] = useState("");

  const [currentUser, setCurrentUser] = useState();


  const [mediaDataImg, setMediaDataImg] = useState([]);
  const [mediaDataLogo, setMediaDataLogo] = useState([]);
  const [mediaDataEmail, setMediaDataEmail] = useState([]);
  const [mediaImg, setMediaImg] = useState(-1);
  const [mediaLogo, setMediaLogo] = useState(-1);
  const [mediaEmail, setMediaEmail] = useState(-1);

  const [numMaxRegistrazioni, setNumMaxRegistrazioni] = useState(2);


  let [conventionData, setConventionData] = useState({});

  let [resellersData, setResellersData] = useState([]);
  let [awardsData, setAwardsData] = useState([]);
  let [tyresData, setTyresData] = useState([]);
  
  const [isUploading, setIsUploading] = useState(false);

  let [hasError, setHasError] = useState({
    value: false,
    description:''
});


 

 
  
function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <CommonConventionData
          formField={formField} 
          conventionId={ConventionId}
          setCheckEmail={setCheckEmail}
          checkEmail={checkEmail}
          checkMedia={checkMedia}

          //
          mediaDataLogo={mediaDataLogo}
          mediaDataImg={mediaDataImg}
          mediaDataEmail={mediaDataEmail}

          mediaLogo={mediaLogo}
          mediaEmail={mediaEmail}
          mediaImg={mediaImg}
          setMediaLogo={setMediaLogo}
          setMediaEmail={setMediaEmail}
          setMediaImg={setMediaImg}
          numMaxRegistrazioni={numMaxRegistrazioni}
          setNumMaxRegistrazioni={setNumMaxRegistrazioni}
          checkMaxRegistrazioni={checkMaxRegistrazioni}

          checkInfo={checkInfo}
          infoConvenzione={infoConvenzione}
          setInfoConvenzione={setInfoConvenzione}

        />;
      case 1:
        return <AwardsJoined
          formField={formField}
          conventionId={ConventionId}
          AwardsData={awardsData}
          HandleAwardSwitch={handleAwardSwitch} 
          HandleAwardText={handleAwardText}
          HandleAwardCheckboxOnlyFS={handleAwardCheckboxOnlyFS} 
          HandleAwardCheckboxPreSelected={handleAwardCheckboxPreSelected}  />;
      case 2:
        return <ResellersJoined
          formField={formField}
          conventionId={ConventionId}
          ResellersData={resellersData}
          HandleResellerChange={handleResellerChange}
          DownloadModel={downloadModel}
          UploadModel={uploadModel} 
          IsUploading={isUploading}  />;
      case 3:
        return <TyresJoined
        formField={formField}
        conventionId={ConventionId}
        TyresData={tyresData}
        HandleTyreChange={handleTyreChange}
        DownloadModel={downloadModel}
        UploadModel={uploadModel} 
        IsUploading={isUploading} />;
      default:
        return <div>Not Found</div>;
    }
  } 

  const handleAwardSwitch = (e) => {
    let id = e.target.name; 
    let checked = e.target.checked;
    let tempAward = [...awardsData]; 
    const currentIndex = tempAward.findIndex((award => award.idAward == id)); 
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardSelected = checked;
    if (tempAward[currentIndex].awardSelected === false) 
    {
      tempAward[currentIndex].awardValue = '';
      tempAward[currentIndex].awardOnlyForFs = false;
      tempAward[currentIndex].awardPreSelected = false;
      
    }
     
    setAwardsData(tempAward); 
  };

  const handleAwardText = (e) => {
    let id = e.target.name;
    let value = e.target.value;  
    let tempAward = [...awardsData]; 
    const currentIndex = tempAward.findIndex((award => award.idAward == id)); 
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardValue = parseFloat(value);
    setAwardsData(tempAward); 
  }




  const handleAwardCheckboxOnlyFS = (e) => {
    let id = e.target.name;
    let checked = e.target.checked; 
    let tempAward = [...awardsData]; 
    const currentIndex = tempAward.findIndex((award => award.idAward == id)); 
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardOnlyForFs = checked;
    setAwardsData(tempAward); 
  }

  const handleAwardCheckboxPreSelected = (e) => {
    let id = e.target.name;
    let checked = e.target.checked; 
    let tempAward = [...awardsData]; 
    const currentIndex = tempAward.findIndex((award => award.idAward == id)); 
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardPreSelected = checked;
    setAwardsData(tempAward); 
  }


  const handleResellerChange = (selectionModel) => { 
    let isChanged= false;
    let tempReseller = [...resellersData];  

    tempReseller.forEach(function (item, index) {
      const checkChecked = selectionModel.findIndex((s => s === item.idReseller.toString())); 
      if (checkChecked===-1)
      {
        if (tempReseller[index].resellerSelected === true)
        {
          tempReseller[index].resellerSelected = false; 
          isChanged = true;
        } 
      }
      else
      {
        if (tempReseller[index].resellerSelected === false)
        {
          tempReseller[index].resellerSelected = true; 
          isChanged = true;
        }  
      }  
    });
    if (isChanged)
    {
      setResellersData(tempReseller);  
    }
  };


  const handleTyreChange = (selectionModel) => { 
    let isChanged= false;
    let tempTyre = [...tyresData];  

    tempTyre.forEach(function (item, index) {
      const checkChecked = selectionModel.findIndex((s => s === item.idTyre.toString())); 
      if (checkChecked===-1)
      {
        if (tempTyre[index].tyreSelected === true)
        {
          tempTyre[index].tyreSelected = false; 
          isChanged = true;
        } 
      }
      else
      {
        if (tempTyre[index].tyreSelected === false)
        {
          tempTyre[index].tyreSelected = true; 
          isChanged = true;
        }  
      }  
    });
    if (isChanged)
    {
      setTyresData(tempTyre);  
    }
  }; 

  const downloadModel = (Area, Type) => { 
    downloadUploadService.DownloadModel(Area, Type).then((data) => {       
    });
  }

  const uploadModel = (Area, Type, file) => {
    setHasError({ value: false, description: '' });
    setIsUploading(true);
    if (Area === 'Convention' & Type ==='Resellers') 
    {
      downloadUploadService.UploadModel(isAddMode ? 0 : ConventionId,
        Area,
        Type,
        file).then(
          (data) => {
            const promoResellers = data.map(
              promoReseller => {
                return {
                  id: promoReseller?.idReseller,
                  idReseller: promoReseller?.idResellerNavigation?.idReseller,
                  sapCode: promoReseller?.idResellerNavigation?.sapCode,
                  name: promoReseller?.idResellerNavigation?.name,
                  street: promoReseller?.idResellerNavigation?.street,
                  cap: promoReseller?.idResellerNavigation?.cap,
                  city: promoReseller?.idResellerNavigation?.city,
                  email: promoReseller?.idResellerNavigation?.email,
                  typeReseller: promoReseller?.idResellerNavigation?.typeReseller,
                  resellerSelected: promoReseller?.resellerSelected,
                }
              }
            );
            setHasError({ value: false, description: '' });
            setResellersData(promoResellers);
          },

          error => {
            setHasError({ value: true, description: error });
            alert(error);
          });
      setIsUploading(false);
    }
    else if (Area === 'Convention' & Type ==='Tyres')
    {
      downloadUploadService.UploadModel(isAddMode ? 0 : ConventionId,
        Area,
        Type,
        file).then(
          (data) => {
            const promoTyres = data.map(
              promoTyre => {
                return {
                  id: promoTyre?.idTyre,
                  idTyre: promoTyre?.idTyre,
                  supplierCode: promoTyre?.idTyreNavigation?.supplierCode,
                  description1: promoTyre?.idTyreNavigation?.description1,
                  brand: promoTyre?.idTyreNavigation?.brand, 
                  design1: promoTyre?.idTyreNavigation?.design1,
                  design2: promoTyre?.idTyreNavigation?.design2,
                  tyreSelected: promoTyre?.tyreSelected,
                }
              }
            );
            setHasError({ value: false, description: '' });
            setTyresData(promoTyres);
          },

          error => {
            setHasError({ value: true, description: error });
            alert(error);
          });
      setIsUploading(false);
    }
  }

 
  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => {
    if (!currentUser) return;
    if (!(currentUser.role === 'Admin' || currentUser.role === "Backoffice" || currentUser.role === "Bridgestone")) return;
    if (!isAddMode) {




      conventionService.GetConventionById(ConventionId).then(data => {
        console.log(data)
        if(!data.mediaLogo || !data.mediaImg){

          setMediaLogo(-1)
          setMediaImg(-1)
          setMediaEmail(-1)
          setCheckMedia(false)
        }else{

          setMediaLogo(parseInt(data.mediaLogo))
          setMediaImg(parseInt(data.mediaImg))
          setMediaEmail(parseInt(data.mediaEmail))
          setCheckMedia(true)


        }



        if(!data.numMaxRegistrazioni){
          setCheckMaxRegistrazioni(false)


        }else{
          setNumMaxRegistrazioni(data.numMaxRegistrazioni)
          setCheckMaxRegistrazioni(true)
        }

        if(!data.infoConvenzione){
          setCheckInfo(false);
        }else{
          setInfoConvenzione(data.infoConvenzione)
          setCheckInfo(true)
        }
        setMedia()


        if(data.companyEmailDomainConventionPromoAttribute=="*"){
          data.companyEmailDomainConventionPromoAttribute=""
          setCheckEmail(true)
        }
        setConventionData({
          PromoInternalName: data?.promoInternalName,
          PromoExternalName: data?.PromoExternalName,
          CompanyConventionCodePromoAttribute: data?.companyConventionCodePromoAttribute,
          CompanyNameConventionPromoAttribute: data?.companyNameConventionPromoAttribute,
          CompanyEmailDomainConventionPromoAttribute: data?.companyEmailDomainConventionPromoAttribute,
          StartVoucherRequestPromo: data?.startVoucherRequestPromo,
          EndVoucherRequestPromo: data?.endVoucherRequestPromo,
          EndVoucherRedemeedPromo: data?.endVoucherRedemeedPromo,
          EndVoucherRedemeedFirstopPromo: data?.endVoucherRedemeedFirstopPromo,
          StartViewPromo: data?.startViewPromo,
          EndViewPromo: data?.endViewPromo,
          EndPracticeRbo: data?.endPracticeRbo
        });
      });

    }
    else 
    {
      setMediaLogo(0)
      setMediaImg(0)
      setMediaEmail(0)
      setMedia()
      setCheckMedia(true)
      setCheckMaxRegistrazioni(true)
      setCheckInfo(true)
    }




    promoService.GetEligiblePromoAward(isAddMode ? 0 : ConventionId)
    .then((data) => {
      const promoAwards = data.map(
        promoAward => {
          return {
            id: promoAward?.idAward,
            idAward: promoAward?.idAward,
            awardName: promoAward?.idAwardNavigation?.awardName,
            awardDescription: promoAward?.idAwardNavigation?.awardDescription,
            awardOnlyForFs: promoAward?.awardOnlyForFs ? true : false,
            awardPreSelected: promoAward?.awardPreSelected ? true : false,
            awardValue: promoAward?.awardValue ? promoAward?.awardValue : 0,
            awardSelected: promoAward?.awardSelected ? true : false
          }
        }
      );
       setAwardsData(promoAwards); 
    });

    promoService.GetEligiblePromoReseller(isAddMode ? 0 : ConventionId)
      .then((data) => {
        const promoResellers = data.map(
          promoReseller => {
            return {
              id: promoReseller?.idReseller,
              idReseller: promoReseller?.idResellerNavigation?.idReseller,
              sapCode: promoReseller?.idResellerNavigation?.sapCode,
              shipToCode: promoReseller?.idResellerNavigation?.shipToCode,
              name: promoReseller?.idResellerNavigation?.name,
              street: promoReseller?.idResellerNavigation?.street,
              cap: promoReseller?.idResellerNavigation?.cap,
              city: promoReseller?.idResellerNavigation?.city,
              email: promoReseller?.idResellerNavigation?.email,
              typeReseller: promoReseller?.idResellerNavigation?.typeReseller,
              resellerSelected: promoReseller?.resellerSelected==true?true:false,
            }
          }
        ); 
        setResellersData(promoResellers); 
      });

      promoService.GetEligiblePromoTyre(isAddMode ? 0 : ConventionId)
      .then((data) => {
        const promoTyres = data.map(
          promoTyre => {
            return {
              id: promoTyre?.idTyre,
              idTyre: promoTyre?.idTyre,
              supplierCode: promoTyre?.idTyreNavigation?.supplierCode,
              description1: promoTyre?.idTyreNavigation?.description1,
              brand: promoTyre?.idTyreNavigation?.brand, 
              design1: promoTyre?.idTyreNavigation?.design1,
              design2: promoTyre?.idTyreNavigation?.design2,
              tyreSelected: promoTyre?.tyreSelected==true?true:false,
            }
          }
        ); 
        setTyresData(promoTyres); 
      });





  }, [currentUser]);





  async function  setMedia(){

    await mediaService.GetAllMediaActive()
      .then((data) => {



        //set mediaDataEmail1
        _mediaDataEmail = data.map(
          item => {
            return {
              key:item.idMedia,
              value: item.idMedia,
              label: item.mediaName

            }

          }

        );
        let def={"key":0,
          "value": 0,
          "label": 'Default'};


        _mediaDataEmail.push(def);
        setMediaDataEmail(_mediaDataEmail)



        //set mediaDataImg1
        _mediaDataImg = data.map(
          item => {
            return {
              key:item.idMedia,
              value: item.idMedia,
              label: item.mediaName,

            }

          }

        );
        def={"key":0,
          "value": 0,
          "label": 'Default'};


        _mediaDataImg.push(def);



        setMediaDataImg(_mediaDataImg)


        //set mediaDataLogo1
        _mediaDataLogo = data.map(
          item => {
            return {
              key:item.idMedia,
              value: item.idMedia,
              label: item.mediaName
            }

          }

        );
        def={"key":0,
          "value": 0,
          "label": 'Default'};


        _mediaDataLogo.push(def);





        setMediaDataLogo(_mediaDataLogo)

      });







  }





  async function _submitForm(values, actions) {
    actions.setSubmitting(false);

    if (isAddMode) {
      if(!checkEmail && !values.CompanyEmailDomainConventionPromoAttribute ){
        const { value: newplafond } =  Swal.fire({
          title: 'Non hai inserito l\'elenco dei domini email o non hai checkato l\'all domains' ,
          showCancelButton: false,
        }).then((ret) =>{
          window.location.reload();
        })
      }else{
        conventionService.CreateConvention(values,checkEmail,mediaImg,mediaLogo,mediaEmail,numMaxRegistrazioni,infoConvenzione ,awardsData, resellersData, tyresData)
          .then(
            r => {
              setHasError({ value: false, description: '' });
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
            },
            error => {
              setHasError({ value: true, description: error });
              setActiveStep(activeStep);
              actions.setTouched({});
              actions.setSubmitting(false);
            }
          );
      }
    }
    else {
      conventionService.UpdateConvention(ConventionId, values,checkEmail,mediaImg,mediaLogo,mediaEmail, numMaxRegistrazioni,infoConvenzione,awardsData, resellersData, tyresData)
        .then(
          r => {
            setHasError({ value: false, description: '' });
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          },
          error => {
            setHasError({ value: true, description: error });
            setActiveStep(activeStep);
            actions.setTouched({});
            actions.setSubmitting(false);
          }
        );
    }
  }


  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleChange = event => {
    alert(event);
  }
  function _handleSubmit(values, actions) { 
     
    if (isLastStep) {
      _submitForm(values, actions);
    } 
    
    else {

      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _endFlow() {    
    if (isAddMode) 
    {
          navigate('..', { replace: false }); 
    }
    else
    {
      navigate('../..', { replace: false });
    }
  }
 
    
 
  return (
    <React.Fragment>
      <CssBaseline /> 
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
              
       
 
          <React.Fragment>
            {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            CONVENZIONE SALVATA CORRETTAMENTE
                          </Typography>
                          <Typography variant="subtitle1">
                            
                          </Typography>
                          <div className={classes.wrapper}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={_endFlow}>
                                RITORNA ALLE CONVENZIONI
                              
                            </Button>
                          </div>
                    </React.Fragment>
            ) : (
              <Formik  
            initialValues={{ 
              ['PromoInternalName']: conventionData?.PromoInternalName,
              ['PromoExternalName']: conventionData?.PromoExternalName,
              ['CompanyConventionCodePromoAttribute']: conventionData?.CompanyConventionCodePromoAttribute,
              ['CompanyNameConventionPromoAttribute']: conventionData?.CompanyNameConventionPromoAttribute, 
              ['CompanyEmailDomainConventionPromoAttribute']: conventionData?.CompanyEmailDomainConventionPromoAttribute,         
              ['StartVoucherRequestPromo']: conventionData?.StartVoucherRequestPromo,              
              ['EndVoucherRequestPromo']: conventionData?.EndVoucherRequestPromo,        
              ['EndVoucherRedemeedPromo']: conventionData?.EndVoucherRedemeedPromo,
              ['EndVoucherRedemeedFirstopPromo']: conventionData?.EndVoucherRedemeedFirstopPromo,
              ['StartViewPromo']: conventionData?.StartViewPromo,
              ['EndViewPromo']: conventionData?.EndViewPromo,
              ['EndPracticeRbo']: conventionData?.EndPracticeRbo
            }} 
            enableReinitialize={true}

              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            //   onChange={handleChange}
             >
              {({ isSubmitting  }) => (
                <Form >
                  {_renderStepContent(activeStep, ConventionId)} 
                 
                  <div className={classes.buttons}>

                  {hasError.value && (
                      <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                    )}
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Precedente
                      </Button>
                    )}
                    
                    <div className={classes.wrapper}>

                   
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? 'Conferma' : 'Prossimo'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
}
