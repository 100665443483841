

 

import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';   


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));
  
    
const Results = ({ className, tyre, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const headCells = [
    {field: 'id', headerName: 'IdPromoTyreAsAward',  width: 150, description:'The internal idPromoTyre.'},
    {field: 'supplierCode', headerName: 'Supplier Code',  width: 150, description:'The internal supplierCode.'},
    {field: 'prodGrp1', headerName: 'prodGrp1',  width: 150, description:'The internal prodGrp1.'},
    {field: 'description1',   headerName: 'Description',  width: 300, description:'The internal description1.'},
    {field: 'wdkBrandText',   headerName: 'Brand Text',  width: 150, description:'The internal wdkBrandText.'},
    {field: 'iswinter',   headerName: 'Is winter',  width: 150, description:'The internal iswinter.'},
    {field: 'Edit', headerName: 'Edit', width: 150, renderCell: (params) => (
         <strong>
          {params.value}
     
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }} 
             onClick={() => { onEdit(params.row.id) }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    ];  

    let onEdit = (idTyre) => {
      navigate('edit/'+ idTyre, { replace: false });
    }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    > 
    <PerfectScrollbar>
        <Box minWidth={1050}>
        <div id='allTyre'>
          <DataGrid fontSize='7'
              columns={headCells}
              rows={tyre}
              pageSize={50}
              rowsPerPageOptions={[10,50,100,500]}
              showToolbar

              // components={{
              //   Toolbar: GridToolbar,
              // }}
              />
        </div> 
        </Box>
        
      </PerfectScrollbar>
      
    </Card>
  );
};


Results.propTypes = {
  className: PropTypes.string,
  tyre: PropTypes.array.isRequired
};

export default Results;
