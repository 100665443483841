import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Paper,
  Card,
  colors,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid'; 

import { authenticationService } from 'src/services/authentication.service';
import { voucherService } from 'src/services/voucher.service'; 

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    div: { 
      width: '100%',
      height: 880
    },
    datagrid: { 
      fontSize: 12, 
    },
    editButton: {
      backgroundColor: colors.red[600] 
    }
  }));
 
  

const Results = ({ className, vouchers, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
   
  const [currentUser, setCurrentUser] = useState();
  let [isSubmitting, setIsSubmitting] = useState(false); 
  
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])

  let downloadVoucher = (LogId, PromoId, UserId, VoucherFileName) => { 
    setIsSubmitting(true);
    voucherService.GetVoucherFileById(LogId, PromoId, VoucherFileName, UserId).then((data) => {  
      if (!data)
      {
        alert('Impossibile trovare il file');
      }

      setIsSubmitting(false);
    });
  }










  const headCells = [
    {field: 'View', headerName: ' ', width: 100, renderCell: (params) => (
      <strong> 
       <Button
         variant="contained"
         color="primary"
         size="small"
         // style={{ marginLeft: 16 }} 
          onClick={() => { downloadVoucher(params.row.idLog, params.row.idPromo, params.row.vouchersFileName, currentUser.id) }}          
          disabled={isSubmitting}
          className={classes.editButton}
       >
         VIEW
       </Button> 
     </strong>
   ),
 },


//  {field: 'shortNamePromoAttribute', headerName: 'PROMOZIONE', flex:1, description:'Nome promozione.',
//     renderCell: (params) => (
//       <>
//        {(params.row.idPromoNavigation.shortNamePromoAttribute)}  
//        </>  
//     )},
    {field: 'promoInternalName', headerName: 'PROMO', width:500, description:'Numero delle cartoline generate.'},
    {field: 'vouchersGenerated', headerName: 'CART.', width: 100, description:'Numero delle cartoline generate.'},
    {field: 'logDate', type: 'dateTime', headerName: 'DATA GENERAZIONE', width: 260, description:'Data di generazione cartoline.'},
    {field: 'vouchersList', headerName: 'CODICI CARTOLINE', flex:1, description:'Elenco dei codici cartolina generati'}
    ];



    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      > 
      <PerfectScrollbar>
          <Box minWidth={1050}>
          {/* <div style={{ height:100, width: '100%'}}> */}
          
          <div className={classes.div}>
          <DataGrid  
              className={classes.datagrid}
              columns={headCells} 
              rows={vouchers}
              pageSize={20}
              rowsPerPageOptions={[5, 10, 20, 50]}
              showToolbar
              components={{
                Toolbar: GridToolbar,
              }} 
              />
          </div> 
          </Box>
          
        </PerfectScrollbar>
        
      </Card>
    );
  };


Results.propTypes = {
  className: PropTypes.string,
  vouchers: PropTypes.array.isRequired
};

export default Results;
