import * as Yup from 'yup';
import moment from 'moment';
import mediaFormModel from './mediaFormModel';
const {
  formField: {
    MediaName,
    MediaActive
  }
} = mediaFormModel;



export default [
  Yup.object().shape({
    [MediaName.name]: Yup.string()
          .required(`${MediaName.requiredErrorMsg}`),

  })
];




