import * as Yup from 'yup';
import moment from 'moment';
import addPracticeFormModel from './addPracticeFormModel';
const {
  formField: {
    VoucherCode,
    IdPromo,
    LongNamePromoAttribute,
    PromoExternalName,    
    IdProspect,
    ProspectName
  }
} = addPracticeFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [VoucherCode.name]: Yup.string()
          .required(`${VoucherCode.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [IdPromo.name]: Yup.string()
          .required(`${IdPromo.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [LongNamePromoAttribute.name]: Yup.string()
          .required(`${LongNamePromoAttribute.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [PromoExternalName.name]: Yup.string()
          .required(`${PromoExternalName.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [IdProspect.name]: Yup.string()
          .required(`${IdProspect.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [ProspectName.name]: Yup.string()
          .required(`${ProspectName.requiredErrorMsg}`)
  })
];

 