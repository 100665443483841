import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { mediaService } from '../../../services/media.service';
import {authenticationService} from "../../../services/authentication.service";
import {API_URL} from "../../../helpers";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const MediaListView = () => {
  const classes = useStyles();
  const [mediaData, setMediaData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [url, setUrl]=useState();


  useEffect(() => {
    const user = authenticationService.currentUserValue;

    setCurrentUser(user)
  }, [])

  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;

    mediaService.GetAllMedia().then((data) => {
      data.map((item, index) => {
        data[index].id = item.idMedia
        data[index].link=`${API_URL}/Media/GetMedia?IdMedia=`+item.idMedia
        data[index].linkApi="/api/Media/GetMedia?IdMedia="+item.idMedia
      } );
      console.log(data);
      setMediaData(data);
    });


  }, [currentUser])


  return (
    <Page
      className={classes.root}
      title="Media"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results media={mediaData} />
        </Box>
      </Container>
    </Page>
  );
};

export default MediaListView;

