export default {
  formId: 'addPracticeForm',
  formField: {
    VoucherCode: {
      name: 'VoucherCode',
      label: 'Codice voucher',
      requiredErrorMsg: 'Codice voucher obbligatorio'
    },
    IdPromo: {
      name: 'IdPromo',
      label: 'IdPromo',
      requiredErrorMsg: 'IdPromo obbligatorio'
    },
    LongNamePromoAttribute: {
      name: 'LongNamePromoAttribute',
      label: 'Promozione',
      requiredErrorMsg: 'Promozione obbligatoria'
    },
    PromoExternalName: {
      name: 'PromoExternalName',
      label: 'Codice promozione',
      requiredErrorMsg: 'Codice promozione obbligatorio'
    },
    ProspectName: {
      name: 'ProspectName',
      label: 'Cliente',
      requiredErrorMsg: 'Cliente obbligatorio'
    },
    IdProspect: {
      name: 'IdProspect',
      label: 'IdProspect',
      requiredErrorMsg: 'Cliente obbligatorio'
    }
  }
};


