import { authenticationService } from '../services/authentication.service';

export function handleDownload(response) {
    return response.blob().then(blob => {
        const data = blob;
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                // location.reload(true);
            }

            const error = 'Errore tecnico';
            // return Promise.reject(error);
        }

        return data;
    });
}
