export default {
  formId: 'awardForm',
  formField: {
    AwardName: {
      name: 'AwardName',
      label: 'Award name',
      requiredErrorMsg: 'Award name is required',
    },    
    AwardDescription: {
      name: 'AwardDescription',
      label: 'Award description',
      requiredErrorMsg: 'Award description is required'
    }
  }
};

