import React,  { useState, useEffect }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  colors,
  CardContent,
  makeStyles,
  withStyles,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  InputAdornment
} from '@material-ui/core';
 
import AccountCircle from '@material-ui/icons/AccountCircle';
import ImageIcon from '@material-ui/icons/Image';
import CodeIcon from '@material-ui/icons/Code';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';

 
import { authenticationService } from 'src/services/authentication.service';
import { resellerService } from 'src/services/reseller.service';


const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(6)
  }
}));
 


export default function PromoSelection(props) {
  const { 
    formField: { 
      IdPromo,
      PromoTextSelected,
      QtaVoucher, 
      IdPromoAward 
  },
      onChange,
      promoSelected,
      promoData
  } = props; 

  const classes = useStyles();    
  const navigate = useNavigate();
  

  return ( 
    <Grid item xs={12} md={12} spacing={5}>
    <Typography variant="h5" className={classes.typografy}>
      PROMOZIONI
    </Typography>
    <Typography variant="h6" gutterBottom>
      SELEZIONARE LA PROMOZIONE PER LA QUALE SI DESIDERA CREARE LE CARTOLINE
    </Typography> 




    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12} sm={6}> 
      <RadioGroup aria-label="promo" name="promo" value={promoSelected} onChange={onChange}>
     {promoData.map((promo) => (
                <FormControlLabel key={promo?.idPromo} value={promo?.idPromo?.toString()} control={<Radio />} label={promo?.internalNamePromo} />
              ))} 
  </RadioGroup>
        
        </Grid> 
      </Grid> 
    </Grid>


 
  );
}


