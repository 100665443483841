import campaignFormModel from './campaignFormModel';
const {
  formField: {
    PromoInternalName,
    PromoExternalName,
    CodiceAziendaleConvenzionePromoAttribute,
    NomeAziendaConvenzionePromoAttribute,
    DataInizioRichiestaCodicePromoAttribute,
    DataFineRichiestaCodicePromoAttribute,
    DataFineRiscossioneCodicePromoAttribute,
    DataFineRiscossioneCodice_FirstopPromoAttribute,
    DominiAziendaliEmailConvenzionePromoAttribute
  }
} = campaignFormModel;

export default {
  [PromoInternalName.name]: 'alex',
  [PromoExternalName.name]: '',
  [CodiceAziendaleConvenzionePromoAttribute.name]: '',
  [NomeAziendaConvenzionePromoAttribute.name]: '',
  [DataInizioRichiestaCodicePromoAttribute.name]: '',
  [DataFineRichiestaCodicePromoAttribute.name]: '',
  [DataFineRiscossioneCodicePromoAttribute.name]: '',
  [DataFineRiscossioneCodice_FirstopPromoAttribute.name]: '',  
  [DominiAziendaliEmailConvenzionePromoAttribute.name]: ''
};


// export default   (convention) => { 
//   return {
//   [PromoInternalName.name]: 'alex',
//   [PromoExternalName.name]: 'alex',
//   [CodiceAziendaleConvenzionePromoAttribute.name]: 'alex',
//   [NomeAziendaConvenzionePromoAttribute.name]: '',
//   [DataInizioRichiestaCodicePromoAttribute.name]: '',
//   [DataFineRichiestaCodicePromoAttribute.name]: '',
//   [DataFineRiscossioneCodicePromoAttribute.name]: '',
//   [DataFineRiscossioneCodice_FirstopPromoAttribute.name]: '',  
//   [DominiAziendaliEmailConvenzionePromoAttribute.name]: ''
// }
// };