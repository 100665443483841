import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { FaCarAlt } from 'react-icons/fa';
import { FaMotorcycle } from 'react-icons/fa';
import { ImTruck } from 'react-icons/im';
import { FaTractor } from 'react-icons/fa';


import { Link as RouterLink, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ViewPromo = ({ className, promoData,user, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();



  let onClick = (idPromo) => {
    navigate('../practices/', { replace: true });
  }


  let getPratiche = (idPromo,user) => {
    //window.open("http://localhost:3000/backoffice/campaigns/giustificativo/"+idPromo+"/"+user, '_blank', 'noopener,noreferrer');
    navigate("/backoffice/campaigns/giustificativo/"+idPromo+"/"+user);
  }

  return ( 
    
    <Card
      className={classes.root}
    >
      {/* <CardHeader title="PROMOZIONI" /> */}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell  width={10}>

                </TableCell>
                <TableCell>
                  PROMOZIONI
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Plafond iniziale
                    </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Plafond utilizzato
                    </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Plafond rimanente
                    </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Inizio richiesta codici
                    </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Fine richiesta codici
                    </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5" component="h5">
                    Termine gestione pratiche
                    </Typography>
                </TableCell>

                

              </TableRow>
            </TableHead>
            <TableBody>
              {promoData.map((promo) => (
                <TableRow
                  hover
                  key={promo.idPromo}
                >
                  {promo?.businessPromo=='auto'&&
                  <TableCell width={10}>
                    <FaCarAlt size={30}></FaCarAlt>
                  </TableCell>
                  }
                  {promo?.businessPromo=='moto'&&
                  <TableCell width={10}>
                    <FaMotorcycle size={30}></FaMotorcycle>
                  </TableCell>
                  }
                  {promo?.businessPromo=='truck'&&
                  <TableCell width={10}>
                    <ImTruck size={30}></ImTruck>
                  </TableCell>
                  }
                  {promo?.businessPromo=='agri'&&
                  <TableCell width={10}>
                    <FaTractor size={30}></FaTractor>
                  </TableCell>
                  }
            

                  <TableCell>
                    <Typography variant="h5" component="h5">
                      {promo?.internalNamePromo}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Button
                      color="secondary"
                      // fullWidth
                      size="small"
                      type="submit"
                      variant="contained"
                      onClick={() => { onClick(promo?.idPromo) }}
                    >
                      GESTIONE PRATICHE
                  </Button>
                  </TableCell>

                  <TableCell>
                    {promo?.hadPractice ? (
                      <Button
                        color="secondary"
                        // fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        onClick={() => { getPratiche(promo?.idPromo,user.id) }}
                      >
                        GIUSTIFICATIVO
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        // fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        disabled={true}

                      >
                        GIUSTIFICATIVO
                      </Button>
                    )}

                  </TableCell>

                  <TableCell>
                    {promo?.typePromo == 'CampaignPurchaseFirstNoPlafond' ? (
                      <Typography variant="h5" component="h5">
                      -
                    </Typography>
                    ) : (
                      <Typography variant="h5" component="h5">
                      {promo?.plafondInitialReseller} €
                    </Typography>
                    )}                    
                  </TableCell>


                  <TableCell>
                    {promo?.typePromo == 'CampaignPurchaseFirstNoPlafond' ? (
                      <Typography variant="h5" component="h5">
                      -
                    </Typography>
                    ) : (
                      <Typography variant="h5" component="h5">
                      {promo?.plafondUsedReseller} €
                    </Typography>
                    )}                    
                  </TableCell>
 
 
                  <TableCell>
                    {promo?.typePromo == 'CampaignPurchaseFirstNoPlafond' ? (
                      <Typography variant="h5" component="h5">
                      -
                    </Typography>
                    ) : (
                      <Typography variant="h5" component="h5">
                      {promo?.plafondRemainingReseller} €
                    </Typography>
                    )}                    
                  </TableCell>

                  <TableCell>
                    <Typography variant="h5" component="h5">
                      {moment(promo?.startVoucherRequest).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {promo?.typePromo == 'CampaignVoucherFirst' ? (
                      <Typography variant="h5" component="h5">
                        {moment(promo?.endVoucherRedemeed).format('DD/MM/YYYY')}
                      </Typography>
                    ) : (
                      <Typography variant="h5" component="h5">
                        {moment(promo?.endVoucherRequest).format('DD/MM/YYYY')}
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell>
                    {promo?.typePromo == 'CampaignVoucherFirst' ? (
                      <Typography variant="h5" component="h5">
                        {moment(promo?.endVoucherRedemeed).format('DD/MM/YYYY')}
                      </Typography>
                    ) : (
                      <Typography variant="h5" component="h5">
                        {moment(promo?.endPracticeRbo).format('DD/MM/YYYY')}
                      </Typography>
                    )}
                  </TableCell>



                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

    </Card> 
  );
};

ViewPromo.propTypes = {
  className: PropTypes.string
};

export default ViewPromo;
