import React,  { useState }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'; 
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';
import { DatePickerField } from '../../../../FormFields';


const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
}));


export default function TyreData(props) {
  const {
    formField: {     

      EAN,
      PROD_GRP_1,
      SUPPLIER_CODE,
      DESCRIPTION_1,
      DESCRIPTION_2,
      PROD_INFO,
      WDK_ANUB,
      WDK_BRAND,
      WDK_BRAND_TEXT,
      BRAND,
      BRAND_TEXT,
      PROD_GRP_2,
      GROUP_DESCRIPTION,
      WEIGHT,
      RIM_INCH,
      PROD_CYCLE,
      THIRD_PARTY,
      PL,
      TEL,
      EDI,
      ADHOC,
      PL_ID,
      URL_1,
      URL_2,
      URL_3,
      URL_4,
      URL_5,
      WIDTH_MM,
      WIDTH_INCH,
      ASPECT_RATIO,
      OVL_DIAMETER,
      CONSTRUCTION_1,
      CONSTRUCTION_2,
      USAGE,
      DEPTH,
      LI1,
      LI2,
      LI3DWB,
      LI4DWB,
      SP1,
      SP2,
      TLTT,
      FLANK,
      PR,
      RFD,
      SIZE_PREFIX,
      COMM_MARK,
      RIM_MM,
      RUN_FLAT,
      SIDEWALL,
      DESIGN_1,
      DESIGN_2,
      PRODUCT_TYPE,
      VEHICLE_TYPE,
      COND_GRP,
      TAX_ID,
      TAX,
      SUGGESTED_PRICE,
      GROSS_PRICE,
      GP_VALID_FROM,
      NET_VALUE,
      NV_VALID,
      RECYCLING_FEE,
      NOISE_PERFORMANCE,
      NOISE_CLASS_TYPE,
      ROLLING_RESISTANCE,
      WET_GRIP,
      EC_VEHICLE_CLASS,
      EU_DIRECTIVE_NUMBER,
      BUSINESS,
      ISWINTER
   }
  } = props; 

  const classes = useStyles();
  const navigate = useNavigate();
    
 
  return (
    <Grid item xs={12} md={12} spacing={5}>
    {/* <Typography variant="h6" className={classes.typografy}>
      DATI GENERALI CONVENZIONE
    </Typography> */}
    {/* <Typography variant="h8" gutterBottom>
      INSERISCI I TUOI DATI PER RICEVERE IL VOUCHER
    </Typography>  */}
 

      <Grid container spacing={3} className={classes.grid}>

        {/* <h1>{props.AddOrEdit ? 'Add' : 'Edit'}</h1> */}


        <Grid item xs={12} sm={6}>
          <InputField name={EAN.name} label={EAN.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PROD_GRP_1.name} label={PROD_GRP_1.label} fullWidth />
        </Grid>


        <Grid item xs={12} sm={6}>
          <InputField name={SUPPLIER_CODE.name} label={SUPPLIER_CODE.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={DESCRIPTION_1.name} label={DESCRIPTION_1.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={DESCRIPTION_2.name} label={DESCRIPTION_2.label} fullWidth />
        </Grid>


        <Grid item xs={12} sm={6}>
          <InputField name={PROD_INFO.name} label={PROD_INFO.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WDK_ANUB.name} label={WDK_ANUB.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WDK_BRAND.name} label={WDK_BRAND.label}  type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WDK_BRAND_TEXT.name} label={WDK_BRAND_TEXT.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={BRAND.name} label={BRAND.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={BRAND_TEXT.name} label={BRAND_TEXT.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PROD_GRP_2.name} label={PROD_GRP_2.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={GROUP_DESCRIPTION.name} label={GROUP_DESCRIPTION.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WEIGHT.name} label={WEIGHT.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={RIM_INCH.name} label={RIM_INCH.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PROD_CYCLE.name} label={PROD_CYCLE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={THIRD_PARTY.name} label={THIRD_PARTY.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={PL.name} label={PL.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={TEL.name} label={TEL.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={EDI.name} label={EDI.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={ADHOC.name} label={ADHOC.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PL_ID.name} label={PL_ID.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={URL_1.name} label={URL_1.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={URL_2.name} label={URL_2.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={URL_3.name} label={URL_3.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={URL_4.name} label={URL_4.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={URL_5.name} label={URL_5.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WIDTH_MM.name} label={WIDTH_MM.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={WIDTH_INCH.name} label={WIDTH_INCH.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={ASPECT_RATIO.name} label={ASPECT_RATIO.label} type='number'  fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={OVL_DIAMETER.name} label={OVL_DIAMETER.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={CONSTRUCTION_1.name} label={CONSTRUCTION_1.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={CONSTRUCTION_2.name} label={CONSTRUCTION_2.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={USAGE.name} label={USAGE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={DEPTH.name} label={DEPTH.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={LI1.name} label={LI1.label} type='number'  fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={LI2.name} label={LI2.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={LI3DWB.name} label={LI3DWB.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={LI4DWB.name} label={LI4DWB.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={SP1.name} label={SP1.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={SP2.name} label={SP2.label} fullWidth />
        </Grid>


        <Grid item xs={12} sm={6}>
          <InputField name={TLTT.name} label={TLTT.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={FLANK.name} label={FLANK.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PR.name} label={PR.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={RFD.name} label={RFD.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={SIZE_PREFIX.name} label={SIZE_PREFIX.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={COMM_MARK.name} label={COMM_MARK.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={RIM_MM.name} label={RIM_MM.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={RUN_FLAT.name} label={RUN_FLAT.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={SIDEWALL.name} label={SIDEWALL.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={DESIGN_1.name} label={DESIGN_1.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={DESIGN_2.name} label={DESIGN_2.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={PRODUCT_TYPE.name} label={PRODUCT_TYPE.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={VEHICLE_TYPE.name} label={VEHICLE_TYPE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={COND_GRP.name} label={COND_GRP.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={TAX_ID.name} label={TAX_ID.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={TAX.name} label={TAX.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={SUGGESTED_PRICE.name} label={SUGGESTED_PRICE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={GROSS_PRICE.name} label={GROSS_PRICE.label}  fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={GP_VALID_FROM.name} label={GP_VALID_FROM.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={NET_VALUE.name} label={NET_VALUE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={NV_VALID.name} label={NV_VALID.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={RECYCLING_FEE.name} label={RECYCLING_FEE.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={NOISE_PERFORMANCE.name} label={NOISE_PERFORMANCE.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={NOISE_CLASS_TYPE.name} label={NOISE_CLASS_TYPE.label} type='number' fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={ROLLING_RESISTANCE.name} label={ROLLING_RESISTANCE.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={WET_GRIP.name} label={WET_GRIP.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={EC_VEHICLE_CLASS.name} label={EC_VEHICLE_CLASS.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={EU_DIRECTIVE_NUMBER.name} label={EU_DIRECTIVE_NUMBER.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={BUSINESS.name} label={BUSINESS.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={ISWINTER.name} label={ISWINTER.label}  fullWidth />
        </Grid>




      </Grid>
    </Grid>
  );
}

