import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link,
  Grid,
  TextField
} from '@material-ui/core';
import InputField from 'src/FormFields/InputField';

import { Formik, Form } from 'formik';

import { useParams, useNavigate } from 'react-router-dom';


import editPracticeValidationSchema from './FormModel/editPracticeValidationSchema';
import editPracticeFormModel from './FormModel/editPracticeFormModel';
import editPracticeFormInitialValues from './FormModel/editPracticeFormInitialValues';


import PracticeData from './Forms/PracticeData';



import { resellerService } from '../../../services/reseller.service';
import { practiceService } from '../../../services/practice.service';
import { promoService } from '../../../services/promo.service';
import { alertService } from '../../../services/alert.service';
import { authenticationService } from '../../../services/authentication.service';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errors: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
}));

const steps = ['Modifica dati pratica'];
const { formId, formField } = editPracticeFormModel;


export default function EditPractice() {
  const navigate = useNavigate();
  const { PracticeId } = useParams();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const currentValidationSchema = editPracticeValidationSchema[activeStep];

  const [practice, setPractice] = useState({});
  const [dropzoneAreaAttachments, setDropzoneAreaAttachments] = useState([]);
  const [practiceAttachments, setPracticeAttachments] = useState([]);

  const [tyres, setTyres] = useState([]);
  
  let [hasError, setHasError] = useState({
    value: false,
    description:''
  });

  const [currentUser, setCurrentUser] = useState();

  const [file, setFile] = useState({});

  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.role != 'Reseller') return;
    resellerService.GetResellerPractice(PracticeId, currentUser.id).then(data => {
      setPractice({
        idPractice: data?.idPractice,
        idPromo: data?.idPromo,
        promoInternalName: data?.idPromoNavigation?.promoInternalName,
        promoExternalName: data?.idPromoNavigation?.promoExternalName,
        prospectName: data?.idClientNavigation?.firstName + ' ' + data?.idClientNavigation?.lastName,
        // VoucherCode: data.awardName, 
        awardRefund: data?.awardRefund,
        dateIns: data?.dateIns,
        practiceNrBo: data?.practiceNrBo,
        practiceStateBkb: data?.practiceStateBkb,
        qtaPneumaticiUno: data?.practiceTyres[0]?.quantity,
        qtaPneumaticiDue: data?.practiceTyres[1]?.quantity,
        qtaPneumaticiTre: data?.practiceTyres[2]?.quantity,
        modelPneumaticiUno: data?.practiceTyres[0] ? { description: data?.practiceTyres[0]?.idPromoTyreNavigation?.idTyreNavigation.description1, id: data?.practiceTyres[0]?.idPromoTyre } : null,
        modelPneumaticiDue: data?.practiceTyres[1] ? { description: data?.practiceTyres[1]?.idPromoTyreNavigation?.idTyreNavigation.description1, id: data?.practiceTyres[1]?.idPromoTyre } : null,
        modelPneumaticiTre: data?.practiceTyres[2] ? { description: data?.practiceTyres[2]?.idPromoTyreNavigation?.idTyreNavigation.description1, id: data?.practiceTyres[2]?.idPromoTyre } : null,
      });
      setPracticeAttachments(data?.practiceAttachments)
    });
  }, [currentUser])

  useEffect(() => {
    if (!practice || !practice.idPromo) return;
    promoService.GetPromoTyres(practice.idPromo).then(data => {
      const tyres = data.map(tyre => { return { id: tyre.idPromoTyre, description: tyre.idTyreNavigation.description1 } });
      setTyres(tyres)
    });
  }, [practice])


  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <PracticeData
          formField={formField}
          tyres={tyres}
          attachments={practiceAttachments}
          downloadFile={downloadFile}
          dropzoneAreaChange={dropzoneAreaChange}
          deleteFile={deleteFile} />;
      // case 1:
      //   return <Awards formField={formField} />;
      // case 2:
      //   return <ResellerJoined formField={formField} />;
      // case 3:
      //  return <Tyres formField={formField} />;
      // case 4:
      //   return <Review formField={formField}/>;
      default:
        return <div>Not Found</div>;
    }
  }

  function downloadFile(idPracticeAttachment, fileNameAttachment) {
    practiceService.GetPracticeAttachment(practice.idPractice, currentUser.id, practice.idPromo, idPracticeAttachment, fileNameAttachment)
    .then(() => {

    })
    .catch(error => {

    });
  }

  function deleteFile(idPracticeAttachment) {
    const newAttachments = [...practiceAttachments];
    const practiceAttachmentIndex = newAttachments.findIndex(item => item.idPracticeAttachment === idPracticeAttachment);
    if (practiceAttachmentIndex != -1) {
      newAttachments[practiceAttachmentIndex].status = "deleted";
      setPracticeAttachments(newAttachments)
    }
  }

  function dropzoneAreaChange(files) {
    setDropzoneAreaAttachments(files)
  }

  function _handleSubmit(values, actions) {
    actions.setSubmitting(true);
    _submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  }

  function _submitForm(fields, { setStatus, setSubmitting }) {
    editPractice(practice.idPractice, currentUser.id, fields, dropzoneAreaAttachments, practiceAttachments, setSubmitting);
  }

  function editPractice(id, idUser, fields, dropzoneAreaAttachments, practiceAttachments,setSubmitting) {
    setHasError({value:false, description: ''});
    practiceService.EditPractice(id, idUser, fields, dropzoneAreaAttachments, practiceAttachments)
      .then(() => {
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      })
      .catch(error => {
        setHasError({value:true, description: error});
        setSubmitting(false);
      });
  }

  function _endFlow() {
    navigate('../..', { replace: false });
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  MODIFICA AVVENUTA CORRETTAMENTE
                      </Typography>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={_endFlow}>CHIUDI
                        </Button>
                </div>
              </React.Fragment>
            ) : (
              <Formik
                initialValues={{
                  ['IdPromo']: practice?.idPromo,
                  ['PromoInternalName']: practice?.promoInternalName,
                  ['PromoExternalName']: practice?.promoExternalName,
                  ['ProspectName']: practice?.prospectName,
                  ['AwardRefund']: practice?.awardRefund,
                  ['DateIns']: practice?.dateIns,
                  ['PracticeNrBo']: practice.practiceNrBo,
                  ['PracticeStateBkb']: practice.practiceStateBkb,
                  ['QtaPneumaticiUno']: practice.qtaPneumaticiUno,
                  ['QtaPneumaticiDue']: practice.qtaPneumaticiDue,
                  ['QtaPneumaticiTre']: practice.qtaPneumaticiTre,
                  ['ModelPneumaticiUno']: practice.modelPneumaticiUno,
                  ['ModelPneumaticiDue']: practice.modelPneumaticiDue,
                  ['ModelPneumaticiTre']: practice.modelPneumaticiTre
                }}
                enableReinitialize={true}

                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              //   onChange={handleChange}
              >
                {({ isSubmitting }) => (
                  <Form id={formId}>
                    {_renderStepContent(activeStep)}

                    <div className={classes.buttons}>

                      {hasError.value && (
                        <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                      )}

                      <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {'Conferma'}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
}
