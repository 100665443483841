import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon
} from 'react-feather';
import StoreIcon from '@material-ui/icons/Store';
import NavItem from './NavItem';
import { addMinutes } from 'date-fns';
import { authenticationService } from 'src/services/authentication.service';

    
 
 
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import DashboardIcon from '@material-ui/icons/Dashboard';
import { GiCarWheel } from "react-icons/gi";
import ReceiptIcon from '@material-ui/icons/Receipt';
import RedeemIcon from '@material-ui/icons/Redeem';
import ImageIcon from '@material-ui/icons/Image';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

//RUOLI POSSIBILI
// Bridgestone
// Admin
// Backoffice
// Reseller


const itemsBridgestone = [
  {
    href: '/backoffice/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  {
    href: '/backoffice/conventions',
    icon: ImageIcon,
    title: 'Convenzioni'
  },
  {
    href: '/backoffice/reports',
    icon: BarChartIcon,
    title: 'Report'
  }
];


const itemsBackoffice = [
  {
    href: '/backoffice/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  /* {
     href: '/backoffice/awards',
     icon: RedeemIcon,
     title: 'Award'
   },*/
   {
     href: '/backoffice/campaigns',
     icon: ImageIcon,
     title: 'Campagne'
   },
  {
    href: '/backoffice/conventions',
    icon: ImageIcon,
    title: 'Convenzioni'
  },
  {
    href: '/backoffice/practicesBo',
    icon: ReceiptIcon,
    title: 'Pratiche'
  },
  {
    href: '/backoffice/resellers',
    icon: GiCarWheel,
    title: 'Rivenditori'
  },
  {
    href: '/backoffice/reports',
    icon: BarChartIcon,
    title: 'Report'
  },{
    href: '/backoffice/awards',
    icon: ImageIcon,
    title: 'Award'
  },{
    href: '/backoffice/tyres',
    icon: ImageIcon,
    title: 'Tyre'
  },{
    href: '/backoffice/media',
    icon: ImageIcon,
    title: 'Media'
  }
];

/*
* ,
  {
    href: '/backoffice/reports',
    icon: BarChartIcon,
    title: 'Report'
  }
* */

const itemsReseller = [
  {
    href: '/backoffice/dashboard-reseller',
    icon: DashboardIcon,
    title: 'Dashboard Rivenditore'
  },
  {
    href: '/backoffice/practices',
    icon: ReceiptIcon,
    title: 'Pratiche'
  },
  {
    href: '/backoffice/vouchers',
    icon: CardGiftcardIcon,
    title: 'Cartoline'
  },
  {
    href: '/backoffice/settings',
    icon: SettingsIcon,
    title: 'Dati personali'
  } 
];

const itemsAdmin = [
  {
    href: '/backoffice/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  {
    href: '/backoffice/dashboard-reseller',
    icon: DashboardIcon,
    title: 'Dashboard Rivenditore'
  },
  {
    href: '/backoffice/awards',
    icon: RedeemIcon,
    title: 'Award'
  },
  {
    href: '/backoffice/conventions',
    icon: ImageIcon,
    title: 'Convenzioni'
  },
  {
    href: '/backoffice/campaigns',
    icon: ImageIcon,
    title: 'Campagne'
  },
  {
    href: '/backoffice/practices',
    icon: ReceiptIcon,
    title: 'Pratiche'
  }, 
  {
    href: '/backoffice/practicesBo',
    icon: ReceiptIcon,
    title: 'Pratiche Bo'
  },
  {
    href: '/backoffice/resellers',
    icon: GiCarWheel,
    title: 'Rivenditori'
  },
  {
    href: '/backoffice/report',
    icon: BarChartIcon,
    title: 'Report'
  },
  {
    href: '/backoffice/settings',
    icon: SettingsIcon,
    title: 'Impostazioni'
  } 
];


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: 20
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false)

  const currentUser = authenticationService.currentUserValue;
  const user = {
    avatar: '',
    id: (currentUser ? currentUser.id : ''),
    role: (currentUser ? currentUser.role : ''),
    name: (currentUser ? currentUser.name : ''),
    token: (currentUser ? currentUser.token : ''),
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  
  function handleClick() {
    setOpen(!open)
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      > 
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/backoffice/settings"
        />
        <Divider />
        <Typography
           className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name} 
        </Typography> 
      </Box>
      <Divider />
      <Box p={2}>

      {currentUser.role=="Backoffice"&&
        (<List>
          {itemsBackoffice.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}

 
                </List> )}
        
        {currentUser.role=="Bridgestone"&&
        (<List>
          {itemsBridgestone.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> )}
         
        {currentUser.role=="Reseller"&&
        (<List>
          {itemsReseller.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> )}

        {currentUser.role=="Admin"&&
        (<List>
          {itemsAdmin.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> )}
        
 
      </Box>
      <Box flexGrow={1} />
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
