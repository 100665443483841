import configData from './config.json';
import {API_URL, authHeader, handleResponse} from '../helpers';


export const resellerService = {
    GetAllReseller,
    GetReseller,
    GetResellerCampaign,
    GetResellerPractices,
    GetResellerPractice,
    UpdateReseller,
    GetResellerCampaignCart
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  

const guardedPromise = preventConcurrentExec();


function GetAllReseller() {
        return guardedPromise("GetAllReseller", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/reseller`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for resellers");
            })
            .catch(error => reject(error));
    }));
}

function GetReseller(idReseller) {
    return guardedPromise("GetReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/reseller/${idReseller}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for resellers");
        })
        .catch(error => reject(error));
}));
}


function GetResellerCampaign(idReseller, voucherOrPurchase) {
    return guardedPromise("GetResellerCampaign", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/reseller/${idReseller}/Campaign?VoucherOrPurchase=${voucherOrPurchase}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for campaigns");
        })
        .catch(error => reject(error));
}));
}
function GetResellerCampaignCart(idReseller, voucherOrPurchase) {
  return guardedPromise("GetResellerCampaignCart", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/reseller/${idReseller}/Campaign_Practice?VoucherOrPurchase=${voucherOrPurchase}`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}





function GetResellerPractices(idReseller) {
    return guardedPromise("GetResellerPractices", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/Reseller/${idReseller}/practice`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for practices");
        })
        .catch(error => reject(error));
}));
} 
function GetResellerPractice(idPractice, idReseller) {
    return guardedPromise("GetResellerPractice", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/Reseller/${idReseller}/practice/${idPractice}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for practices");
        })
        .catch(error => reject(error));
}));
}


function UpdateReseller(idUser, iban) { 
    var IdReseller = idUser; 
    var Iban = iban; 
    return guardedPromise("UpdateReseller", () => new Promise((resolve, reject) => {
        // const requestOptions = { method: 'GET', headers: authHeader() };

        const requestOptions = {
            method: 'PUT',  
            headers: {'Content-Type': 'application/json',  ...authHeader()},   
            body: JSON.stringify({ 
                IdReseller, 
                Iban 
            })
        }; 
 
        fetch(`${API_URL}/reseller/`+IdReseller, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for reseller");
            })
            .catch(error => reject(error));
        }));
}
