import configData from './config.json';
import {authHeader, handleResponse, authHeaderShort, API_URL, handleDownload} from '../helpers';
import {getBase64} from "../utils/utility";
import {downloadService} from "./download.service";


export const mediaService = {
    CreateMedia,
  GetAllMedia,
  GetMediaFromIdMedia,
  UpdateMediaActive,
  UpdateMediaName,
  GetAllMediaActive
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();


function CreateMedia(values,dropzoneAreaAttachments) {
  var MediaName =  values.MediaName;
  var MediaActive =  values.MediaActive;

  return guardedPromise("CreateMedia", () => new Promise((resolve, reject) => {
    const sendData = (data) => {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
      };
      fetch(`${API_URL}/Media/InsertMedia?MediaName=`+MediaName+`&MediaActive=`+MediaActive, requestOptions)
        .then(handleResponse)
        .then(responseData => {
          if (responseData) {
            return resolve(responseData);
          }
          reject("Empty Response for Edit");
        })
        .catch(error => reject(error));
    }


    let dataToSend = {
    };

    let promises = dropzoneAreaAttachments.map(async file =>  {
      const fileBased64 = await getBase64(file);
      return Promise.resolve({ fileName: fileBased64.fileName, base64: fileBased64.base64, status: "added" });
    });

    Promise.all(promises).then(files => {
      dataToSend = files;

      sendData(dataToSend);
    }, reason => {
      console.log(reason);
    });

  }));





}



function GetAllMedia() {
  return guardedPromise("GetAllMedia", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Media/GetAllMedia`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for media");
      })
      .catch(error => reject(error));
  }));
}





function GetAllMediaActive() {
  return guardedPromise("GetAllMediaActive", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Media/GetAllMediaActive`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for media");
      })
      .catch(error => reject(error));
  }));
}



function UpdateMediaActive(idMedia,mediaActive) {
  return guardedPromise("UpdateMediaActive", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Media/UpdateMediaActive?IdMedia=`+idMedia+`&MediaActive=`+mediaActive, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for media");
      })
      .catch(error => reject(error));
  }));
}


function UpdateMediaName(idMedia,mediaName) {
  return guardedPromise("UpdateMediaName", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Media/UpdateMediaName?IdMedia=`+idMedia+`&MediaName=`+mediaName, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for media");
      })
      .catch(error => reject(error));
  }));
}





function GetMediaFromIdMedia(idMedia) {
  return guardedPromise("GetMediaFromIdMedia", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Media/GetMedia?IdMedia=`+idMedia, requestOptions)
      .then(handleDownload)
      .then(responseData => {
        if (responseData) {
          const url = URL.createObjectURL(responseData);
          /*const a = document.createElement('a');
          //a.style.display = 'none';
          a.href = url;
          // the filename you want


          a.download = "AttachmentName.png";
          document.body.appendChild(a);
          a.click();
          //document.body.removeChild(a);
          //window.URL.revokeObjectURL(url);
          console.log(url)
*/

          return resolve(url);
        }
      })
      .catch(error => reject(error));
  }));
}
