import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {conventionService} from "../../../services/convention.service";
import {awardService} from "../../../services/award.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignAwardEdit = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const navigate = useNavigate();
  let dict = [];
  const [currentUser, setCurrentUser] = useState();
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);

  let [awardsData, setAwardsData] = useState([]);
  let [awardDataInPromo, setAwardDataInPromo] = useState([]);


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;

    dict=[]

    promoService.GetPromo(CampaignId).then(data => {
      console.log(data);
      setCampaignsSingleData(data);
    });


    promoService.GetAwardByPromoId(CampaignId)
      .then((data)=>{
        data.map((item,index)=>{
            dict.push(item);
        })

        setAwardDataInPromo(dict)

      })


    promoService.GetEligiblePromoAward(CampaignId)
      .then((data) => {

        const promoAwards = data.map(
          promoAward => {
            return {
              id: promoAward?.idAward,
              idAward: promoAward?.idAward,
              awardName: promoAward?.idAwardNavigation?.awardName,
              awardDescription: promoAward?.idAwardNavigation?.awardDescription,
              awardOnlyForFs: promoAward?.awardOnlyForFs ? true : false,
              awardPreSelected: promoAward?.awardPreSelected ? true : false,
              awardValue: promoAward?.awardValue ? promoAward?.awardValue : 0,
              awardSelected: promoAward?.awardSelected ? true : false,
              awardSplit: promoAward?.awardSplit ? true : false
            }
          }
        );

        setAwardsData(promoAwards);


      });


}, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}
        <Box mt={3}>
          <Results awardsData={awardsData} setAwardsData={setAwardsData} awardDataInPromo={awardDataInPromo}  idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignAwardEdit;


   
