import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


import validationSchema from './FormModel/validationSchema';
import conventionFormModel from './FormModel/conventionFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonConventionData from './Forms/CommonConventionData';
import AwardsJoined from './Forms/AwardsJoined';
import ResellersJoined from './Forms/ResellersJoined';
import TyresJoined from './Forms/TyresJoined';
import {downloadUploadService} from "../../../services/downloadUpload.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, awardsData,setAwardsData,awardDataInPromo,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let awardToAdd=[];
  let awardToModify=[];
  let res={}
  // renderCell: (data) => {return moment().format("")}
  const { formId, formField } = conventionFormModel;




  const handleAwardSwitch = (e) => {
    let id = e.target.name;
    let checked = e.target.checked;
    let tempAward = [...awardsData];
    const currentIndex = tempAward.findIndex((award => award.idAward == id));
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardSelected = checked;
    if (tempAward[currentIndex].awardSelected === false)
    {
      tempAward[currentIndex].awardValue = '';
      tempAward[currentIndex].awardOnlyForFs = false;
      tempAward[currentIndex].awardPreSelected = false;

    }

    setAwardsData(tempAward);
  };



  const handleAwardText = (e) => {
    let id = e.target.name;
    let value = e.target.value;
    let tempAward = [...awardsData];
    const currentIndex = tempAward.findIndex((award => award.idAward == id));
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardValue = parseFloat(value);
    setAwardsData(tempAward);
  }

  const handleAwardCheckboxOnlyFS = (e) => {
    let id = e.target.name;
    let checked = e.target.checked;
    let tempAward = [...awardsData];
    const currentIndex = tempAward.findIndex((award => award.idAward == id));
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardOnlyForFs = checked;
    setAwardsData(tempAward);
  }

  const handleAwardCheckboxPreSelected = (e) => {
    let id = e.target.name;
    let checked = e.target.checked;
    let tempAward = [...awardsData];
    const currentIndex = tempAward.findIndex((award => award.idAward == id));
    if (currentIndex===-1)
    {
      return false;
    }
    tempAward[currentIndex].awardPreSelected = checked;
    setAwardsData(tempAward);
  }


  function arraysEqual(a1,a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1)==JSON.stringify(a2);
  }


const salva=async ()=>{
  awardToAdd=[];
  awardToModify=[];
  res={}
  awardsData.map((item, index) => {
    if (item.awardSelected){
      awardToAdd.push(item)
    }
  });



//togliere da awardToAdd gli award già esistenti
  //aggiungere ad awardToModify gli award già esistenti ma differenti

  awardDataInPromo.map((item,index)=>{

    awardToAdd.map((item1,index1)=>{
      if(item.idAward==item1.idAward){
        if(item.awardValue!=item1.awardValue || item.awardOnlyForFs!= item1.awardOnlyForFs || item.awardPreSelected!= item1.awardPreSelected || item.awardSplit!= item1.awardSplit){
          awardToModify.push(item1);
        }
        awardToAdd.splice(index1, 1);
      }
    })
  });
  res.aggiungere=awardToAdd
  res.modificare=awardToModify

  promoService.addEndUpdatePromoAWard(idCampaign,res)
    .then((data)=>{
      if(data==1) {
        const { value: newplafond } =  Swal.fire({
          title: 'Award aggiunti alla promo' ,
          showCancelButton: false,
        }).then((ret) =>{
          navigate("/backoffice/campaigns/award/"+idCampaign);
        })


      }
      else if(data==2){
        const { value: newplafond } =  Swal.fire({
          title: 'Award modificati alla promo' ,
          showCancelButton: false,
        }).then((ret) =>{
          navigate("/backoffice/campaigns/award/"+idCampaign);
        })

      }
      else if(data==3){
        const { value: newplafond } =  Swal.fire({
          title: 'Award aggiunti e modificati alla promo' ,
          showCancelButton: false,
        }).then((ret) =>{
          navigate("/backoffice/campaigns/award/"+idCampaign);
        })

      }
      else{
        const { value: newplafond } =  Swal.fire({
          title: 'Errore' ,
          showCancelButton: false,
        }).then((ret) =>{
          navigate("/backoffice/campaigns/award/"+idCampaign);
        })
      }
    })
};




  return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
    <AwardsJoined
      formField={formField}
      conventionId={idCampaign}
      AwardsData={awardsData}
      HandleAwardSwitch={handleAwardSwitch}
      HandleAwardText={handleAwardText}
      HandleAwardCheckboxOnlyFS={handleAwardCheckboxOnlyFS}
      HandleAwardCheckboxPreSelected={handleAwardCheckboxPreSelected}  />
    <Button style={{marginLeft: '2em', marginBottom: '2em'}}
      type="submit"
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={salva}
    >
     Salva
    </Button>
  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  awardsData: PropTypes.array.isRequired,
  awardDataInPromo: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
