import React, { useEffect, useState } from 'react';
import { Grid, Typography, Checkbox, TextField, Divider } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../../..';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';

import Tooltip from '@material-ui/core/Tooltip';
import RedeemIcon from '@material-ui/icons/Redeem';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { awardService } from '../../../../services/award.service';
import BuildIcon from '@material-ui/icons/Build';


import { GiCarWheel } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(3)
  },
  inline: {
    display: 'inline',
  },
  textField: {
    height: theme.spacing(3),
  }
}));



export default function AwardsJoined(props) {
  const {
    formField: {
      VoucherCode,
      Awards
    }, 
    conventionId,
    AwardsData,
    HandleAwardSwitch,
    HandleAwardText,
    HandleAwardCheckboxOnlyFS,
    HandleAwardCheckboxPreSelected
  } = props;
 


  const classes = useStyles();
  return (


    <List subheader={<ListSubheader>Available awards</ListSubheader>} className={classes.root}  >

      {AwardsData.map((award) => ( 
        <ListItem>
          {/* {award.awardOnlyForFs && <Tooltip title={award.awardDescription}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
              </Tooltip>} */}

          <Switch
            id={award.idAward}
            name={award.idAward}
            edge="end"
            onChange={HandleAwardSwitch}
            checked={award.awardSelected}
          />

          <ListItemText id={award.idAward} primary={
            <React.Fragment>
              <Typography
                component="span"
                variant="h3"
                className={classes.inline}
                color="textPrimary"
              >
                {award.awardName}
              </Typography>
              

            
            </React.Fragment>
          }>

          </ListItemText>



          <ListItemSecondaryAction>
            {award.awardSelected &&
              <>
                <TextField
                className={classes.textField}
                name={award.idAward} 
                id={award.idAward}
                label="Valore sconto (€)"
                type="number"
                onChange={HandleAwardText} 
                value={award.awardValue}
                InputLabelProps={{
                  shrink: true
                }}

                />
              <Tooltip title="Solo per rivenditori First Stop"> 
                <Checkbox
                  checked={award.awardOnlyForFs}
                  onChange={HandleAwardCheckboxOnlyFS}
                  name={award.idAward} 
                ></Checkbox>
              </Tooltip>

              {/* <Tooltip title="Premio pre-selezionato"> 
                <Checkbox
                  checked={award.awardPreSelected}
                  onChange={HandleAwardCheckboxPreSelected}
                  name={award.idAward} 
                ></Checkbox>
              </Tooltip> */}
              </>}
          </ListItemSecondaryAction>
        </ListItem> 
      ))}

    </List>

  );
}



