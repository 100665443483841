export default {
  formId: 'createVoucherForm',
  formField: { 
    IdPromo: {
      name: 'IdPromo',
      label: 'IdPromo',
      requiredErrorMsg: 'Promozione obbligatoria'
    },
    PromoTextSelected: {
      name: 'PromoTextSelected',
      label: 'Promozione',
      requiredErrorMsg: 'Promozione obbligatoria'
    },
    QtaVoucher: {
      name: 'QtaVoucher',
      label: 'Quantità cartoline',
      requiredErrorMsg: 'Numero di cartoline obbligatorio'
    },
    IdPromoAward: {
      name: 'IdPromoAward',
      label: 'Selezione premio',
      requiredErrorMsg: 'Selezione premio obbligatoria'
    } 
  }
};
