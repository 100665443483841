import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch, Container, CircularProgress
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },circularProgress: {
      display: "flex",
      justifyContent: "center",
      position: 'absolute',
      left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }
const Results = ({ className, campaigns,idCampaign, currentUser,...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [campaignsData, setCampaignsData] = useState([]);
  // renderCell: (data) => {return moment().format("")}
  const [lunghezza, setLunghezza] = useState(0);
  const [fetch, setFetch] = useState(true);






  const headCells = [
    {field: 'idPromoReseller', headerName: 'idPromoReseller',  width: 150, description:'The internal idPromoReseller.'},
    {field: 'idPromo', headerName: 'idPromo',  width: 150, description:'The internal idPromo.'},
    {field: 'idReseller', headerName: 'idReseller',  width: 150, description:'The internal id Reseller.'},
    {field: 'sapCode', headerName: 'SapCode',  width: 150, description:'SapCode'},
    {field: 'shipToCode', headerName: 'ShipToCode',  width: 150, description:'ShipToCode'},
    {field: 'dealer', headerName: 'Dealer',  width: 500, description:'Dealer'},
    {field: 'plafondPromoReseller', headerName: 'Plafond', width: 200, description:'plafondPromoReseller',  renderCell : (params) => (
       <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          // style={{ marginLeft: 16 }}
          onClick={() => {  onEditPlafond(params.row.idPromoReseller, params.row.idResellerNavigation.sapCode,  params.row.plafondPromoReseller, params)}}
        > {params.row.plafondPromoReseller}
        </Button>
       </div>
       ), },
    {field: 'casaMadre', headerName: 'Casa Madre', width: 150, renderCell : (params) => (
      <strong> {params.row.idResellerNavigation.idParentCompany == 0 ? 'SI' : 'NO'}</strong>
      ),},
    {field: 'Cancella', headerName: 'Cancella', width: 150, description:'Cancella Reseller',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancReseller(params.row.idResellerNavigation.idReseller) }}
          >Cancella
          </Button>
        </div>
      ), },
    {field: 'Giustificativo', headerName: 'Giustificativo', width: 150, description:'Get Giustificativo',  renderCell : (params) => (
        <div>
          {params.row.hadPractice? (
            <Button
              color="secondary"
              // fullWidth
              size="small"
              type="submit"
              variant="contained"
              onClick={() => { getPratiche(params.row.idPromo,params.row.idReseller) }}
            >
              GIUSTIFICATIVO
            </Button>
          ) : (
            <Button
              color="secondary"
              // fullWidth
              size="small"
              type="submit"
              variant="contained"
              disabled={true}

            >
              GIUSTIFICATIVO
            </Button>
          )}
        </div>
      ), },

    /*  {field: 'businessPromoAttribute', headerName: 'Business', width: 125, description:'The id used for campaign.'},*/
  /*{field: 'countryPromoAttribute', headerName: 'Country',  width: 100, description:'The internal name of the campaign.'},*/
  /*{field: 'startViewPromo', headerName: 'Start view', width: 225, description:'The start date of view of the campaign.'},
  {field: 'startVoucherRequestPromo',  headerName: 'Start voucher request',  width: 175, description:'The start date for voucher request of the campaign.'},
  {field: 'endVoucherRequestPromo', headerName: 'End voucher rederequest', width: 225, description:'The end date for voucher request of the campaign.'},
  {field: 'endVoucherRedemeedPromo', headerName: 'End voucher redemeed',  width: 225, description:'The end date for voucher redemeed of the campaign.'},
  {field: 'endVoucherRedemeedFirstopPromo', headerName: 'End voucher redemeed for FS', width: 225, description:'The end date for voucher redemeed of the campaign, for Firststop reseller.'},
  {field: 'endViewPromo', headerName: 'End view',  width: 175, description:'The end date of view of the campaign.'},*/

];






let cancReseller= async (idReseller)=>{

  await promoService.DeletePromoResellerByIdPromoReseller(idReseller,idCampaign).then(data => {
    if(data==1) {
      const {value: newplafond} = Swal.fire({
        title: 'Reseller Cancellato',
        showCancelButton: false,
      }).then((ret) => {
        window.location.reload();
      })

    }else if(data==-1)
    {
      const {value: newplafond} = Swal.fire({
        title: 'Il reseller ha pratiche, non è possibile cancellarlo',
        showCancelButton: false,
      })
    }else{
      const { value: newplafond } =  Swal.fire({
        title: "Errore nella cancellazione del Reseller" ,
        showCancelButton: false,

      })
    }


  });
}


  let getPratiche = (idPromo,idReseller) => {

    navigate("/backoffice/campaigns/giustificativo/"+idPromo+"/"+idReseller);
  }


  let addReseller = async () => {

    console.log("click");
    var html="<select id='reseller' autocomplete=\"on\" style='width: 100%'>"

    await promoService.GetResellerNotInPromo(idCampaign).then((data) => {
      data.map((item, index) => html+=" <option value="+item.idReseller+">"+item.sapCode+" "+item.shipToCode+" "+item.name+" </option>");
    });

    html+="</select>"


    const { value: newplafond } =  Swal.fire({
      title: 'Seleziona il dealer da aggiungere' ,
      showCancelButton: true,
      html:html

    }).then((ret) =>{
      if (ret.isConfirmed){
        var select = document.getElementById('reseller');
        var idReseller = select.options[select.selectedIndex].value;



        promoService.AddResellerInPromoReseller(idCampaign,idReseller).then(data => {
          if(data==1) {
            const { value: newplafond } =  Swal.fire({
              title: 'Reseller aggiunto' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })


          }else{
            const { value: newplafond } =  Swal.fire({
              title: "Errore nell'aggiunta del Reseller" ,
              showCancelButton: false,

            })
          }


        });

      }
    })



  }


  let getLunghezza=()=>{
    return lunghezza
  }





  let onEditPlafond = (idPromoReseller,sapCode, plafondPromoReseller, p) => {

    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica Plafond per Sapcode ' + sapCode,
      input: 'number',
      inputLabel: 'Inserire il nuovo plafond',
      inputValue: plafondPromoReseller,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore, anche 0'
        }
      }
    }).then((ret) =>{
      if (ret.isConfirmed){
        p.row.plafondPromoReseller  = ret.value;
         var data = {
           IdPromoReseller: p.row.idPromoReseller,
           IdPromo: p.row.idPromo,
           IdReseller: p.row.idReseller,
           PlafondPromoReseller: Number(p.row.plafondPromoReseller),
           PlafondTypePromoReseller: p.row.plafondTypePromoReseller

        }




        promoService.UpdatePlafondReseller(p.row.idPromo, data).then(data => {
          Swal.fire('Update effettuato');
          promoService.GetPromoReseller(idCampaign).then((data) => {
            data.map((item, index) => data[index].id=item.idReseller);
            setCampaignsData(data);
          });


        });

      }


    })



  }
  useEffect(() => {
    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;
    setFetch(true)
    promoService.GetPromoReseller(idCampaign).then((data) => {
      data.map((item, index) => {
        data[index].id=item.idReseller
        data[index].sapCode=parseInt(item.idResellerNavigation.sapCode)
        data[index].shipToCode=parseInt(item.idResellerNavigation.shipToCode)
        data[index].dealer=item.idResellerNavigation.name
      });
      setCampaignsData(data);
      setLunghezza(data.length)
      setFetch(false)
    });

  }, [currentUser]);
return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { if(currentUser.role === 'Admin' || currentUser.role === "Backoffice" || currentUser.role === "Bridgestone")addReseller() }}
          >
            Add Reseller
          </Button>
          <Button style={{marginLeft: "1%"}}
                  variant="contained"
                  color="primary"
                  size="small"
            // style={{ marginLeft: 16 }}
                  onClick={() => {if(currentUser.role === 'Admin' || currentUser.role === "Backoffice" || currentUser.role === "Bridgestone")navigate("/backoffice/campaigns/resellerEdit/"+idCampaign)}}
          >
            Add list of Resellers
          </Button>
        </div>
      <div id='TableInCampaign'>



        {!fetch &&
        <DataGrid
          columns={headCells}
          rows={campaignsData}
          pageSize={100}
          rowsPerPageOptions={[50,100,300,500,parseInt(lunghezza)]}


          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />}
        {fetch &&
        <Container className={classes.circularProgress}>
          <CircularProgress
            size={48}
          />
        </Container>
        }
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  campaigns: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
