import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Avatar,
  Button,
  Card,
  CardMedia,
  colors,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  IconButton,
  makeStyles,
  withStyles,
  Grid,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Switch,
  Paper
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from "@material-ui/icons/Delete"
import CancelIcon from '@material-ui/icons/Cancel'
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from "react-router-dom";
import { InputField, AutocompleteField } from '../../../../FormFields';
import DescriptionIcon from '@material-ui/icons/Description';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RedeemIcon from '@material-ui/icons/Redeem';
import StorageIcon from '@material-ui/icons/Storage';
import ReorderIcon from '@material-ui/icons/Reorder';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NoteIcon from '@material-ui/icons/Note';
import CodeIcon from '@material-ui/icons/Code';
import { GiCarWheel } from "react-icons/gi";
import { ImWrench } from "react-icons/im";
import { authenticationService } from '../../../../services/authentication.service';
// import { practiceService } from '../../../services/practice.service';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  card: {
    width: '100%',
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },

  gridList: {
    width: '100%',
    // height: 450,
    backgroundColor: "red"
  },
  media: {
    height: 20,
  },
  action: {
    height: 100,
  }
}));


export default function PracticeDataBo(props) {
  const {
    formField: {
      IdPromo,
      Reseller,
      PromoInternalName,
      PromoExternalName,
      ProspectName,
      VoucherCode,
      AwardRefund,
      PurchaseAmount,
      DateIns,
      PracticeNrBo,
      PracticeState,
      PracticeNote,
      QtaPneumaticiUno,
      QtaPneumaticiDue,
      QtaPneumaticiTre,
      ModelPneumaticiUno,
      ModelPneumaticiDue,
      ModelPneumaticiTre,
      InvoiceService1Attribute,
      InvoiceService2Attribute,
      MarcaAttribute,
      ModelloAttribute,
      PremioAttribute
    }, 
    tyres,
    practiceState,
    attachments,
    downloadFile,
    dropzoneAreaChange,
    deleteFile,
    boEmails,
    setEmailTemplate,
    sendEmail,
    isSendingEmail,
    resultSendingEmail,    
    showPracticeAttributes,
    practiceAttributes 
  } = props;

  const classes = useStyles();
    
  function handleDeleteFile(idPracticeAttachment, event) {
    event.stopPropagation();
    event.preventDefault();
    deleteFile(idPracticeAttachment);
  }

  function handleDownloadFile(idPracticeAttachment, fileNameAttachment) {
    downloadFile(idPracticeAttachment, fileNameAttachment)
  }
  return (

    <Grid container spacing={3} className={classes.grid}>


      <Grid item xs={12} sm={6}>
        <InputField
          name={PromoInternalName.name}
          label={PromoInternalName.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputField
          name={PromoExternalName.name}
          label={PromoExternalName.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Grid>



      <Grid item xs={12} sm={8} md={8} lg={8}>
        <InputField
          name={ProspectName.name}
          label={ProspectName.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <InputField
          name={DateIns.name}
          label={DateIns.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ScheduleIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={3}>
        <InputField
          name={PracticeNrBo.name}
          label={PracticeNrBo.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StorageIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={3}>
        <InputField
          name={AwardRefund.name}
          label={AwardRefund.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RedeemIcon />
              </InputAdornment>
            ),
            // readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={3}>
        <InputField
          name={PurchaseAmount.name}
          label={PurchaseAmount.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RedeemIcon />
              </InputAdornment>
            ),
            // readOnly: true,
          }}
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>

      

      <Grid item xs={12} sm={3} md={3} lg={3}>
        <AutocompleteField
          name={PracticeState.name}
          label={PracticeState.label}
          data={practiceState}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <InputField
          name={VoucherCode.name}
          label={VoucherCode.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CodeIcon />
              </InputAdornment>
            ),
            // readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={8} md={8} lg={8}>
        <InputField
          name={Reseller.name}
          label={Reseller.label}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GiCarWheel /> 
              </InputAdornment>
            ),
            // readOnly: true,
          }}
        />
      </Grid>
 
      {showPracticeAttributes &&
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputField
              name={InvoiceService1Attribute.name}
              label={InvoiceService1Attribute.label}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImWrench />
                  </InputAdornment>
                ),
                // readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputField
              name={InvoiceService2Attribute.name}
              label={InvoiceService2Attribute.label}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImWrench />
                  </InputAdornment>
                ),
                // readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputField
              name={ MarcaAttribute.name}
              label={MarcaAttribute.label}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImWrench />
                  </InputAdornment>
                ),
                 readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputField
              name={ ModelloAttribute.name}
              label={ModelloAttribute.label}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImWrench />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputField
              name={ PremioAttribute.name}
              label={PremioAttribute.label}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImWrench />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Grid>
        </>

      }

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <InputField
          name={PracticeNote.name}
          label={PracticeNote.label}
          fullWidth
          multiline
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NoteIcon />
              </InputAdornment>
            )
            // readOnly: true,
          }}
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Autocomplete 
          options={boEmails}
          getOptionLabel={(option) => option.description} 
          onChange={(event, newValue) => {
            setEmailTemplate(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Selezionare Tipo email..." variant="outlined" />}
        />
      </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={(event, value) => { 
                      sendEmail(value);
                    }}>INVIA EMAIL
                        </Button>
                        <Typography color="secondary" variant="h6" component="h6">{resultSendingEmail}</Typography>
            
          </Grid>

      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Gestione pneumatici
        </Typography>
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputField
              name={QtaPneumaticiUno.name}
              label={QtaPneumaticiUno.label}
              fullWidth
              type="number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ReorderIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <AutocompleteField
              name={ModelPneumaticiUno.name}
              label={ModelPneumaticiUno.label}
              data={tyres}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputField
              name={QtaPneumaticiDue.name}
              label={QtaPneumaticiDue.label}
              fullWidth
              type="number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ReorderIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <AutocompleteField
              name={ModelPneumaticiDue.name}
              label={ModelPneumaticiDue.label}
              data={tyres}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputField
              name={QtaPneumaticiTre.name}
              label={QtaPneumaticiTre.label}
              fullWidth
              type="number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ReorderIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8}>
            <AutocompleteField
              name={ModelPneumaticiTre.name}
              label={ModelPneumaticiTre.label}
              data={tyres}
            />
          </Grid>
        </Grid>
      </Card>
 


      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Carica documenti
        </Typography>
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DropzoneArea
              onChange={(files) => dropzoneAreaChange(files)}
              acceptedFiles={['image/*', 'application/pdf']}
              filesLimit={1}
              dropzoneText={"Trascinare qui il file o clicca sull'area"}
              maxFileSize={5000000}
            />
          </Grid>
        </Grid>
      </Card>

      {attachments && attachments.length > 0 && attachments.some(a => a.status !== "deleted") &&
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Documenti caricati
        </Typography>

          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {attachments.map(attachment => attachment.status != "deleted" && (
              <Grid item xs={12} sm={6} md={3} key={attachment.idPracticeAttachment}>
                <Card>
                  <CardActionArea className={classes.action}
                    onClick={() => handleDownloadFile(attachment.idPracticeAttachment, attachment.fileName)}>
                  <CardMedia
                  className={classes.media}>
                  </CardMedia>
                  <CardHeader
                    title={`${attachment.fileName}`}
                    action={
                      <IconButton
                        onClick={(event) => handleDeleteFile(attachment.idPracticeAttachment, event)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Card>
      }
    </Grid>
  );
}
