import editPracticeFormModel from './editPracticeFormModel';
const {
  formField: {
    IdPromo,
    PromoInternalName,
    PromoExternalName,     
    ProspectName,
    VoucherCode,
    AwardRefund,
    DateIns, 
    PracticeNrBo,
    PracticeStateBkb,
    QtaPneumaticiUno,
    QtaPneumaticiDue,
    QtaPneumaticiTre
  }
} = editPracticeFormModel;

export default { 
  [IdPromo.name]: '',
  [PromoInternalName.name]: '',
  [PromoExternalName.name]: '',    
  [ProspectName.name]: '',
  [VoucherCode.name]: '',
  [AwardRefund.name]: '',
  [DateIns.name]: '',  
  [PracticeNrBo.name]: 0,
  [PracticeStateBkb.name]: '',
  [QtaPneumaticiUno.name]: '',
  [QtaPneumaticiDue.name]: '',
  [QtaPneumaticiTre.name]: '',
}; 


