import mediaFormModel from './mediaFormModel';
const {
  formField: {
    MediaName,
    MediaActive
  }
} = mediaFormModel;

export default {
  [MediaName.name]: '',
  [MediaActive.name]: ''
};

