import React , { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';





function Table(props) {

  const dati = props.data;
  const totRimborso=props.totaleRimborso;
  const setIsFetchingTable=props.setIsFetchingTable;
  let fine=false;

    return (
      dati.map((val, key) => {
      fine=key==dati.length-1;

       return(
      <div key={key} id="download" className="download" style={{marginTop:'150%' }}>
        <div key={key} className="test" align="center" valign="middle" style={{width: '100%'}}>
          <table key={key} border="1"  style={{width: '95%', borderCollapse:"collapse"}}>
            <tbody key={key} >
            <tr key={key}>
              <th>NPratica</th>
              <th>Stato</th>
              <th>Data pagamento</th>
              <th>Cliente</th>
              <th>Codice Cartolina</th>
              <th>Rimborso</th>
            </tr>
            {val.map((x, y) => {
              return (

                <tr key={y}>
                  <td>{x.nPratica}</td>
                  <td>{x.stato}</td>
                  <td>{x.dataPagamento}</td>
                  <td>{x.cliente}</td>
                  <td>{x.codiceCartolina}</td>
                  <td>{x.rimborso}</td>
                </tr>
              );
            })}

            {fine  && (

              <tr rowSpan="2">
                <td colSpan="5" style={{fontWeight: "bold", fontSize:"90%",textAlign: "right", color: "red"}}>Totale Rimborso</td>
                <td style={{fontWeight: "bold",fontSize:"90%", color: "red"}}>{totRimborso}</td>
              </tr>

              )}{setIsFetchingTable(false)}
            </tbody>
          </table>
        </div>
      </div>
       );
      })
    );


}

Table.defaultProps = {
  data: [],
  totaleRimborso: "",

};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  totaleRimborso: PropTypes.string.isRequired,
  setIsFetchingTable:PropTypes.func.isRequired
};

export default Table;
