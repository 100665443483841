import editPracticeFormModel from './editPracticeFormModel';
const {
  formField: {
    IdPromo,
    IdReseller,
    Reseller,
    PromoInternalName,
    PromoExternalName,     
    ProspectName,
    VoucherCode,
    AwardRefund,
    PurchaseAmount,
    DateIns, 
    PracticeNrBo,
    PracticeState,
    PracticeNote,
    QtaPneumaticiUno,
    QtaPneumaticiDue,
    QtaPneumaticiTre,
    InvoiceService1Attribute,
    InvoiceService2Attribute,
    MarcaAttribute,
    ModelloAttribute,
    PremioAttribute
  }
} = editPracticeFormModel;

export default { 
  [IdPromo.name]: '',
  [PromoInternalName.name]: '',
  [PromoExternalName.name]: '',    
  [ProspectName.name]: '',
  [VoucherCode.name]: '',
  [Reseller.name]: '',
  [AwardRefund.name]: '',
  [PurchaseAmount.name]: '',   
  [DateIns.name]: '',  
  [PracticeNrBo.name]: 0,
  [PracticeState.name]: '',
  [PracticeNote.name]: '',
  [QtaPneumaticiUno.name]: '',
  [QtaPneumaticiDue.name]: '',
  [QtaPneumaticiTre.name]: '',
  [InvoiceService1Attribute.name]: '',
  [InvoiceService2Attribute.name]: '',
  [MarcaAttribute.name]: '',
  [ModelloAttribute.name]: '',
  [PremioAttribute.name]: ''
}; 

