import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, tyre,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}





  const headCells = [
    {field: 'id', headerName: 'Id Promo Tyre',  width: 150, description:'The internal idPromoTyre.'},
    {field: 'supplierCode', headerName: 'Supplier Code',  width: 150, description:'The internal supplierCode.'},
    {field: 'prodGrp1', headerName: 'prodGrp1',  width: 150, description:'The internal prodGrp1.'},
    {field: 'description1',   headerName: 'Description',  width: 300, description:'The internal description1.'},
    {field: 'wdkBrandText',   headerName: 'Brand Text',  width: 150, description:'The internal wdkBrandText.'},
    {field: 'iswinter',   headerName: 'Is winter',  width: 150, description:'The internal iswinter.'},
    {field: 'Cancellazione', headerName: 'Cancellazione', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancelTyre(params.row.id) }}
          >
            Cancella
          </Button>


        </strong>
      ),
    }

  ];

  let addTyre = async () => {

    var html="<select id='tyre' autocomplete=\"on\">"



    await promoService.GetTyreNotInPromo(idCampaign).then((data) => {

      data.map((item, index) => html+=" <option value="+item.idTyre+">"+item.description1+" </option>");
    });
    html+="</select>"


    const { value: newplafond } =  Swal.fire({
      title: 'Seleziona la ruota da aggiungere' ,
      showCancelButton: true,
      html:html

    }).then((ret) =>{
      if (ret.isConfirmed){
        var select = document.getElementById('tyre');
        var idTyre = select.options[select.selectedIndex].value;
        promoService.AddTyreInPromoTyre(idCampaign,idTyre).then(data => {
          if(data==1) {
            const { value: newplafond } =  Swal.fire({
              title: 'Tyre aggiunto' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })


          }else{
            const { value: newplafond } =  Swal.fire({
              title: "Errore nell'aggiunta del tyre" ,
              showCancelButton: false,

            }).then((ret) =>{
              window.location.reload();
            })
          }


        });

      }
    })
  }

  let cancelTyre = (idPromoTyre) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Cancellare questa ruota per la campagna ' +idCampaign ,
      showCancelButton: true,

    }).then((ret) =>{
      if (ret.isConfirmed){

        promoService.DeletePromoTyreByIdPromoTyre(idPromoTyre).then(data => {
          if(data==1) {


            const { value: newplafond } =  Swal.fire({
              title: 'Cancellazione effettuata' ,
              showCancelButton: false,
            }).then((ret) =>{
                window.location.reload();
            })


          }else{
            const { value: newplafond } =  Swal.fire({
              title: 'Errore nella cancellazione' ,
              showCancelButton: false,

            }).then((ret) =>{
              window.location.reload();
            })
          }


        });

      }


    })



  }

return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {addTyre()}}
          >
            Add Tyre
          </Button>

          <Button style={{marginLeft: "1%"}}
                  variant="contained"
                  color="primary"
                  size="small"
            // style={{ marginLeft: 16 }}
                  onClick={() => {navigate("/backoffice/campaigns/tyreEdit/"+idCampaign)}}
          >
            Add list of Tyres
          </Button>
        </div>
      <div id='TableInCampaign'>







      <DataGrid
          columns={headCells}
          rows={tyre}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  tyre: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
