import awardFormModel from './awardFormModel';
const {
  formField: {
    AwardName,
    AwardDescription 
  }
} = awardFormModel;

export default {
  [AwardName.name]: '',
  [AwardDescription.name]: '' 
};

 