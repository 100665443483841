import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Reports from './Reports'; 

import { reportService } from '../../../services/report.service';
import { authenticationService } from 'src/services/authentication.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ReportListView = () => {
  const classes = useStyles();
  const [reportsData, setReportsData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
   
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => { 
    if (!currentUser) return;
    // if (currentUser.role!='Reseller') return; 
    reportService.GetReports(currentUser.id, currentUser.role).then((data) => {
      setReportsData(data);
    });
  }, [currentUser])
  return (
    <Page
      className={classes.root}
      title="Report"
    >
      <Container maxWidth={false}> 
        <Box mt={3}>
          <Reports reports={reportsData} />
        </Box>
      </Container>
    </Page>
  );
};

export default ReportListView;
