import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignPromoEdit = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const navigate = useNavigate();
  var dict = [];
  const [currentUser, setCurrentUser] = useState();

  const [campaignsSingleData, setCampaignsSingleData] = useState([]);


  let [conventionData, setConventionData] = useState({});


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;


    promoService.GetPromo(CampaignId).then(data => {
      setCampaignsSingleData(data);
    });


   promoService.GetPromo(CampaignId).then(data => {
     let type="";
     switch (data.typePromo) {
       case 1:
         type="Convention"
         break;
       case 2:
         type="CampaignVoucherFirst"
         break;
       case 3:
         type="CampaignPurchaseFirst"
         break;
       case 4:
         type="CampaignPurchaseFirstNoPlafond"
         break;
       case 5:
         type="CampaignPurchaseFirstNoVoucher"
         break;
       case 9:
         type="CampaignExternal"
         break;

     }

     setConventionData({
        PromoInternalName: data?.promoInternalName,
        PromoExternalName: data?.promoExternalName,
        CompanyConventionCodePromoAttribute: data?.companyConventionCodePromoAttribute,
        CompanyNameConventionPromoAttribute: data?.companyNameConventionPromoAttribute,
        CompanyEmailDomainConventionPromoAttribute: data?.companyEmailDomainConventionPromoAttribute,
        StartVoucherRequestPromo: data?.startVoucherRequestPromo,
        EndVoucherRequestPromo: data?.endVoucherRequestPromo,
        EndVoucherRedemeedPromo: data?.endVoucherRedemeedPromo,
        EndVoucherRedemeedFirstopPromo: data?.endVoucherRedemeedFirstopPromo,
        StartViewPromo: data?.startViewPromo,
        EndViewPromo: data?.endViewPromo,
        EndPracticeRbo: data?.endPracticeRbo,
        OnBekube:data?.onBekube,
        TypePromo:type
      });
    });


}, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}

        <Box mt={3}>
          <Results conventionData={conventionData} setConventionData={setConventionData}  idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignPromoEdit;


   
