import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid'; 


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));
  
   
  function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }

const Results = ({ className, campaigns, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}
  const headCells = [
    {field: 'idPromo', headerName: 'Id', width: 75, description:'The id used for campaign.'},
    {field: 'promoInternalName', headerName: 'Internal name',  width: 325, description:'The internal name of the campaign.'},
    {field: 'promoExternalName', headerName: 'External name', width: 275, description:'The external name of the campaign. (URL Campaign)'},
    /*{field: 'businessPromoAttribute', headerName: 'Business', width: 125, description:'The id used for campaign.'},*/
  /*{field: 'countryPromoAttribute', headerName: 'Country',  width: 100, description:'The internal name of the campaign.'},*/
  /*{field: 'startViewPromo', headerName: 'Start view', width: 225, description:'The start date of view of the campaign.'},
  {field: 'startVoucherRequestPromo',  headerName: 'Start voucher request',  width: 175, description:'The start date for voucher request of the campaign.'},
  {field: 'endVoucherRequestPromo', headerName: 'End voucher rederequest', width: 225, description:'The end date for voucher request of the campaign.'},
  {field: 'endVoucherRedemeedPromo', headerName: 'End voucher redemeed',  width: 225, description:'The end date for voucher redemeed of the campaign.'},
  {field: 'endVoucherRedemeedFirstopPromo', headerName: 'End voucher redemeed for FS', width: 225, description:'The end date for voucher redemeed of the campaign, for Firststop reseller.'},
  {field: 'endViewPromo', headerName: 'End view',  width: 175, description:'The end date of view of the campaign.'},*/


{field: 'EditPromo', headerName: 'Edit', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


            <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { navigate('promoEdit/'+ params.row.idPromo, { replace: false });}}
          >
            Promo
          </Button>


        </strong>
      ),
    },


{field: 'Edit', headerName: 'Dealer', width: 150, renderCell: (params) => (
     <strong>
      {params.value}


       <Button
         variant="contained"
         className={"button_campagne"}
         size="small"
         // style={{ marginLeft: 16 }}
         onClick={() => { onEditPlafond(params.row.idPromo) }}
       >
         Dealer
       </Button>


    </strong>
  ),
},
    {field: 'Voucher', headerName: 'Voucher', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('prospect/'+ params.row.idPromo, { replace: false });}}
          >
            VOUCHER
          </Button>


        </strong>
      ),
    },
    {field: 'Tyre', headerName: 'Tyre', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('tyre/'+ params.row.idPromo, { replace: false });}}
          >
            Tyre
          </Button>


        </strong>
      ),
    },
    {field: 'Award', headerName: 'Award', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('award/'+ params.row.idPromo, { replace: false });}}
          >
            Award
          </Button>


        </strong>
      ),
    },
    {field: 'PromoAttribute', headerName: 'Attribute', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('promoAttribute/'+ params.row.idPromo, { replace: false });}}
          >
            Attribute
          </Button>


        </strong>
      ),
    },
    {field: 'PromoEmail', headerName: 'Email', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('email/'+ params.row.idPromo, { replace: false });}}
          >
            Email
          </Button>


        </strong>
      ),
    },
    {field: 'PromoForm', headerName: 'Form', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('form/'+ params.row.idPromo, { replace: false });}}
          >
            Form
          </Button>


        </strong>
      ),
    },
    {field: 'TyreAsAward', headerName: 'Tyre As Award', width: 200, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  navigate('tyreAsAward/'+ params.row.idPromo, { replace: false });}}
          >
            Tyre As Award
          </Button>


        </strong>
      ),
    }
];

let onEdit = (PromoId) => {
  navigate('edit/'+ PromoId, { replace: false });
}
  let onEditPlafond = (PromoId) => {
    navigate('reseller/'+ PromoId, { replace: false });
  }
return (
  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
      <div id='allPromo' >
      <DataGrid
          columns={headCells}
          rows={campaigns}
          pageSize={20}
          rowsPerPageOptions={[5, 10, 20, 50]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
className: PropTypes.string,
campaigns: PropTypes.array.isRequired
};

export default Results;
