import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignTyreList = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const [tyreData, setTyreData] = useState([]);
  const navigate = useNavigate();
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);
  const [currentUser, setCurrentUser] = useState();

  var dict = [];

  if (isAddMode){
    navigate('/', { replace: false });
  }

  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;


    promoService.GetTyreByPromoId(CampaignId).then((data) => {
      data.map((item, index) => data[index].id=item.idPromoTyre);
      data.map((item,index)=>dict.push({"id":item.idPromoTyre, "supplierCode": item.idTyreNavigation.supplierCode, "prodGrp1":item.idTyreNavigation.prodGrp1, "description1": item.idTyreNavigation.description1,
      "wdkBrandText":item.idTyreNavigation.wdkBrandText, "iswinter":item.idTyreNavigation.iswinter}));
      setTyreData(dict);
    });


    promoService.GetPromo(CampaignId).then(data => {
      setCampaignsSingleData(data);
    });

  }, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}

        <Box mt={3}>
          <Results tyre={tyreData} idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignTyreList;


   
