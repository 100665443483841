import configData from './config.json';
import {authHeader, handleResponse, handleDownload, API_URL} from '../helpers';
import { downloadService} from 'src/services/download.service';


export const reportService = { 
    GetReports,
    GetReportById
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();

function GetReports(idUser, roleUser) {
    return guardedPromise("GetReports", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/report/getReport?IdUser=${idUser}&RoleUser=${roleUser}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response");
        })
        .catch(error => reject(error));
}));
}
 
function GetReportById(ReportId, IdUser, ReportName) {
    return guardedPromise("GetReportById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    console.log('${API_URL}/Report/ExecuteReport?IdReport=${ReportId}&IdReseller=${IdUser}');
    fetch(`${API_URL}/Report/ExecuteReport?IdReport=${ReportId}&IdReseller=${IdUser}`, requestOptions)
        .then(handleDownload)
        .then(blob => {
            if (blob) { 
                downloadService.downloadReport(blob, ReportName);
                return resolve();
            }
            
        })
        .catch(error => reject(error));
    }));
}




