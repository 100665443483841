import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link,
  TextField
} from '@material-ui/core';

 

import moment from 'moment';
import { Formik, Form } from 'formik';
import { useFormikContext } from 'formik';
import { useForm } from "react-hook-form";

import { useParams, useNavigate } from 'react-router-dom';

import validationSchema from './FormModel/validationSchema';
import tyreFormModel from './FormModel/tyreFormModel';
import formInitialValues from './FormModel/formInitialValues';


import TyreData from './Forms/TyreData';


import { awardService } from '../../../services/award.service';
import { alertService } from '../../../services/alert.service';
import {promoService} from "../../../services/promo.service";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(1)
    },
  }));

const steps = ['Tyres data'];
const { formId, formField } = tyreFormModel;


function _renderStepContent(step) {
  //console.clear();
    switch (step) {
      case 0:
        return <TyreData formField={formField}/>;
      default:
        return <div>Not Found</div>;
    }
  }

export default function AddEditTyre() {
    const navigate = useNavigate();
    const { TyresId } = useParams();
    const isAddMode = !TyresId;

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const currentValidationSchema = validationSchema[activeStep];

    const isLastStep = activeStep === steps.length - 1;
    // const { setValue, register, setFieldValue } = useForm();
    let [tyre, setTyre] = React.useState({});
    
 

    let [hasError, setHasError] = React.useState({
        value: false,
        description:''
    });

  
  useEffect(() => {
      if (!isAddMode)
      {
        /*awardService.GetAwardById(TyresId).then(data =>
            {
                console.log(data);
                setAward({awardName: data.awardName, awardDescription: data.awardDescription});
                // console.clear();
                // console.log('start');
                // console.log(formInitialValues);                
                // console.log(formInitialValues.AwardName);
                // formInitialValues.AwardName = data.awardName;
                // console.log(formInitialValues.AwardName);
                // console.log('end');
                //setValue("name", data.awardName); 
            });*/
        promoService.GetTyreIdTyre(TyresId).then(data=>{
          setTyre(data)
        })
      }
      else
      {

      }
    
  }, [])

 
  // async function _submitForm(values, actions) {
  //   actions.setSubmitting(false);

  //   ConventionService.postDataConventionRegistration(values)
  //   .then(
  //       r => {
  //         setHasError({value:false, description: ''});
  //         console.log(r);
  //         setActiveStep(activeStep + 1);
  //         actions.setTouched({});
  //         actions.setSubmitting(false);  
  //       },
  //       error => {
  //         setHasError({value:true, description: error});
  //         console.log('errore', hasError.value, hasError.description);
  //         setActiveStep(activeStep);
  //         actions.setTouched({});
  //         actions.setSubmitting(false);  
  //       }
  //   );
  // }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _handleSubmit(values, actions) {
    actions.setSubmitting(true);
    if (isLastStep) {
      _submitForm(values, actions);
    } 
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _submitForm(fields, { setStatus, setSubmitting }) { 
        // setStatus();
         if (isAddMode) {
           createTyre(fields, setSubmitting);
        } else {
          updateTyre(TyresId, fields, setSubmitting);
        }
    }

  let updateTyre= async (idTyre ,fields, setSubmitting)=>{



    let json={}
    json.idTyre=parseInt(idTyre);
    json.ean=fields.EAN==0 || fields.EAN?parseInt(fields.EAN):null;
    json.prodGrp1=fields.PROD_GRP_1 ?fields.PROD_GRP_1:"";
    json.supplierCode=fields.SUPPLIER_CODE==0 ||fields.SUPPLIER_CODE?parseInt(fields.SUPPLIER_CODE):null;
    json.description1=fields.DESCRIPTION_1?fields.DESCRIPTION_1:"";
    json.description2=fields.DESCRIPTION_2?fields.DESCRIPTION_2:"";
    json.prodInfo=fields.PROD_INFO?fields.PROD_INFO:"";
    json.wdkAnub=fields.WDK_ANUB?fields.WDK_ANUB:"";
    json.wdkBrand=fields.WDK_BRAND==0 || fields.WDK_BRAND?parseInt(fields.WDK_BRAND):null;
    json.wdkBrandText=fields.WDK_BRAND_TEXT?fields.WDK_BRAND_TEXT:"";
    json.brand=fields.BRAND?fields.BRAND:"";
    json.brandText=fields.BRAND_TEXT?fields.BRAND_TEXT:"";
    json.prodGrp2=fields.PROD_GRP_2?fields.PROD_GRP_2:"";
    json.groupDescription=fields.GROUP_DESCRIPTION?fields.GROUP_DESCRIPTION:"";
    json.weight=fields.WEIGHT==0 || fields.WEIGHT?parseInt(fields.WEIGHT):null;
    json.rimInch=fields.RIM_INCH==0 || fields.RIM_INCH?parseInt(fields.RIM_INCH):null;
    json.prodCycle=fields.PROD_CYCLE?fields.PROD_CYCLE:"";
    json.thirdParty=fields.THIRD_PARTY?fields.THIRD_PARTY:"";
    json.pl=fields.PL?fields.PL:"";
    json.tel=fields.TEL?fields.TEL:"";
    json.edi=fields.EDI?fields.EDI:"";
    json.adhoc=fields.ADHOC?fields.ADHOC:"";
    json.plId=fields.PL_ID?fields.PL_ID:"";
    json.url1=fields.URL_1?fields.URL_1:"";
    json.url2=fields.URL_2?fields.URL_2:"";
    json.url3=fields.URL_3?fields.URL_3:"";
    json.url4=fields.URL_4?fields.URL_4:"";
    json.url5=fields.URL_5?fields.URL_5:"";
    json.widthMm=fields.WIDTH_MM==0 || fields.WIDTH_MM?parseInt(fields.WIDTH_MM):null;
    json.widthInch=fields.WIDTH_INCH?fields.WIDTH_INCH:"";
    json.aspectRatio=fields.ASPECT_RATIO==0 || fields.ASPECT_RATIO?parseInt(fields.ASPECT_RATIO):null;
    json.ovlDiameter=fields.OVL_DIAMETER?fields.OVL_DIAMETER:"";
    json.construction1=fields.CONSTRUCTION_1?fields.CONSTRUCTION_1:"";
    json.construction2=fields.CONSTRUCTION_2?fields.CONSTRUCTION_2:"";
    json.usage=fields.USAGE?fields.USAGE:"";
    json.depth=fields.DEPTH?fields.DEPTH:"";
    json.li1=fields.LI1==0 || fields.LI1?parseInt(fields.LI1):null;
    json.li2=fields.LI2?fields.LI2:"";
    json.li3Dwb=fields.LI3DWB?fields.LI3DWB:"";
    json.li4Dwb=fields.LI4DWB?fields.LI4DWB:"";
    json.sp1=fields.SP1?fields.SP1:"";
    json.sp2=fields.SP2?fields.SP2:"";
    json.tlTt=fields.TLTT?fields.TLTT:"";
    json.flank=fields.FLANK?fields.FLANK:"";
    json.pr=fields.PR?fields.PR:"";
    json.rfd=fields.RFD?fields.RFD:"";
    json.sizePrefix=fields.SIZE_PREFIX?fields.SIZE_PREFIX:"";
    json.commMark=fields.COMM_MARK?fields.COMM_MARK:"";
    json.rimMm=fields.RIM_MM?fields.RIM_MM:"";
    json.runFlat=fields.RUN_FLAT?fields.RUN_FLAT:"";
    json.sidewall=fields.SIDEWALL?fields.SIDEWALL:"";
    json.design1=fields.DESIGN_1?fields.DESIGN_1:"";
    json.design2=fields.DESIGN_2?fields.DESIGN_2:"";
    json.productType=fields.PRODUCT_TYPE==0 || fields.PRODUCT_TYPE?parseInt(fields.PRODUCT_TYPE):null;
    json.vehicleType=fields.VEHICLE_TYPE?fields.VEHICLE_TYPE:"";
    json.condGrp=fields.COND_GRP?fields.COND_GRP:"";
    json.taxId=fields.TAX_ID?fields.TAX_ID:"";
    json.tax=fields.TAX?fields.TAX:"";
    json.suggestedPrice=fields.SUGGESTED_PRICE?fields.SUGGESTED_PRICE:"";
    json.grossPrice=fields.GROSS_PRICE?fields.GROSS_PRICE:"";
    json.gpValidFrom=fields.GP_VALID_FROM==0 || fields.GP_VALID_FROM?parseFloat(fields.GP_VALID_FROM):null;
    json.netValue=fields.NET_VALUE?fields.NET_VALUE:"";
    json.nvValid=fields.NV_VALID==0 ||fields.NV_VALID?parseFloat(fields.NV_VALID):null;
    json.recyclingFee=fields.RECYCLING_FEE?fields.RECYCLING_FEE:"";
    json.noisePerformance=fields.NOISE_PERFORMANCE?parseFloat(fields.NOISE_PERFORMANCE):"";
    json.noiseClassType=fields.NOISE_CLASS_TYPE==0 || fields.NOISE_CLASS_TYPE  ?fields.NOISE_CLASS_TYPE:"";
    json.rollingResistance=fields.ROLLING_RESISTANCE?fields.ROLLING_RESISTANCE:null;
    json.wetGrip=fields.WET_GRIP?fields.WET_GRIP:"";
    json.ecVehicleClass=fields.EC_VEHICLE_CLASS?fields.EC_VEHICLE_CLASS:"";
    json.euDirectiveNumber=fields.EU_DIRECTIVE_NUMBER?fields.EU_DIRECTIVE_NUMBER:"";
    json.business=fields.BUSINESS?fields.BUSINESS:"";
    json.iswinter=(fields.ISWINTER === 'true');


    await promoService.updateTyre(json).then(data => {
      if(data==1) {
        const {value: newplafond} = Swal.fire({
          title: 'Tyre updato',
          showCancelButton: false,
        }).then((ret) => {
        })
        setActiveStep(activeStep + 1);

      }
      else if(data==-2){
        const { value: newplafond } =  Swal.fire({
          title: "Update non avvenuto, esiste un Tyre con lo stesso SupplierCode" ,
          showCancelButton: false,

        })
      }
      else{
        const { value: newplafond } =  Swal.fire({
          title: "Update non avvenuto, i dati sono cambiati?" ,
          showCancelButton: false,

        })
      }
      setSubmitting(false);


    });
  }


  let createTyre= async (fields, setSubmitting)=>{



    let json={}
    json.ean=fields.EAN==0 || fields.EAN?parseInt(fields.EAN):null;
    json.prodGrp1=fields.PROD_GRP_1 ?fields.PROD_GRP_1:"";
    json.supplierCode=fields.SUPPLIER_CODE==0 ||fields.SUPPLIER_CODE?parseInt(fields.SUPPLIER_CODE):null;
    json.description1=fields.DESCRIPTION_1?fields.DESCRIPTION_1:"";
    json.description2=fields.DESCRIPTION_2?fields.DESCRIPTION_2:"";
    json.prodInfo=fields.PROD_INFO?fields.PROD_INFO:"";
    json.wdkAnub=fields.WDK_ANUB?fields.WDK_ANUB:"";
    json.wdkBrand=fields.WDK_BRAND==0 || fields.WDK_BRAND?parseInt(fields.WDK_BRAND):null;
    json.wdkBrandText=fields.WDK_BRAND_TEXT?fields.WDK_BRAND_TEXT:"";
    json.brand=fields.BRAND?fields.BRAND:"";
    json.brandText=fields.BRAND_TEXT?fields.BRAND_TEXT:"";
    json.prodGrp2=fields.PROD_GRP_2?fields.PROD_GRP_2:"";
    json.groupDescription=fields.GROUP_DESCRIPTION?fields.GROUP_DESCRIPTION:"";
    json.weight=fields.WEIGHT==0 || fields.WEIGHT?parseInt(fields.WEIGHT):null;
    json.rimInch=fields.RIM_INCH==0 || fields.RIM_INCH?parseInt(fields.RIM_INCH):null;
    json.prodCycle=fields.PROD_CYCLE?fields.PROD_CYCLE:"";
    json.thirdParty=fields.THIRD_PARTY?fields.THIRD_PARTY:"";
    json.pl=fields.PL?fields.PL:"";
    json.tel=fields.TEL?fields.TEL:"";
    json.edi=fields.EDI?fields.EDI:"";
    json.adhoc=fields.ADHOC?fields.ADHOC:"";
    json.plId=fields.PL_ID?fields.PL_ID:"";
    json.url1=fields.URL_1?fields.URL_1:"";
    json.url2=fields.URL_2?fields.URL_2:"";
    json.url3=fields.URL_3?fields.URL_3:"";
    json.url4=fields.URL_4?fields.URL_4:"";
    json.url5=fields.URL_5?fields.URL_5:"";
    json.widthMm=fields.WIDTH_MM==0 || fields.WIDTH_MM?parseInt(fields.WIDTH_MM):null;
    json.widthInch=fields.WIDTH_INCH?fields.WIDTH_INCH:"";
    json.aspectRatio=fields.ASPECT_RATIO==0 || fields.ASPECT_RATIO?parseInt(fields.ASPECT_RATIO):null;
    json.ovlDiameter=fields.OVL_DIAMETER?fields.OVL_DIAMETER:"";
    json.construction1=fields.CONSTRUCTION_1?fields.CONSTRUCTION_1:"";
    json.construction2=fields.CONSTRUCTION_2?fields.CONSTRUCTION_2:"";
    json.usage=fields.USAGE?fields.USAGE:"";
    json.depth=fields.DEPTH?fields.DEPTH:"";
    json.li1=fields.LI1==0 || fields.LI1?parseInt(fields.LI1):null;
    json.li2=fields.LI2?fields.LI2:"";
    json.li3Dwb=fields.LI3DWB?fields.LI3DWB:"";
    json.li4Dwb=fields.LI4DWB?fields.LI4DWB:"";
    json.sp1=fields.SP1?fields.SP1:"";
    json.sp2=fields.SP2?fields.SP2:"";
    json.tlTt=fields.TLTT?fields.TLTT:"";
    json.flank=fields.FLANK?fields.FLANK:"";
    json.pr=fields.PR?fields.PR:"";
    json.rfd=fields.RFD?fields.RFD:"";
    json.sizePrefix=fields.SIZE_PREFIX?fields.SIZE_PREFIX:"";
    json.commMark=fields.COMM_MARK?fields.COMM_MARK:"";
    json.rimMm=fields.RIM_MM?fields.RIM_MM:"";
    json.runFlat=fields.RUN_FLAT?fields.RUN_FLAT:"";
    json.sidewall=fields.SIDEWALL?fields.SIDEWALL:"";
    json.design1=fields.DESIGN_1?fields.DESIGN_1:"";
    json.design2=fields.DESIGN_2?fields.DESIGN_2:"";
    json.productType=fields.PRODUCT_TYPE==0 || fields.PRODUCT_TYPE?parseInt(fields.PRODUCT_TYPE):null;
    json.vehicleType=fields.VEHICLE_TYPE?fields.VEHICLE_TYPE:"";
    json.condGrp=fields.COND_GRP?fields.COND_GRP:"";
    json.taxId=fields.TAX_ID?fields.TAX_ID:"";
    json.tax=fields.TAX?fields.TAX:"";
    json.suggestedPrice=fields.SUGGESTED_PRICE?fields.SUGGESTED_PRICE:"";
    json.grossPrice=fields.GROSS_PRICE?fields.GROSS_PRICE:"";
    json.gpValidFrom=fields.GP_VALID_FROM==0 || fields.GP_VALID_FROM?parseFloat(fields.GP_VALID_FROM):null;
    json.netValue=fields.NET_VALUE?fields.NET_VALUE:"";
    json.nvValid=fields.NV_VALID==0 ||fields.NV_VALID?parseFloat(fields.NV_VALID):null;
    json.recyclingFee=fields.RECYCLING_FEE?fields.RECYCLING_FEE:"";
    json.noisePerformance=fields.NOISE_PERFORMANCE?parseFloat(fields.NOISE_PERFORMANCE):"";
    json.noiseClassType=fields.NOISE_CLASS_TYPE==0 || fields.NOISE_CLASS_TYPE  ?fields.NOISE_CLASS_TYPE:"";
    json.rollingResistance=fields.ROLLING_RESISTANCE?fields.ROLLING_RESISTANCE:null;
    json.wetGrip=fields.WET_GRIP?fields.WET_GRIP:"";
    json.ecVehicleClass=fields.EC_VEHICLE_CLASS?fields.EC_VEHICLE_CLASS:"";
    json.euDirectiveNumber=fields.EU_DIRECTIVE_NUMBER?fields.EU_DIRECTIVE_NUMBER:"";
    json.business=fields.BUSINESS?fields.BUSINESS:"";
    json.iswinter=(fields.ISWINTER === 'true');


    await promoService.createTyre(json).then(data => {
      if(data==1) {
        const {value: newplafond} = Swal.fire({
          title: 'Tyre creato',
          showCancelButton: false,
        }).then((ret) => {
        })
        setActiveStep(activeStep + 1);

      }
      else{
        const { value: newplafond } =  Swal.fire({
          title: "Creazione tyre non avvenuto, esiste già un tyre con questo SupplierCode?" ,
          showCancelButton: false,

        })
      }
      setSubmitting(false);


    });
  }


    function _endFlow() {
      if (isAddMode) {
        navigate('..', { replace: false });
      } else {
        navigate('../..', { replace: false });
      }

       
    }
    
 
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1> */}
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            TYRE SAVED
                          </Typography> 
                          <div className={classes.wrapper}>
                            <Button
                              onClick={_endFlow}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}>CLOSE
                            </Button>
                          </div>
                    </React.Fragment>
            ) : (
              <Formik
               initialValues={{

                 ["EAN"]:tyre.ean,
                 ["PROD_GRP_1"]:tyre.prodGrp1,
                 ["SUPPLIER_CODE"]:tyre.supplierCode,
                 ["DESCRIPTION_1"]:tyre.description1,
                 ["DESCRIPTION_2"]:tyre.description2,
                 ["PROD_INFO"]:tyre.prodInfo,
                 ["WDK_ANUB"]:tyre.wdkAnub,
                 ["WDK_BRAND"]:tyre.wdkBrand,
                 ["WDK_BRAND_TEXT"]:tyre.wdkBrandText,
                 ["BRAND"]:tyre.brand,
                 ["BRAND_TEXT"]:tyre.brandText,
                 ["PROD_GRP_2"]:tyre.prodGrp2,
                 ["GROUP_DESCRIPTION"]:tyre.groupDescription,
                 ["WEIGHT"]:tyre.weight,
                 ["RIM_INCH"]:tyre.rimInch,
                 ["PROD_CYCLE"]:tyre.prodCycle,
                 ["THIRD_PARTY"]:tyre.thirdParty,
                 ["PL"]:tyre.pl,
                 ["TEL"]:tyre.tel,
                 ["EDI"]:tyre.edi,
                 ["ADHOC"]:tyre.adhoc,
                 ["PL_ID"]:tyre.plId,
                 ["URL_1"]:tyre.url1,
                 ["URL_2"]:tyre.url2,
                 ["URL_3"]:tyre.url3,
                 ["URL_4"]:tyre.url4,
                 ["URL_5"]:tyre.url5,
                 ["WIDTH_MM"]:tyre.widthMm,
                 ["WIDTH_INCH"]:tyre.widthInch,
                 ["ASPECT_RATIO"]:tyre.aspectRatio,
                 ["OVL_DIAMETER"]:tyre.ovlDiameter,
                 ["CONSTRUCTION_1"]:tyre.construction1,
                 ["CONSTRUCTION_2"]:tyre.construction2,
                 ["USAGE"]:tyre.usage,
                 ["DEPTH"]:tyre.depth,
                 ["LI1"]:tyre.li1,
                 ["LI2"]:tyre.li2,
                 ["LI3DWB"]:tyre.li3Dwb,
                 ["LI4DWB"]:tyre.li4Dwb,
                 ["SP1"]:tyre.sp1,
                 ["SP2"]:tyre.sp2,
                 ["TLTT"]:tyre.tlTt,
                 ["FLANK"]:tyre.flank,
                 ["PR"]:tyre.pr,
                 ["RFD"]:tyre.rfd,
                 ["SIZE_PREFIX"]:tyre.sizePrefix,
                 ["COMM_MARK"]:tyre.commMark,
                 ["RIM_MM"]:tyre.rimMm,
                 ["RUN_FLAT"]:tyre.runFlat,
                 ["SIDEWALL"]:tyre.sidewall,
                 ["DESIGN_1"]:tyre.design1,
                 ["DESIGN_2"]:tyre.design2,
                 ["PRODUCT_TYPE"]:tyre.productType,
                 ["VEHICLE_TYPE"]:tyre.vehicleType,
                 ["COND_GRP"]:tyre.condGrp,
                 ["TAX_ID"]:tyre.taxId,
                 ["TAX"]:tyre.tax,
                 ["SUGGESTED_PRICE"]:tyre.suggestedPrice,
                 ["GROSS_PRICE"]:tyre.grossPrice,
                 ["GP_VALID_FROM"]:tyre.gpValidFrom,
                 ["NET_VALUE"]:tyre.netValue,
                 ["NV_VALID"]:tyre.nvValid,
                 ["RECYCLING_FEE"]:tyre.recyclingFee,
                 ["NOISE_PERFORMANCE"]:tyre.noisePerformance,
                 ["NOISE_CLASS_TYPE"]:tyre.noiseClassType,
                 ["ROLLING_RESISTANCE"]:tyre.rollingResistance,
                 ["WET_GRIP"]:tyre.wetGrip,
                 ["EC_VEHICLE_CLASS"]:tyre.ecVehicleClass,
                 ["EU_DIRECTIVE_NUMBER"]:tyre.euDirectiveNumber,
                 ["BUSINESS"]:tyre.business,
                 ["ISWINTER"]:tyre.iswinter
              }}
              // initialValues={formInitialValues}
              enableReinitialize={true}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  
                  {_renderStepContent(activeStep)} 
                  
                  <div className={classes.buttons}>

                  {hasError.value && (
                      <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                    )}
 
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Precedente
                      </Button>
                    )}
                    
                    <div className={classes.wrapper}>

                   
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? 'Confirm' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
}
