import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { voucherService } from '../../../services/voucher.service';
import { authenticationService } from 'src/services/authentication.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const VoucherListView = () => {
  const classes = useStyles(); 
  const [vouchersData, setVoucherData] = useState([]); 

  const [currentUser, setCurrentUser] = useState();
   
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.role!='Reseller') return;
    voucherService.GetVoucherByReseller(currentUser.id).then((data) => {
      data.map((item, index) => data[index].id=item.idLog);
      data.map((item, index) => data[index].promoInternalName=item.idPromoNavigation.promoInternalName);
      data.map((item, index) => data[index].vouchersList=item.vouchersList.replaceAll(",", ""));
      setVoucherData(data);
    });
  }, [currentUser])
 

 



  return (
    <Page
      className={classes.root}
      title="Cartoline"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results vouchers={vouchersData} />
        </Box>
      </Container>
    </Page>
  );
};

export default VoucherListView;


   
