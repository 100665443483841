import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { awardService } from '../../../services/award.service';
import {promoService} from "../../../services/promo.service";
import {authenticationService} from "../../../services/authentication.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const TyreListView = () => {
  const classes = useStyles();
  const [tyreData, setTyreData] = useState([]);
  const [currentUser, setCurrentUser] = useState();


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;

    promoService.GetTyre().then((data) => {
      data.map((item, index) => data[index].id=item.idTyre);
      setTyreData(data);
    });
  }, [currentUser])


  return (
    <Page
      className={classes.root}
      title="Tyre"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results tyre={tyreData} />
        </Box>
      </Container>
    </Page>
  );
};

export default TyreListView;

