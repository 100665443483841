export default {
  formId: 'mediaForm',
  formField: {
    MediaName: {
      name: 'MediaName',
      label: 'Media name',
      requiredErrorMsg: 'Media name is required',
    },
    MediaActive: {
      name: 'MediaActive',
      label: 'Media active',
      requiredErrorMsg: 'Media active is required'
    }
  }
};

