import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Backoffice Bridgestone"
      src="/static/tri-logo.svg"
      {...props}
      width="100"
    />
  );
};

export default Logo;
