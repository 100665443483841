import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
 

import { authenticationService } from '../../services/authentication.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



export default function ChangeCreateLoginView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { tokenValidation } = useParams();

  let [account, setAccount] = useState({
    password: '',
    confirm: '' 
  });

  let [isSubmitting, setIsSubmitting] = useState(false); 
  
  let [hasError, setHasError] = useState({
    value: false,
    description:''
  });

  let [weekPassword, setWeekPassword] = useState({
    value: false,
    description:''
  });

  let [isOk, setIsOk] = useState({
    value: false,
    description:''
  });

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    account[name] = value;
    setAccount(account); 
  }

  useEffect(() => {
    if (tokenValidation) 
    {
      authenticationService.checkToken(tokenValidation)
        .then(
            data => { 
              //   const { from } = this.props.location.state || { from: { pathname: "/" } };
              //   this.props.history.push(from);
              setIsSubmitting(false);
              setIsOk({value:true, description: ''});
              
              // navigate('/', { replace: true });
            },
            error => {
              setHasError({value:true, description: error});
              console.log('errore', hasError.value, hasError.description);
              setIsSubmitting(false);
            }
        );
    }
    else
    {
     
    }
  
  }, [])

  
 let handleSubmit = (event) => {
      event.preventDefault();
      console.log(account.password);
      console.log(account.confirm);
      authenticationService.createPassword(account.password, account.confirm, tokenValidation)
          .then(
              user => { 
                //   const { from } = this.props.location.state || { from: { pathname: "/" } };
                //   this.props.history.push(from);
                navigate('/', { replace: true });
              },
              error => {
                setWeekPassword({value:true, description: error});
                console.log('errore', hasError.value, hasError.description);
              }
          );
    }

 

  return (
    <Page
      className={classes.root}
      title="Change or create password"
    >
      {hasError.value && (
            <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
                  
                    <Box mb={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Failed validation
                      </Typography>                       
                    </Box>    
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Have an account?
                      {' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                        Sign in
                      </Link>
                    </Typography>

            </Container>
          </Box>
          )}






      {isOk.value && (
            <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Account password
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        
                      </Typography>
                    </Box> 
                    <TextField
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                onChange={handleChange}
                type="password"
                
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Conferma password"
                margin="normal"
                name="confirm"
                onChange={handleChange}
                type="password"
                
                variant="outlined"
              /> 
                    {weekPassword.value && (
                      <div className="error">{weekPassword.description}</div>
                    )} <Box
                      alignItems="center"
                      display="flex"
                      ml={-1}
                    >  
                    </Box> 
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Confirm
                      </Button>
                    </Box>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Have an account?
                      {' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </form>
            </Container>
          </Box>
          )}

      
    </Page>
  );
};

 
