import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { resellerService } from '../../../services/reseller.service';
import { authenticationService } from 'src/services/authentication.service';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const PracticeListView = () => {
  const classes = useStyles();
  const [practicesData, setPracticesData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
   
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => { 
    if (!currentUser) return;
    if (currentUser.role!='Reseller') return;

    resellerService.GetResellerPractices(currentUser.id).then((data) => {
      const practices = data.map(
        practice => {
          return {
            id: practice?.idPractice,
            idPractice: practice?.idPractice,
            practiceNrBo: practice?.practiceNrBo,  
            practiceStateBkb: practice?.practiceStateBkb,            
            dateIns: practice?.dateIns,
            typePromo: practice?.idPromoNavigation?.typePromo,
            name: practice?.idClientNavigation?.firstName + ' '+ practice?.idClientNavigation?.lastName,
            denomination: practice?.idClientNavigation?.denomination,
            email: practice?.idClientNavigation?.email,
            promo: practice?.idPromoNavigation?.promoInternalName,
            business: practice?.idPromoNavigation?.business,
            tyrePresent: (practice?.practiceTyres?.length>0 
              || practice?.idPromoNavigation?.typePromo==3 || practice?.idPromoNavigation?.typePromo==4 ) ? 'Si' : 'No',
            attachPresent: practice?.practiceAttachments?.length>0 ? 'Si' : 'No',
            awardRefund: practice?.awardRefund,
            showButtonModifica: (
              (moment().diff(practice?.idPromoNavigation?.endPracticeRbo, 'minutes') < 0)
              &&
              (practice?.idPromoNavigation?.typePromo === 2 || practice?.idPromoNavigation?.typePromo === 1)
              &&
              (practice?.practiceState=== '0' || practice?.practiceState === '1' || practice?.practiceState === '3' )
              )    
          }
        }
      );   

      // data.map((item, index) => data[index].id=item.idPractice);
      // data.map((item, index) => data[index].name=item.idClientNavigation.firstName + ' '+ item.idClientNavigation.lastName);
      // data.map((item, index) => data[index].denomination=item?.idClientNavigation.denomination);
      // data.map((item, index) => data[index].email=item.idClientNavigation.email);
      // data.map((item, index) => data[index].promo=item.idPromoNavigation.promoInternalName);
      // data.map((item, index) => data[index].business=item.idPromoNavigation.business);      
      // data.map((item, index) => data[index].tyrePresent=(item?.practiceTyres?.length>0 
      //   || item?.idPromoNavigation?.typePromo==3 || item?.idPromoNavigation?.typePromo==4 ) ? 'Si' : 'No');     
      // data.map((item, index) => data[index].attachPresent=item?.practiceAttachments?.length>0 ? 'Si' : 'No');
      
      setPracticesData(practices);
    });    
  }, [currentUser])
 
  return (
    <Page
      className={classes.root}
      title="Pratiche"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results practices={practicesData} />
        </Box>
      </Container>
    </Page>
  );
};

export default PracticeListView;


   
