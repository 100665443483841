import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link
} from '@material-ui/core';


import moment from 'moment';
import { Formik, Form } from 'formik';

import { useParams, useNavigate } from 'react-router-dom';

import createVoucherValidationSchema from './FormModel/createVoucherValidationSchema';
import createVoucherFormModel from './FormModel/createVoucherFormModel';
import createVoucherFormInitialValues from './FormModel/createVoucherFormInitialValues';

import VoucherCreate from './Forms/NrVoucherCreate';
import PromoSelection from './Forms/PromoSelection';
import AwardSelection from './Forms/AwardSelection';
 
import { voucherService } from '../../../services/voucher.service';
import { alertService } from '../../../services/alert.service';
import { authenticationService } from '../../../services/authentication.service';
import { resellerService } from 'src/services/reseller.service';
import { awardService } from 'src/services/award.service';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errors: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
}));

const steps = ['Seleziona promo', 'Seleziona numero cartoline', 'Seleziona premio']; 
const { formId, formField } = createVoucherFormModel;




export default function AddVoucher() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const currentValidationSchema = createVoucherValidationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1; 
    const isPromoStep = activeStep === 0;
    const isNrVoucherStep = activeStep === 1;
    const isAwardSelectionStep = activeStep === 2;

    
  let [hasError, setHasError] = React.useState({
    value: false,
    description: ''
  });



  const [awardData, setAwardData] = React.useState([]);
  const [idPromoAwardSelected, setIdPromoAwardSelected] = React.useState(''); 

  const [promoData, setPromoData] = React.useState([]);  
  const [idPromoSelected, setIdPromoSelected] = React.useState('');
  const [promoTextSelected, setPromoTextSelected] = React.useState('');
  const [qtaVoucher, setQtaVoucher] = React.useState('');



  const [errorQtaVoucher, setErrorQtaVoucher] = React.useState(false);
  const [descErrorQtaVoucher, setDescErrorQtaVoucher] = React.useState('');

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => { 
    if (!currentUser) return;
    if (currentUser.role!='Reseller') return;
    resellerService.GetResellerCampaignCart(currentUser.id, 'PurchaseFirst_New').then((data) => {
      setPromoData(data);
    });
  }, [currentUser])


 

  function onClickPromo(event, value) {
    setIdPromoSelected(event.target.value);
    var textContent = event.target.labels[0].textContent.toString();
    setPromoTextSelected(textContent);
  }

  function onClickQtaVoucher(event) {
    if (event.target.value.match('^[1-9][0-9]*$') && event.target.value <= 30) {
      setErrorQtaVoucher(false);
      setDescErrorQtaVoucher('');
      setQtaVoucher(event.target.value);
    } else {
      setErrorQtaVoucher(true);
      setDescErrorQtaVoucher('Formato errato o numero eccedente');
      setQtaVoucher(event.target.value);
    }
  }

  function onToggleAward(event, value) {
    setIdPromoAwardSelected(value);
  }



  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <PromoSelection formField={formField} promoData={promoData} promoSelected={idPromoSelected} onChange={onClickPromo} />;
      case 1:
        return <VoucherCreate formField={formField} 
          QtaVoucherSelected={qtaVoucher}
          errorQtaVoucher={errorQtaVoucher}
          descErrorQtaVoucher={descErrorQtaVoucher}
          onChange={onClickQtaVoucher} />;
      case 2:
        return <AwardSelection formField={formField} awardList={awardData} onToggle={onToggleAward} awardSelected={idPromoAwardSelected}/>;
      default:
        return <div>Not Found</div>;
    }
  }

    async function _submitForm(values, actions) {
      // await _sleep(1000);
      if (!currentUser) return;
      if (currentUser.role!='Reseller') return;
      voucherService.CreateVoucherFile(values, currentUser.id)
      .then(
          r => {
            setHasError({value:false, description: ''});
            console.log(r);
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);  
          },
          error => {
            setHasError({value:true, description: error});
            console.log('errore', hasError.value, hasError.description);
            setActiveStep(activeStep);
            actions.setTouched({});
            actions.setSubmitting(false);  
          }
      ); 
    }
  


    function _handleSubmit(values, actions) {
      let currentUser = authenticationService.currentUserValue;  
      if (!currentUser) return;
      if (currentUser.role!='Reseller') return;
      
      if (isLastStep) {
        _submitForm(values, actions);
      } 
      else if (isPromoStep) 
      {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
      else if (isNrVoucherStep) 
      {
        if (errorQtaVoucher) {
          setActiveStep(activeStep);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
        else {
          awardService.GetPromoAward(idPromoSelected)
          .then(
            (data) => {
              setHasError({ value: false, description: '' });
              setAwardData(data);
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
            },
            error => {
              setHasError({ value: true, description: error });
              console.log('errore', hasError.value, hasError.description);
              setActiveStep(activeStep);
              actions.setTouched({});
              actions.setSubmitting(false);
            }
          );
          setActiveStep(activeStep + 1);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      }

      else if (isAwardSelectionStep) {
        
      } 
      
      else if (isAwardSelectionStep)
      {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      } 

      else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }



    // function _handleSubmit(values, actions) {
    //   // alert(JSON.stringify(values, null, 2));
    //   if (errorQtaVoucher)
    //   {
    //     setActiveStep(activeStep);
    //     actions.setTouched({});
    //     actions.setSubmitting(false);
    //   }
    //   else if (isCodeCheckStep) {
    //     voucherService.GetVoucherByCode(values.VoucherCode,user.id)
    //       .then(
    //           r => {
    //             setHasError({value:false, description: ''});
    //             setVoucherData(r); 
    //             setVoucherCode(values.VoucherCode);
    //             setActiveStep(activeStep + 1);
    //             actions.setTouched({});
    //             actions.setSubmitting(false);  
    //           },
    //           error => {
    //             setHasError({value:true, description: error});
    //             console.log('errore', hasError.value, hasError.description);
    //             setActiveStep(activeStep);
    //             actions.setTouched({});
    //             actions.setSubmitting(false);  
    //           }
    //       );
    //   }
    //   else {
    //     setActiveStep(activeStep + 1);
    //     actions.setTouched({});
    //     actions.setSubmitting(false);
    //   }
    // }


  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _endFlow() {
    navigate('..', { replace: false }); 
  }

    
return (
  <React.Fragment>
    <CssBaseline />
     <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
                  <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                          CARTOLINE CORRETTAMENTE CREATE
                        </Typography> 
                        <div className={classes.wrapper}>
                          <Button
                            onClick={_endFlow}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}>CLOSE
                          </Button>
                        </div>
                  </React.Fragment>
          ) : (
            <Formik
            initialValues={{ 
              ['IdPromo']: idPromoSelected,
              ['PromoTextSelected']: promoTextSelected,
             ['QtaVoucher']: qtaVoucher,
             ['IdPromoAward']: idPromoAwardSelected,  
           }}  
           enableReinitialize={true}
           validationSchema={currentValidationSchema}
           onSubmit={_handleSubmit}
         > 
            {({ isSubmitting }) => (
              <Form id={formId}>
                
                {_renderStepContent(activeStep)} 
                
                <div className={classes.buttons}>

                {hasError.value && (
                    <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                  )}

                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      PRECEDENTE
                    </Button>
                  )}
                  
                  <div className={classes.wrapper}>

                 
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? 'CONFERMA' : 'PROSSIMO'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          )}
        </React.Fragment>
      </Paper>
      {/* <Copyright /> */}
    </main>
  </React.Fragment>
);
}
