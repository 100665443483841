import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import { practiceService } from '../../../services/practice.service';
import { authenticationService } from 'src/services/authentication.service';
import { promoService } from '../../../services/promo.service';
import { useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const practiceState = [
  {
    id: 0,
    description: 'Nuova',
    selected: false
  },
  {
    id: 1,
    description: 'Da verificare',
    selected: false
  },
  {
    id: 2,
    description: 'Valida',
    selected: false
  },
  {
    id: 3,
    description: 'Non valida',
    selected: false
  },
  {
    id: 4,
    description: 'Rimborsata',
    selected: false
  },
  {
    id: 5,
    description: 'Emessa NC',
    selected: false
  }
];

const PracticeBoListView = () => {
  const classes = useStyles();
  const {selectState}=useParams();


  let first=true;


  const [practicesData, setPracticesData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [selectedPracticeStateIds, setSelectedPracticeStateIds] = useState([null]);
  const [isFetching, setIsFetching] = useState(false);
  const [promoData, setPromoData] = useState([]);
  const {promoSelect} = useParams();
  const [promoSelected, setPromoSelected] = useState(promoSelect);
  const [isFetchingPromo, setIsFetchingPromo] = useState(true);




//simone
  const handlePromo = (event) => {
    setPromoSelected(event.target.value);
    //window.location.href="/backoffice/practicesBo/"+event.target.value+"/"+selectedPracticeStateIds;
    window.history.replaceState(null, "New Page Title", "/backoffice/practicesBo/"+event.target.value+"/"+selectedPracticeStateIds);
  };


  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPracticeStateIds.indexOf(id);
    let newSelectedPracticeStateIds = [];

    if (selectedIndex === -1) {
      newSelectedPracticeStateIds = newSelectedPracticeStateIds.concat(selectedPracticeStateIds, id);
    } else if (selectedIndex === 0) {
      newSelectedPracticeStateIds = newSelectedPracticeStateIds.concat(selectedPracticeStateIds.slice(1));
    } else if (selectedIndex === selectedPracticeStateIds.length - 1) {
      newSelectedPracticeStateIds = newSelectedPracticeStateIds.concat(selectedPracticeStateIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPracticeStateIds = newSelectedPracticeStateIds.concat(
        selectedPracticeStateIds.slice(0, selectedIndex),
        selectedPracticeStateIds.slice(selectedIndex + 1)
      );
    } 
    setSelectedPracticeStateIds(newSelectedPracticeStateIds);
    //window.location.href="/backoffice/practicesBo/"+promoSelected+"/"+newSelectedPracticeStateIds;
    window.history.replaceState(null, "New Page Title", "/backoffice/practicesBo/"+promoSelected+"/"+newSelectedPracticeStateIds);
  };

  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user);


  }, [])



  useEffect(()=>{
    if (!currentUser) return;
    if (!(currentUser.role === 'Admin' || currentUser.role === "Backoffice")) return;
    setIsFetchingPromo(true);



    //simone
    promoService.GetAllPromo().then((data) => {
      console.log(data)
      const promo = data.map(
        promo => {
          return {
            key:promo.idPromo,
            id:promo.idPromo,
            value: promo.idPromo,
            label: promo.promoInternalName,
            n_nuove:promo.n_nuove,
            n_verificate:promo.n_verificate
          }

        }

      );

      const def={"value": 0, "label": "TUTTE"};
      promo.push(def);
      setPromoData(promo);
      if(promoSelected==null) {
        setPromoSelected(promo[0].value);
      }
      if(selectedPracticeStateIds[0]==null){
        if(selectState==null) {
          setSelectedPracticeStateIds([0]);

        }else{

          let newSelectedPracticeStateIds = [];
          let newSelectState=selectState.split(",");

          for(let i=0;i<newSelectState.length;i++){
            newSelectedPracticeStateIds=newSelectedPracticeStateIds.concat(parseInt(newSelectState[i]));
          }
          setSelectedPracticeStateIds(newSelectedPracticeStateIds);
        }
      }

      setIsFetchingPromo(false);
    });
  }, [currentUser]);


  useEffect(() => {
    if (!currentUser) return;
    if (!(currentUser.role === 'Admin' || currentUser.role === "Backoffice")) return;
    setIsFetching(true);



    if(promoSelected!=null && selectedPracticeStateIds[0]!=null){
      practiceService.GetAllPracticeBo(currentUser.id, selectedPracticeStateIds,promoSelected).then((data) => {

        const practices = data.map(
          practice => {

            return {
              key:practice.idPractice,
              id: practice.idPractice,
              idPractice: practice.idPractice,
              practiceNrBo: practice.practiceNrBo,
              practiceState: practiceState[practice?.practiceState]?.description,
              dateIns: practice.dateIns,
              name: practice.idClientNavigation.firstName + ' '+ practice.idClientNavigation.lastName,
              email: practice.idClientNavigation.email,
              promo: practice.idPromoNavigation.promoInternalName,
              business: practice.idPromoNavigation.business,
              tyrePresent: (practice?.practiceTyres?.length>0
                || practice?.idPromoNavigation?.typePromo==3 || practice?.idPromoNavigation?.typePromo==4 ) ? 'Si' : 'No',
              attachPresent: practice?.practiceAttachments?.length>0 ? 'Si' : 'No',
              awardRefund: practice.awardRefund
            }
          }
        );

        setPracticesData(practices)
        // data.map((item, index) => data[index].id=item.idPractice);
        // data.map((item, index) => data[index].practiceState=practiceState[item?.practiceState]?.description);
        // data.map((item, index) => data[index].name=item.idClientNavigation.firstName + ' '+ item.idClientNavigation.lastName);
        // data.map((item, index) => data[index].email=item.idClientNavigation.email);
        // data.map((item, index) => data[index].promo=item.idPromoNavigation.promoInternalName);
        // data.map((item, index) => data[index].business=item.idPromoNavigation.business);
        // data.map((item, index) => data[index].tyrePresent=item?.practiceTyres?.length>0 ? 'Si' : 'No');
        // data.map((item, index) => data[index].attachPresent=item?.practiceAttachments?.length>0 ? 'Si' : 'No');

      });


    }

    setIsFetching(false);
  }, [currentUser, selectedPracticeStateIds,promoSelected]);


  
  return (
    <Page
      className={classes.root}
      title="Pratiche"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          
                   
                   
          <Results 
          practices={practicesData} 
          practiceState={practiceState}
          promo={promoData}
          promoSelected={promoSelected}
          handlePromo={handlePromo}
          handleSelectOne={handleSelectOne}
          selectedPracticeStateIds={selectedPracticeStateIds}
          isFetching={isFetching}
          isFetchingPromo={isFetchingPromo}
          />
 
      </Box>
      </Container>
    </Page>
  );
};

export default PracticeBoListView;


   
