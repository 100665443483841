import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom'; 
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  colors,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';

import getInitials from 'src/utils/getInitials';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid'; 


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  div: {
    // backgroundColor: theme.palette.background.dark, 
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    width: '100%',
    height: 880
  },
  datagrid: { 
    fontSize: 12, 
  },
  editButton: {
    backgroundColor: colors.red[600] 
  }
}));

const Results = ({ className, conventions, ...rest }) => {
  const classes = useStyles();    
  const navigate = useNavigate();

  const headCells = [
    {field: 'Edit', headerName: ' ', width: 125, renderCell: (params) => (   
      <strong>
       <Button
         variant="contained"
         color="primary"
         size="small"
         className={classes.editButton}
         // style={{ marginLeft: 16 }} 
          onClick={() => { onEdit(params.row.idPromo) }}
       >
         MODIFICA
       </Button>
     </strong>
   ),
 },
  // {field: 'idPromo', headerName: 'Id', width: 75, description:'The id used for campaign.'},
  {field: 'promoInternalName', headerName: 'Nome convenzione',  flex: 1, description:'Il nome della convenzione'},
  // {field: 'promoExternalName', headerName: 'External name', width: 275, description:'The external name of the campaign. (URL Campaign)'},
  // {field: 'businessPromoAttribute', headerName: 'Business', width: 125, description:'The id used for campaign.'},
  // {field: 'countryPromoAttribute', headerName: 'Country',  width: 100, description:'The internal name of the campaign.'},
  // {field: 'startViewPromo', headerName: 'Start view', width: 225, description:'The start date of view of the campaign.'},
  {field: 'startVoucherRequestPromo',  headerName: 'Inizio richiesta voucher', type: 'date', 
      renderCell: params => <>{moment(params.value).format('DD/MM/YYYY')}</>, 
      width: 200, description:'La data di inizio richiesta codice voucher.'},
  {field: 'endVoucherRequestPromo', headerName: 'Termine richiesta voucher', type: 'date', 
  renderCell: params => <>{moment(params.value).format('DD/MM/YYYY')}</>, 
 width: 200, description:'La data di fine richiesta codice voucher.'},
  {field: 'endVoucherRedemeedPromo', headerName: 'Termine riscossione voucher', type: 'date',  
  renderCell: params => <>{moment(params.value).format('DD/MM/YYYY')}</>, 
 width: 225, description:'La data di fine riscossione codice voucher.'},
  {field: 'endVoucherRedemeedFirstopPromo', headerName: 'Termine riscossione voucher (per First Stop)', type: 'date',
  renderCell: params => <>{moment(params.value).format('DD/MM/YYYY')}</>, 
  width: 300, description:'La data di fine riscossione codice voucher per rivenditori First Stop.'}
  ];  
    
  let onEdit = (idPractice) => { 
    navigate('edit/'+ idPractice, { replace: false }); 
  }
   
  return (
  <Card
        className={clsx(classes.root, className)}
        {...rest}
      > 
      <PerfectScrollbar>
          <Box minWidth={1050}>
          {/* <div style={{ height:100, width: '100%'}}> */}

            <div id='TableInConvention'>
          <DataGrid  
              className={classes.datagrid}
              columns={headCells} 
              rows={conventions}
              pageSize={20}
              rowsPerPageOptions={[5, 10, 20, 50]}
              showToolbar
              components={{
                Toolbar: GridToolbar,
              }} 
              />
          </div> 
          </Box>
          
        </PerfectScrollbar>
        
      </Card>
  );
};


Results.propTypes = {
  className: PropTypes.string,
  conventions: PropTypes.array.isRequired
};

export default Results;
