export default {
  formId: 'campaignForm',
  formField: {
    PromoInternalName: {
      name: 'PromoInternalName',
      label: 'Nome convenzione (interno)',
      requiredErrorMsg: 'Nome interno convenzione obbligatorio'
    },    
    PromoExternalName: {
      name: 'PromoExternalName',
      label: 'Nome convenzione (esterno)',
      requiredErrorMsg: 'Nome esterno convenzione obbligatorio'
    },
    CodiceAziendaleConvenzionePromoAttribute: {
      name: 'CodiceAziendaleConvenzionePromoAttribute',
      label: 'Codice alfanumerico convenzione',
      requiredErrorMsg: 'Codice convenzione obbligatorio'
    },
    NomeAziendaConvenzionePromoAttribute: {
      name: 'NomeAziendaConvenzionePromoAttribute',
      label: 'Nome azienda',
      requiredErrorMsg: 'Nome azienda obbligatorio'
    },
    DataInizioRichiestaCodicePromoAttribute: {
      name: 'DataInizioRichiestaCodicePromoAttribute',
      label: 'Inizio richiesta voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    DataFineRichiestaCodicePromoAttribute: {
      name: 'DataFineRichiestaCodicePromoAttribute',
      label: 'Termine richiesta voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    DataFineRiscossioneCodicePromoAttribute: {
      name: 'DataFineRiscossioneCodicePromoAttribute',
      label: 'Termine riscossione voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    DataFineRiscossioneCodice_FirstopPromoAttribute: {
      name: 'DataFineRiscossioneCodice_FirstopPromoAttribute',
      label: 'Termine riscossione voucher (rivenditori First Stop)',
      requiredErrorMsg: 'Data obbligatoria'
    },
    DominiAziendaliEmailConvenzionePromoAttribute: {
      name: 'DominiAziendaliEmailConvenzionePromo',
      label: 'Elenco domini email accettati (separati da ;)',
      requiredErrorMsg: 'Valore obbligatorio'
    }
  }
};

