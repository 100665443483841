export * from './auth-header';
export * from './handle-response'; 
export * from './handle-download';

export const ENV_PROD = !(window.location.href.toLowerCase().indexOf("-test")>0
  || window.location.href.toLowerCase().indexOf("localhost")>0);
export const SERVER_URL = !ENV_PROD ?  "https://api-promoportal-prod-test.azurewebsites.net" : "https://api-promoportal-prod.azurewebsites.net";
export const API_URL = `${SERVER_URL}/api`;
//export const API_URL = `http://localhost:35276/api`;

export const FRONT_URL = !ENV_PROD ?  "https://campagne-promoportal-prod-test.azurewebsites.net/" : "https://promo.bridgestone.it/";

