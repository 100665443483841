import React,  { useState }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'; 
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(6)
  },
}));


export default function VoucherCheck(props) {
  const {
    formField: { VoucherCode }
  } = props;

  const classes = useStyles(); 
  // let {conventionCode} = props;
  // const [conventionCode, conventionCode] = useState({
  //      value: '' 
  //    });
  
  const navigate = useNavigate();
    

  const HandleSubmit = (e) => {
    e.preventDefault();
    // navigate('/registration', {codeConvention}, { replace: true });
   // codeConventionService.check(codeConvention.value);
    // authenticationService.login(account.username, account.password)
    //     .then(
    //         user => {
    //           // console.log(this.props.location.state);
    //           //   const { from } = this.props.location.state || { from: { pathname: "/" } };
    //           //   this.props.history.push(from);
    //           navigate('/app/dashboard', { replace: true });
    //         },
    //         error => {
    //           hasError = error;
    //           console.log(hasError);
    //         }
    //     );
  }

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value; 
  }

  return (
    <Grid item xs={12} md={12} spacing={5}>
    <Typography variant="h5" className={classes.typografy}>
      CODICE VOUCHER
    </Typography>
    <Typography variant="h8" gutterBottom>
      INSERISCI QUI IL CODICE VOUCHER
    </Typography> 
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12} sm={6}> 
          <InputField
            name={VoucherCode.name}
            label={VoucherCode.label}
            fullWidth
          /> 
        </Grid> 
      </Grid> 
    </Grid>
  );
}

