import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  colors,
  View
} from '@material-ui/core';
import Page from 'src/components/Page';
import TotalPractice from './TotalPractice'; 
import PracticeByDay from './PracticeByDay';
import PracticesForBusiness from './PracticesForBusiness';
import TotalPracticeBusiness from './TotalPracticeBusiness';



import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ReceiptIcon from '@material-ui/icons/Receipt';


import { FaCarAlt } from 'react-icons/fa';
import { FaMotorcycle } from 'react-icons/fa';
import { ImTruck } from 'react-icons/im';
import { FaTractor } from 'react-icons/fa';

import { authenticationService } from 'src/services/authentication.service';
import { dashboardService } from 'src/services/dashboard.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    position: 'absolute', 
    left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },

}));

const Dashboard = () => {
  const classes = useStyles(); 
  const [currentUser, setCurrentUser] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  useEffect(() => { 
    setIsFetching(true);
    if (!currentUser) return;
    if (!(currentUser.role === 'Admin' || currentUser.role === "Backoffice"|| currentUser.role === "Bridgestone")) return;
    dashboardService.GetDashboardSummary(currentUser.id).then((data) => {
      setDashboardData({
        nrPracticesTotal: data?.nrPracticesTotal,
        nrPracticesTotalToday: data?.nrPracticesTotalToday,
        nrPracticesTotalYesterday: data?.nrPracticesTotalYesterday,
        diffVsYesterday: data?.diffVsYesterday,
        nrPracticesTotalAuto: data?.nrPracticesTotalAuto,
        nrPracticesTotalAutoNew: data?.nrPracticesTotalAutoNew,
        nrPracticesTotalAutoVerify: data?.nrPracticesTotalAutoVerify,
        nrPracticesTotalTruck: data?.nrPracticesTotalTruck,
        nrPracticesTotalTruckNew: data?.nrPracticesTotalTruckNew,
        nrPracticesTotalTruckVerify: data?.nrPracticesTotalTruckVerify,
        nrPracticesTotalAgri: data?.nrPracticesTotalAgri,
        nrPracticesTotalAgriNew: data?.nrPracticesTotalAgriNew,
        nrPracticesTotalAgriVerify: data?.nrPracticesTotalAgriVerify,
        nrPracticesTotalMoto: data?.nrPracticesTotalMoto,
        nrPracticesTotalMotoNew: data?.nrPracticesTotalMotoNew,
        nrPracticesTotalMotoVerify: data?.nrPracticesTotalMotoVerify,
        nrPracticesMinus4DayAuto: data?.nrPracticesMinus4DayAuto,
        nrPracticesMinus3DayAuto: data?.nrPracticesMinus3DayAuto,
        nrPracticesMinus2DayAuto: data?.nrPracticesMinus2DayAuto,
        nrPracticesMinus1DayAuto: data?.nrPracticesMinus1DayAuto,
        nrPracticesTodayAuto: data?.nrPracticesTodayAuto,
        nrPracticesMinus4DayTruck: data?.nrPracticesMinus4DayTruck,
        nrPracticesMinus3DayTruck: data?.nrPracticesMinus3DayTruck,
        nrPracticesMinus2DayTruck: data?.nrPracticesMinus2DayTruck,
        nrPracticesMinus1DayTruck: data?.nrPracticesMinus1DayTruck,
        nrPracticesTodayTruck: data?.nrPracticesTodayTruck,
        nrPracticesMinus4DayAgri: data?.nrPracticesMinus4DayAgri,
        nrPracticesMinus3DayAgri: data?.nrPracticesMinus3DayAgri,
        nrPracticesMinus2DayAgri: data?.nrPracticesMinus2DayAgri,
        nrPracticesMinus1DayAgri: data?.nrPracticesMinus1DayAgri,
        nrPracticesTodayAgri: data?.nrPracticesTodayAgri,
        nrPracticesMinus4DayMoto: data?.nrPracticesMinus4DayMoto,
        nrPracticesMinus3DayMoto: data?.nrPracticesMinus3DayMoto,
        nrPracticesMinus2DayMoto: data?.nrPracticesMinus2DayMoto,
        nrPracticesMinus1DayMoto: data?.nrPracticesMinus1DayMoto,
        nrPracticesTodayMoto: data?.nrPracticesTodayMoto,
        ratioPracticesAuto: data?.ratioPracticesAuto,
        ratioPracticesAgri: data?.ratioPracticesAgri,
        ratioPracticesTruck: data?.ratioPracticesTruck,
        ratioPracticesMoto: data?.ratioPracticesMoto,

      });
      
    setIsFetching(false);
    });
  }, [currentUser])

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      {isFetching &&
                        <Container className={classes.circularProgress}>
                            <CircularProgress
                                size={48}
                            />
                        </Container>
                    }

      {!isFetching &&
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPractice 
            title="Numero pratiche TOTALI"
            totalPractices={dashboardData?.nrPracticesTotal} 
            diffVsYesterday ={dashboardData?.diffVsYesterday} 
            />
          </Grid>

          </Grid>

          <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPracticeBusiness  
            title="Numero pratiche AUTO"
            totalPractices={dashboardData?.nrPracticesTotalAuto}
            totalPracticesNew={dashboardData?.nrPracticesTotalAutoNew}
            totalPracticesVerify={dashboardData?.nrPracticesTotalAutoVerify} 
            icon={<FaCarAlt />}
            business={"auto"}/>
          </Grid>
           
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPracticeBusiness 
            title="Numero pratiche TRUCK"
            totalPractices={dashboardData?.nrPracticesTotalTruck}
            totalPracticesNew={dashboardData?.nrPracticesTotalTruckNew}
            totalPracticesVerify={dashboardData?.nrPracticesTotalTruckVerify} 
            icon={<ImTruck/>}
            business={"truck"}/>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPracticeBusiness  
            title="Numero pratiche AGRI"
            totalPractices={dashboardData?.nrPracticesTotalAgri}
            totalPracticesNew={dashboardData?.nrPracticesTotalAgriNew}
            totalPracticesVerify={dashboardData?.nrPracticesTotalAgriVerify} 
            icon={<FaTractor/>}
            business={"agri"}/>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPracticeBusiness  
            title="Numero pratiche MOTO"
            totalPractices={dashboardData?.nrPracticesTotalMoto}
            totalPracticesNew={dashboardData?.nrPracticesTotalMotoNew}
            totalPracticesVerify={dashboardData?.nrPracticesTotalMotoVerify} 
            icon={<FaMotorcycle />}
            business={"moto"}/>
          </Grid>

          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <PracticeByDay  
              nrPracticesMinus4DayAuto={dashboardData?.nrPracticesMinus4DayAuto}
              nrPracticesMinus3DayAuto={dashboardData?.nrPracticesMinus3DayAuto}
              nrPracticesMinus2DayAuto={dashboardData?.nrPracticesMinus2DayAuto}
              nrPracticesMinus1DayAuto={dashboardData?.nrPracticesMinus1DayAuto}
              nrPracticesMinusTodayAuto={dashboardData?.nrPracticesTodayAuto}
              nrPracticesMinus4DayTruck={dashboardData?.nrPracticesMinus4DayTruck}
              nrPracticesMinus3DayTruck={dashboardData?.nrPracticesMinus3DayTruck}
              nrPracticesMinus2DayTruck={dashboardData?.nrPracticesMinus2DayTruck}
              nrPracticesMinus1DayTruck={dashboardData?.nrPracticesMinus1DayTruck}
              nrPracticesTodayTruck={dashboardData?.nrPracticesTodayTruck}
              nrPracticesMinus4DayAgri={dashboardData?.nrPracticesMinus4DayAgri}
              nrPracticesMinus3DayAgri={dashboardData?.nrPracticesMinus3DayAgri}
              nrPracticesMinus2DayAgri={dashboardData?.nrPracticesMinus2DayAgri}
              nrPracticesMinus1DayAgri={dashboardData?.nrPracticesMinus1DayAgri}
              nrPracticesTodayAgri={dashboardData?.nrPracticesTodayAgri}
              nrPracticesMinus4DayMoto={dashboardData?.nrPracticesMinus4DayMoto}
              nrPracticesMinus3DayMoto={dashboardData?.nrPracticesMinus3DayMoto}
              nrPracticesMinus2DayMoto={dashboardData?.nrPracticesMinus2DayMoto}
              nrPracticesMinus1DayMoto={dashboardData?.nrPracticesMinus1DayMoto}
              nrPracticesTodayMoto={dashboardData?.nrPracticesTodayMoto}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <PracticesForBusiness  
            ratioPracticesAuto= {dashboardData?.ratioPracticesAuto}
            ratioPracticesAgri= {dashboardData?.ratioPracticesAgri}
            ratioPracticesTruck= {dashboardData?.ratioPracticesTruck}
            ratioPracticesMoto= {dashboardData?.ratioPracticesMoto}
            />
          </Grid>
          
          
        </Grid>
      </Container>}
    </Page>
  );
};

export default Dashboard;
