import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';
import { DatePickerField } from '../../../../FormFields';
import {FRONT_URL} from '../../../../helpers';
import {MenuItem, Select, TextareaAutosize} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
}));


export default function CommonConventionData(props) {
  const {
    formField: {
      PromoInternalName,
      CompanyConventionCodePromoAttribute,
      CompanyNameConventionPromoAttribute,
      CompanyEmailDomainConventionPromoAttribute,
      StartVoucherRequestPromo,
      EndVoucherRequestPromo,
      EndVoucherRedemeedPromo,
      EndVoucherRedemeedFirstopPromo,
      StartViewPromo,
      EndViewPromo,
      EndPracticeRbo
    },
    conventionId,
    //changeCheckbox,
    setCheckEmail,
    checkEmail,
    checkMedia,



    mediaDataLogo,
    mediaDataImg,
    mediaDataEmail,


    mediaLogo,
    mediaImg,
    mediaEmail,
    setMediaLogo,
    setMediaEmail,
    setMediaImg,
    numMaxRegistrazioni,
    setNumMaxRegistrazioni,
    checkMaxRegistrazioni,
    checkInfo,
    infoConvenzione,
    setInfoConvenzione

  } = props;

  const classes = useStyles();


  const [errore, setErrore] = useState(false);
  const HandleSubmit = (e) => {
    e.preventDefault();
    // navigate('/registration', {codeConvention}, { replace: true });
    // codeConventionService.check(codeConvention.value);
    // authenticationService.login(account.username, account.password)
    //     .then(
    //         user => {
    //           // console.log(this.props.location.state);
    //           //   const { from } = this.props.location.state || { from: { pathname: "/" } };
    //           //   this.props.history.push(from);
    //           navigate('/app/dashboard', { replace: true });
    //         },
    //         error => {
    //           hasError = error;
    //           console.log(hasError);
    //         }
    //     );
  }

  const HandleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
  }



  const getMediaLogo=()=>{
    return mediaLogo
  }

  const getMediaImg=()=>{
    return mediaImg
  }

  const getMediaEmail=()=>{
    return mediaEmail
  }

  const getNumMaxRegistrazioni=()=>{
    return numMaxRegistrazioni
  }

  const getInfoConvenzione=()=>{
    return infoConvenzione;
  }

  const SetInfoConvenzione=(event)=>{
    setInfoConvenzione(event.target.value)
  }

  const SetMaxResistrazioni=(event)=>{
    let x=parseInt(event.target.value)
    if(x<1){
      setErrore(true)
    }else {
      setErrore(false)
      setNumMaxRegistrazioni(x)
    }
  }


  const changeCheckbox=()=>{
    setCheckEmail(checkEmail?false:true);
  }

  const setEmail = (event) => {
    setMediaEmail(event.target.value);
  };

  const setImg = (event) => {
    setMediaImg(event.target.value);
  };


  const setLogo = (event) => {
    setMediaLogo(event.target.value);
  };



  const changeCod1=()=>{
    let x =document.getElementById("promoCod")

    let str;
    if (x != null && x.value!="") {
      str = FRONT_URL+"convenzioni/"+x.value;
    }else {
      str = "";
    }
    return str
  }

  return (
    <Grid item xs={12} md={12} spacing={5}>
      {/* <Typography variant="h6" className={classes.typografy}>
      DATI GENERALI CONVENZIONE
    </Typography> */}
      {/* <Typography variant="h8" gutterBottom>
      INSERISCI I TUOI DATI PER RICEVERE IL VOUCHER
    </Typography>  */}
      <Grid container spacing={3} className={classes.grid}>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <InputField name={CompanyNameConventionPromoAttribute.name} label={CompanyNameConventionPromoAttribute.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <InputField name={PromoInternalName.name} label={PromoInternalName.label} fullWidth />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <InputField id="promoCod"  name={CompanyConventionCodePromoAttribute.name} label={CompanyConventionCodePromoAttribute.label}  fullWidth />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <InputField name={CompanyEmailDomainConventionPromoAttribute.name} label={CompanyEmailDomainConventionPromoAttribute.label} fullWidth disabled={checkEmail}/>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
         <div style={{display: "flex",
           alignItems: "end",
           justifyContent: "center",height:"100%"}}>

           All domains : <input type="checkbox"
                             checked={checkEmail}
                             onChange={changeCheckbox}
                                style={{margin:"3px"}}
           //inputProps={{ 'aria-label': 'controlled' }}
         />

         </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={StartVoucherRequestPromo.name}
            label={StartVoucherRequestPromo.label}
            format="dd/MM/yyyy" 
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={EndVoucherRequestPromo.name}
            label={EndVoucherRequestPromo.label}
            format="dd/MM/yyyy" 
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={EndVoucherRedemeedPromo.name}
            label={EndVoucherRedemeedPromo.label}
            format="dd/MM/yyyy" 
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={EndVoucherRedemeedFirstopPromo.name}
            label={EndVoucherRedemeedFirstopPromo.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={StartViewPromo.name}
            label={StartViewPromo.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={EndViewPromo.name}
            label={EndViewPromo.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DatePickerField
            name={EndPracticeRbo.name}
            label={EndPracticeRbo.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>





        {checkMedia  && getMediaImg()>=0 && (

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>



            <p> Media Background</p>
            <Select
              onChange={setImg}
              defaultValue={getMediaImg()}
              style={{"width": "100%"}}

            >
              {mediaDataImg.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}

            </Select>
          </Grid>)}


        {checkMedia  && getMediaEmail()>=0 && (

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>



            <p>Media Template Email</p>
            <Select
              defaultValue={getMediaEmail()}
              onChange={setEmail}
              style={{"width": "100%"}}

            >
              {mediaDataEmail.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}

            </Select>
          </Grid>)}



        {checkMedia  && getMediaLogo()>=0 && (

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>



            <p>Media Logo</p>
            <Select
              onChange={setLogo}
              defaultValue={getMediaLogo()}
              style={{"width": "100%"}}
            >
              {mediaDataLogo.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}

            </Select>
          </Grid>)}


        {checkMaxRegistrazioni &&<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <p>Numero max registrazioni</p>
          <InputField type="number" onChange={SetMaxResistrazioni} name={"test"}  value={getNumMaxRegistrazioni()}  fullWidth />
          {errore &&<p style={{color : "red"}} >Valore minimo:1</p>}

        </Grid>}


        {checkInfo &&<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <p>Inserire info convenzione</p>
          <TextareaAutosize style={{width: "100%"}}  rows={5} onChange={SetInfoConvenzione} value={getInfoConvenzione()}  />


        </Grid>}




        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <p>

            {changeCod1()}
          </p>
        </Grid>


      </Grid>
    </Grid>
  );
}
