export default {
  formId: 'conventionForm',
  formField: {
    PromoInternalName: {
      name: 'PromoInternalName',
      label: 'Nome convenzione (interno)',
      requiredErrorMsg: 'Nome interno convenzione obbligatorio'
    }, 
    PromoExternalName: {
      name: 'PromoExternalName',
      label: 'Nome convenzione (esterno)',
      requiredErrorMsg: 'Nome esterno convenzione obbligatorio'
    }, 
    CompanyNameConventionPromoAttribute: {
      name: 'CompanyNameConventionPromoAttribute',
      label: 'Nome azienda',
      requiredErrorMsg: 'Nome azienda obbligatorio'
    }, 
    CompanyConventionCodePromoAttribute: {
      name: 'CompanyConventionCodePromoAttribute',
      label: 'Codice alfanumerico convenzione',
      requiredErrorMsg: 'Codice convenzione obbligatorio'
    },
    CompanyEmailDomainConventionPromoAttribute: {
      name: 'CompanyEmailDomainConventionPromoAttribute',
      label: 'Elenco domini email accettati (separati da ;)',
      requiredErrorMsg: 'Valore obbligatorio'
    }, 
    StartVoucherRequestPromo: {
      name: 'StartVoucherRequestPromo',
      label: 'Inizio richiesta voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    EndVoucherRequestPromo: {
      name: 'EndVoucherRequestPromo',
      label: 'Termine richiesta voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    EndVoucherRedemeedPromo: {
      name: 'EndVoucherRedemeedPromo',
      label: 'Termine riscossione voucher',
      requiredErrorMsg: 'Data obbligatoria'
    },
    EndVoucherRedemeedFirstopPromo: {
      name: 'EndVoucherRedemeedFirstopPromo',
      label: 'Termine riscossione voucher (rivenditori First Stop)',
      requiredErrorMsg: 'Data obbligatoria'
    },
    StartViewPromo: {
      name: 'StartViewPromo',
      label: 'Inizio visualizzazione promo',
      requiredErrorMsg: 'Data obbligatoria'
    },
    EndViewPromo: {
      name: 'EndViewPromo',
      label: 'Termine visualizzazione promo',
      requiredErrorMsg: 'Data obbligatoria'
    },
    EndPracticeRbo: {
      name: 'EndPracticeRbo',
      label: 'Termine gestione pratiche',
      requiredErrorMsg: 'Data obbligatoria'
    }
  }
};
