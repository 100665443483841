import configData from './config.json';
import {authHeader, handleResponse, handleDownload, API_URL} from '../helpers';
import { downloadService} from 'src/services/download.service';
import { getBase64 } from '../utils/utility';

export const downloadUploadService = {
    DownloadModel,
    UploadModel
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  

const guardedPromise = preventConcurrentExec();


function DownloadModel(Area, Type) {
    return guardedPromise("DownloadImportModel", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/DownloadUpload/download-import-model?Area=${Area}&Type=${Type}`, requestOptions)
        .then(handleDownload)
        .then(blob => {
            if (blob) { 
                downloadService.downloadModel(blob, Area, Type);
                return resolve();
            }
            
        })
        .catch(error => reject(error));
    }));
}


async function UploadModel(idPromo, area, type, file) {    
    var IdPromo = parseInt(idPromo);   
    var Area = area;
    var Type = type; 
    var FileBased64 = await getBase64(file); 

    console.log(FileBased64.base64);

    console.log(JSON.stringify({
        IdPromo,
        Area,
        Type,
        FileBased64: FileBased64.base64,
        FileName: file.name
    }));


    return guardedPromise("UploadModel", () => new Promise((resolve, reject) => { 
        const requestOptions = {
            method: 'POST', 
            headers: {'Content-Type': 'application/json', ...authHeader()},      
            body: JSON.stringify({ IdPromo, 
                Area,
                Type,
                FileBased64: FileBased64.base64,
                FileName: file.name
            })
        };
        console.log(requestOptions.body);
        fetch(`${API_URL}/DownloadUpload/upload-import-model`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for awards");
            })
            .catch(error => reject(error));
        }));
}
