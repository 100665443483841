import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, promoAttribute,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}



  const headCells = [
    {field: 'idPromoAttribute', headerName: 'idPromoAttribute',  width: 250, description:'The internal idPromoAttribute.'},
    {field: 'parameterName',   headerName: 'Parameter name',  width: 250, description:'The internal parameterName.'},
    //{field: 'parameterValue',   headerName: 'Parameter value',  width: 250, description:'The internal parameterValue.'},
    {field: 'parameterValue',type: 'string', headerName: 'Parameter value', width: 350, description:'The internal parameterValue',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyPromoAttribute(params.row.parameterName,  params.row.parameterValue, params)}}
          > {params.row.parameterValue}
          </Button>
        </div>
      ), },
    {field: 'Cancellazione', headerName: 'Cancellazione', width: 150, renderCell: (params) => (
        <strong>
          {params.value}


          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancelPromoAttribute(params.row.idPromoAttribute) }}
          >
            Cancella
          </Button>


        </strong>
      ),
    }
  ];

  let cancelPromoAttribute = async  (idPromoAttribute) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Cancellare questo attributo per la campagna ' +idCampaign ,
      showCancelButton: true,

    }).then( async (ret) => {
      if (ret.isConfirmed){

        await promoService.DeletePromoAttributeByIdPromoAttribute(idPromoAttribute).then(data => {
          if(data==1) {


            const { value: newplafond } =  Swal.fire({
              title: 'Cancellazione effettuata' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })


          }else{
            const { value: newplafond } =  Swal.fire({
              title: 'Errore nella cancellazione' ,
              showCancelButton: false,

            }).then((ret) =>{
              window.location.reload();
            })
          }


        });

      }


    })




  }


  let modifyPromoAttribute =async ( parameterName ,parameterValue , p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo ParameterValue',
      inputValue: parameterValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then(async (ret) =>{
      if (ret.isConfirmed){
        p.row.parameterValue  = ret.value;
       var json = {
          IdPromo: idCampaign,
          parameterName:parameterName,
          parameterValue: ret.value
        }


        await promoService.modifyPromoAttribute(json).then((data) => {
          if(data==1) {
            const { value: newplafond } =  Swal.fire({
              title: 'PromoAttribute modificata' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })


          }
          else if(data==-1){
            const { value: newplafond } =  Swal.fire({
              title: 'PromoAttribute non modificata perchè non trovata' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })
          }
          else{
            const { value: newplafond } =  Swal.fire({
              title: 'Errore' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })
          }
        });
      }
    })
  }

  let addPromoAttribute = async () => {




    Swal.fire({
      showCancelButton:true,
      title:"Add PromoAttribute for Promo "+idCampaign,
      html:`Parameter name:<input id="parameterName" type="text"></br>
      Parameter value: <input id="parameterValue" type="text">`,

      preConfirm: async function() {
        let parameterName= document.getElementById('parameterName').value;
        let parameterValue= document.getElementById('parameterValue').value;
        if(parameterName=="" || parameterValue==""){
          const { value: newplafond } =  Swal.fire({
            title: 'Inserire entrambi i valori' ,
            showCancelButton: false,
          })
        }else{
          let json={}
          json.idPromo=idCampaign;
          json.parameterName=parameterName;
          json.parameterValue=parameterValue;
          await promoService.addPromoAttribute(json).then((data) => {
            if(data==1) {
              const { value: newplafond } =  Swal.fire({
                title: 'PromoAttribute aggiunto' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })


            }
            else if(data==-1){
              const { value: newplafond } =  Swal.fire({
                title: 'ParameterName già esistente per questa promo' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })
            }
            else{
              const { value: newplafond } =  Swal.fire({
                title: 'Errore' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })

            }


            });




        }
      }
    })

  }


return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {addPromoAttribute()}}
          >
            Add PromoAttribute
          </Button>
        </div>
      <div id='TableInCampaign'>



      <DataGrid

          columns={headCells}
          rows={promoAttribute}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  promoAttribute: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
