import * as Yup from 'yup';
import moment from 'moment';
import createVoucherFormModel from './createVoucherFormModel';
const {
  formField: {
    IdPromo,
    PromoTextSelected,
    QtaVoucher, 
    IdPromoAward 
  }
} = createVoucherFormModel;

 
 
export default [
  Yup.object().shape({
    [IdPromo.name]: Yup.string().required(`${IdPromo.requiredErrorMsg}`) 
  }),
  Yup.object().shape({
    [QtaVoucher.name]: Yup.string().required(`${QtaVoucher.requiredErrorMsg}`) 
  }),
  Yup.object().shape({
    [IdPromoAward.name]: Yup.string().required(`${IdPromoAward.requiredErrorMsg}`) 
  })
];
