import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCreate from 'src/FormFields/Table';
import GenericPdfDownloader from "src/components/GenericPdfDownloader";

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch, Container, CircularProgress
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    circularProgress: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    download:{
      marginTop:'30%!important'
    }

  }));



function print(navigate,currentUser,IdPromo){
  let printContents = document.getElementsByClassName("download");

  let contentToPrint= "<div class='noPrint' id='noPrint' style='margin-bottom: 100%'></div>" +
    " <div class=\"page-footer\">\n" +
    "  </div>" +
    "<div class=\"page-header\" style=\"text-align: center\">\n" +
    "<img class=\"img\" style='float: right;' \n" +
    "      alt=\"Backoffice Bridgestone\"\n" +
    "      src=\"/static/logoBridgeston.png \"\n" +
    "      {...props}\n" +
    "      width=\"150\"\n" +
    "    />" +
    "  </div>" +
    "<div class=\"page-header-space\"></div>" +
    "<div class=\"page-footer-space\"></div>";
  for(let i = 0 ; i < printContents.length ; i++) {
    contentToPrint+="<div class='page'>"+printContents.item(i).innerHTML+"</div>";

  }



  contentToPrint+="<style>\n" +
    "" +
    ".page-header, .page-header-space {\n" +
    "  height: 100px;\n" +
    "}\n" +
    "\n" +
    ".page-footer, .page-footer-space {\n" +
    "  height: 50px;\n" +
    "\n" +
    "}\n" +
    ".img:{margin-top: 10%;}\n" +
    ".page-footer {\n" +
    "  position: fixed;\n" +
    "  bottom: 0;\n" +
    "  width: 100%;\n" +
    "  border-top: 3px solid black; /* for demo */\n" +
    "}\n" +
    "\n" +
    ".page-header {\n" +
    "  position: fixed;\n" +
    "  top: 0mm;\n" +
    "  width: 100%;\n" +
    "  border-bottom: 3px solid black; /* for demo */\n" +
    "}\n" +
    "\n" +
    ".page {\n" +
    "  page-break-after: always;\n" +
    "}.prova{width: 100%;}\n" +
    "\n" +
    "\n" +
    "  td,th{\n" +
    "text-align: center;" +
    "    font-size: 10px;\n" +
    "    \n" +
    "  }" +
    "@page {\n" +
    "  margin: 20mm\n" +
    "}\n" +
    ".test{margin-top: 18%!important;}" +
    "\n" +
    "@media print {\n" +
    ".img{margin-top: 10%;}" +
    "   thead {display: table-header-group;} \n" +
    "   tfoot {display: table-footer-group;}\n" +
    "   \n" +
    "   button {display: none;}\n" +
    "   \n" +
    "   body {margin: 0;}\n" +
    "#noPrint{ display: none !important;}" +
    "" +
    "" +
    "}  </style>"
 // let originalContents = document.body.innerHTML;

  //document.body.innerHTML = contentToPrint;

  let w=window.open();
  if(w!=null) {
    w.document.write(contentToPrint);
    setTimeout(function () { // wait until all resources loaded
      w.document.close(); // necessary for IE >= 10
      w.focus(); // necessary for IE >= 10
      w.print();  // change window to winPrint
      w.close();// change window to winPrint
      if(currentUser.role!="Backoffice") {
        navigate("/backoffice/dashboard-reseller");
      }else{
        navigate("/backoffice/campaigns/reseller/"+IdPromo);
      }
    }, 250);
  }

  //w.print();
  //w.close();
  //navigate("/backoffice/dashboard-reseller");
}



  function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }


  function getData(params){
    if(params!=null){
      let mese=params.substring(0,2);
      let anno=params.substring(6,10);



      if( mese == '01'){
        mese = 'GENNAIO';
      }
      else if (mese== '02'){
        mese = 'FEBBRAIO';
      }
      else if (mese== '03'){
        mese = 'MARZO';
      }
      else if (mese== '04'){
        mese = 'APRILE';
      }
      else if (mese== '05'){
        mese = 'MAGGIO';
      }
      else if (mese== '06'){
        mese = 'GIUGNO';
      }
      else if (mese== '07'){
        mese = 'LUGLIO';
      }
      else if (mese== '08'){
        mese = 'AGOSTO';
      }
      else if (mese== '09'){
        mese = 'SETTEMBRE';
      }
      else if (mese== '10'){
        mese = 'OTTOBRE';
      }
      else if (mese== '11'){
        mese = 'NOVEMBRE';
      }
      else if (mese== '12'){
        mese = 'DICEMBRE';
      }


      return mese+" "+anno;

    }
  }

const splitIntoChunks = (array, chunk = 3) => {
  let result = []

  for (let i=0; i<array.length; i+=chunk) {
    result.push(array.slice(i,i+chunk));
  }

  return result;
}


const Results = ({ className, practiceData,isFetching,resellerData,setIsFetchingTable,isFetchingTable,currentUser,IdPromo,...rest }) => {

  var today = new Date();
  let giorno=today.getDate();
  let mese=(today.getMonth() + 1);
  let anno=today.getFullYear();
  if(giorno.toString().length==1){
    giorno="0"+giorno;
  }
  if(mese.toString().length==1){
    mese="0"+mese;
  }
  let date=giorno+"/"+mese+"/"+anno;
  const isFirefox = typeof InstallTrigger !== 'undefined';

  let rimborsoTot=  parseFloat(resellerData.rimborsi);
  let nomeReseller=resellerData.nameReseller;
  let indirizzoReseller=resellerData.streetReseller;
  let capReseller=resellerData.capReseller;
  let cittaReseller=resellerData.cittaReseller;
  let nomePromo= resellerData.nomePromo;
  let promoClaim=resellerData.attributePromoClaim;
  let startPromo=resellerData.startPromo;
  let endPromo=resellerData.endPromo;
  let arrayDiviso=[];
  startPromo=getData(startPromo);
  endPromo=getData(endPromo);


  arrayDiviso=splitIntoChunks(practiceData, 48);








  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}
  if(!isFetchingTable){
    if(!isFirefox) {
      print(navigate,currentUser,IdPromo);
    }else{
      alert("Impossibile generare pdf da Firefox, utilizzare Chrome o Safari");
      navigate("/backoffice/dashboard-reseller");
    }
  }


return (
  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >

      <Box minWidth={1050}>




<div id="download" className="download">
        <Typography style={{ marginLeft:'80%', fontSize:17}}  className={classes.typografy}>
          Milano, {date}
        </Typography>



        {!isFetching
        &&  (
          <Typography  style={{fontSize:17}}  className={classes.typografy}>
            Spett.<br />
            {nomeReseller}<br />
            {indirizzoReseller}<br />
            {capReseller} {cittaReseller}
          </Typography>
        )}




        {!isFetching
        && (
          <Typography style={{fontSize:15}}  className={classes.typografy}>
            Oggetto: GIUSTIFICATIVO DI BONIFICO {nomePromo}
          </Typography>
        )}
        <br/>
      <div className="column" style={{float: 'left', width: '70%', marginTop:'5%'}}>
        {!isFetching
        && (
        <Typography style={{fontSize:13}} variant="h4" className={classes.typografy}>
          Descrizione<br/>
          PROMOZIONE “{promoClaim}” <br/>
          ({startPromo} {endPromo})<br/>
          Operazione fuori campo IVA regolamentata da art. 26 D.P.R. 633/72
        </Typography>
        )}
      </div>



      <div className="column" style={{float: 'left', width: '30%', marginTop:'5%'}}>
        {!isFetching
        && (
        <Typography style={{fontSize:13}} variant="h4" className={classes.typografy}>
          TOTALE:<br/>
          € {rimborsoTot}
        </Typography>
        )}

      </div >

</div>

    <div id="prova" className="prova" style={{ height:'100%', width: '98%', marginTop:'12%',marginLeft:'1%'}}>
        {isFetchingTable
        && (
          <Container className={classes.circularProgress}>

            <CircularProgress
              size={48}
            />
          </Container>
        )}


      {!isFetching
      &&(
        <TableCreate data={arrayDiviso} totaleRimborso={rimborsoTot} setIsFetchingTable={setIsFetchingTable}></TableCreate>
      )}





      </div>






      </Box>



  </Card>
);

};


Results.propTypes = {
  className: PropTypes.string,
  practiceData: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default Results;
