import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import ViewPromo from './ViewPromo';
import BoxNewPractice from './BoxNewPractice';
import BoxNewVoucher from './BoxNewVoucher';


import { authenticationService } from 'src/services/authentication.service';
import { resellerService } from 'src/services/reseller.service';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    position: 'absolute', 
    left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
}));

const DashboardResellerView = () => {
  const classes = useStyles();

  const [promoData, setPromoData] = useState([]);
  const [typePromoPractice, setTypePromoPractice] = useState(false);
  const [typePromoVoucher, setTypePromoVoucher] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [isFetching, setIsFetching] = useState(false);


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user);
  }, [])



  useEffect(() => {
    setIsFetching(true);
    if (!currentUser) return;
    if (currentUser.role != 'Reseller') return;
    resellerService.GetResellerCampaign(currentUser.id, 'All').then((data) => {
      data.map((item, index) => {

        if ((item.typePromo == 'CampaignPurchaseFirstNoPlafond') || (item.typePromo == 'CampaignPurchaseFirst')) {
          setTypePromoVoucher(true);
        }
        else if (item.typePromo == 'CampaignVoucherFirst') {
          setTypePromoPractice(true);
        }
      });
      setPromoData(data);
      console.log(data)
      setIsFetching(false);
    });
  }, [currentUser])



  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      {isFetching &&
        <Container className={classes.circularProgress}>
          <CircularProgress
            size={48}
          />
        </Container>
      }

      {!isFetching &&
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <BoxNewPractice
                promoData={promoData}
                typePromoPractice={typePromoPractice} />
            </Grid>

            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <BoxNewVoucher promoData={promoData}
                typePromoVoucher={typePromoVoucher} />
            </Grid>


            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <ViewPromo
                promoData={promoData}
                user={currentUser}
              />
            </Grid>


          </Grid>
        </Container>
      }
    </Page>
  );
};

export default DashboardResellerView;
