import tyreFormModel from './tyreFormModel';
const {
  formField: {

    EAN,
    PROD_GRP_1,
    SUPPLIER_CODE,
    DESCRIPTION_1,
    DESCRIPTION_2,
    PROD_INFO,
    WDK_ANUB,
    WDK_BRAND,
    WDK_BRAND_TEXT,
    BRAND,
    BRAND_TEXT,
    PROD_GRP_2,
    GROUP_DESCRIPTION,
    WEIGHT,
    RIM_INCH,
    PROD_CYCLE,
    THIRD_PARTY,
    PL,
    TEL,
    EDI,
    ADHOC,
    PL_ID,
    URL_1,
    URL_2,
    URL_3,
    URL_4,
    URL_5,
    WIDTH_MM,
    WIDTH_INCH,
    ASPECT_RATIO,
    OVL_DIAMETER,
    CONSTRUCTION_1,
    CONSTRUCTION_2,
    USAGE,
    DEPTH,
    LI1,
    LI2,
    LI3DWB,
    LI4DWB,
    SP1,
    SP2,
    TLTT,
    FLANK,
    PR,
    RFD,
    SIZE_PREFIX,
    COMM_MARK,
    RIM_MM,
    RUN_FLAT,
    SIDEWALL,
    DESIGN_1,
    DESIGN_2,
    PRODUCT_TYPE,
    VEHICLE_TYPE,
    COND_GRP,
    TAX_ID,
    TAX,
    SUGGESTED_PRICE,
    GROSS_PRICE,
    GP_VALID_FROM,
    NET_VALUE,
    NV_VALID,
    RECYCLING_FEE,
    NOISE_PERFORMANCE,
    NOISE_CLASS_TYPE,
    ROLLING_RESISTANCE,
    WET_GRIP,
    EC_VEHICLE_CLASS,
    EU_DIRECTIVE_NUMBER,
    BUSINESS,
    ISWINTER
  }
} = tyreFormModel;

export default {

  [EAN.name]:null,
  [PROD_GRP_1.name]:null,
  [SUPPLIER_CODE.name]:null,
  [DESCRIPTION_1.name]:null,
  [DESCRIPTION_2.name]:null,
  [PROD_INFO.name]:null,
  [WDK_ANUB.name]:null,
  [WDK_BRAND.name]:null,
  [WDK_BRAND_TEXT.name]:null,
  [BRAND.name]:null,
  [BRAND_TEXT.name]:null,
  [PROD_GRP_2.name]:null,
  [GROUP_DESCRIPTION.name]:null,
  [WEIGHT.name]:null,
  [RIM_INCH.name]:null,
  [PROD_CYCLE.name]:null,
  [THIRD_PARTY.name]:null,
  [PL.name]:null,
  [TEL.name]:null,
  [EDI.name]:null,
  [ADHOC.name]:null,
  [PL_ID.name]:null,
  [URL_1.name]:null,
  [URL_2.name]:null,
  [URL_3.name]:null,
  [URL_4.name]:null,
  [URL_5.name]:null,
  [WIDTH_MM.name]:null,
  [WIDTH_INCH.name]:null,
  [ASPECT_RATIO.name]:null,
  [OVL_DIAMETER.name]:null,
  [CONSTRUCTION_1.name]:null,
  [CONSTRUCTION_2.name]:null,
  [USAGE.name]:null,
  [DEPTH.name]:null,
  [LI1.name]:null,
  [LI2.name]:null,
  [LI3DWB.name]:null,
  [LI4DWB.name]:null,
  [SP1.name]:null,
  [SP2.name]:null,
  [TLTT.name]:null,
  [FLANK.name]:null,
  [PR.name]:null,
  [RFD.name]:null,
  [SIZE_PREFIX.name]:null,
  [COMM_MARK.name]:null,
  [RIM_MM.name]:null,
  [RUN_FLAT.name]:null,
  [SIDEWALL.name]:null,
  [DESIGN_1.name]:null,
  [DESIGN_2.name]:null,
  [PRODUCT_TYPE.name]:null,
  [VEHICLE_TYPE.name]:null,
  [COND_GRP.name]:null,
  [TAX_ID.name]:null,
  [TAX.name]:null,
  [SUGGESTED_PRICE.name]:null,
  [GROSS_PRICE.name]:null,
  [GP_VALID_FROM.name]:null,
  [NET_VALUE.name]:null,
  [NV_VALID.name]:null,
  [RECYCLING_FEE.name]:null,
  [NOISE_PERFORMANCE.name]:null,
  [NOISE_CLASS_TYPE.name]:null,
  [ROLLING_RESISTANCE.name]:null,
  [WET_GRIP.name]:null,
  [EC_VEHICLE_CLASS.name]:null,
  [EU_DIRECTIVE_NUMBER.name]:null,
  [BUSINESS.name]:null,
  [ISWINTER.name]:null
};

