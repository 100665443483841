import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignResellerEdit = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const navigate = useNavigate();
  var dict = [];
  const [currentUser, setCurrentUser] = useState();

  let [resellersData, setResellersData] = useState([]);
  let [resellerDataInPromo, setResellerDataInPromo] = useState([]);
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;



    promoService.GetPromo(CampaignId).then(data => {
      setCampaignsSingleData(data);
    });



    promoService.GetPromoReseller(CampaignId).then((data) => {
      data.map((item, index) => data[index].id=item.idReseller);
      setResellerDataInPromo(data);
    });



    promoService.GetEligiblePromoReseller(CampaignId)
      .then((data) => {
        const promoResellers = data.map(
          promoReseller => {
            return {
              id: promoReseller?.idReseller,
              idReseller: promoReseller?.idResellerNavigation?.idReseller,
              sapCode: promoReseller?.idResellerNavigation?.sapCode,
              shipToCode: promoReseller?.idResellerNavigation?.shipToCode,
              name: promoReseller?.idResellerNavigation?.name,
              street: promoReseller?.idResellerNavigation?.street,
              cap: promoReseller?.idResellerNavigation?.cap,
              city: promoReseller?.idResellerNavigation?.city,
              email: promoReseller?.idResellerNavigation?.email,
              typeReseller: promoReseller?.idResellerNavigation?.typeReseller,
              resellerSelected: promoReseller?.resellerSelected==true?true:false,
            }
          }
        );
        setResellersData(promoResellers);
      });


}, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}

        <Box mt={3}>
          <Results resellersData={resellersData} setResellersData={setResellersData} resellerDataInPromo={resellerDataInPromo} idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignResellerEdit;


   
