import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, award,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}



  const headCells = [
    {field: 'idAward', headerName: 'idAward',  width: 125, description:'The internal idAward.'},
    {field: 'awardName',   headerName: 'Award name',  width: 400, description:'The internal awardName.'},
    {field: 'awardDescription',   headerName: 'Award description',  width: 250, description:'The internal awardDescription.'},
    {field: 'awardValue',   headerName: 'Award value',  width: 150, description:'The internal awardValue.'},
    {field: 'Cancella', headerName: 'Cancella', width: 275, description:'Cancella Reseller',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancPromoAward(params.row.id) }}
          >Cancella
          </Button>
        </div>
      ), },
  ];



  let cancPromoAward= async (idPromoAward)=>{


    await promoService.deletePromoAwardByIdPromoAward(idPromoAward).then(data => {
      if(data==1) {
        const {value: newplafond} = Swal.fire({
          title: 'PromoAward Cancellata',
          showCancelButton: false,
        }).then((ret) => {
          window.location.reload();
        })

      }
      else{
        const { value: newplafond } =  Swal.fire({
          title: "Errore nella cancellazione della PromoAward" ,
          showCancelButton: false,

        })
      }


    });
  }



return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
                  variant="contained"
                  color="primary"
                  size="small"
            // style={{ marginLeft: 16 }}
                  onClick={() => {navigate("/backoffice/campaigns/awardEdit/"+idCampaign)}}
          >
            Modifica Award
          </Button>
        </div>
      <div id='TableInCampaign'>



      <DataGrid
          columns={headCells}
          rows={award}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  award: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
