import createVoucherFormModel from './createVoucherFormModel';
const {
  formField: {
    IdPromo, 
    PromoTextSelected,
    QtaVoucher, 
    IdPromoAward 
  }
} = createVoucherFormModel;

export default { 
  [IdPromo.name]: '', 
  [PromoTextSelected.name]: '', 
  [QtaVoucher.name]: '',
  [IdPromoAward.name]: '' 
}; 


