import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
  
import getInitials from 'src/utils/getInitials';
 
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {promoService} from "../../../services/promo.service";
import {resellerService} from "../../../services/reseller.service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  div: {
    // backgroundColor: theme.palette.background.dark,
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    width: '100%',
    height: 880
  },
  datagrid: {
    fontSize: 12,
  }
}));

  

const Results = ({ className, resellers,setResellersData, ...rest }) => {
  const classes = useStyles();







  const headCells = [
    {field: 'idReseller', headerName: 'Id', width: 75, description:'The identification used by the person with access to the online service.'},
    {field: 'sapCode', headerName: 'Sap Code',  width: 125, description:'The identification used by the person with access to the online service.'},
    {field: 'name', headerName: 'Name', width:300,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"name"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyName(params.row.name,params.row.idReseller, params)}}
          > {params.row.name!=""?params.row.name:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'street', headerName: 'Street', width:300,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"street"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyStreet(params.row.street,params.row.idReseller, params)}}
          > {params.row.street!=""?params.row.street:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'city', headerName: 'City', width:200, description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"city"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyCity(params.row.city,params.row.idReseller, params)}}
          > {params.row.city!=""?params.row.city:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'cap', headerName: 'Cap', flex: 2,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"cap"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyCap(params.row.cap,params.row.idReseller, params)}}
          > {params.row.cap.trim()!="" ?params.row.cap:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'province', headerName: 'Province', width:100,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"province"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { modifyProvince(params.row.province,params.row.idReseller, params)}}
          > {params.row.province.trim()!=""?params.row.province:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'region', headerName: 'Region', width:100,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"region"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyRegion(params.row.region,params.row.idReseller, params)}}
          > {params.row.region!=""?params.row.region:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'country', headerName: 'Country', width:100,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"country"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyCountry(params.row.country,params.row.idReseller, params)}}
          > {params.row.country!=""?params.row.country:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'geoCoords', headerName: 'Geo Coords',  width: 200, description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"geoCoords"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyGeoCoords(params.row.geoCoords,params.row.idReseller, params)}}
          > {params.row.geoCoords!=""?params.row.geoCoords:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'phone', headerName: 'Phone',  width: 150, description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"phone"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { modifyPhone(params.row.phone,params.row.idReseller, params)}}
          > {params.row.phone!=""?params.row.phone:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'email', headerName: 'Email',  width: 300, description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"email"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {   modifyEmail(params.row.email,params.row.idReseller, params)}}
          > {params.row.email!=""?params.row.email:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'channel', headerName: 'Channel', width:100,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"channel"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { modifyChannel(params.row.channel,params.row.idReseller, params)}}
          > {params.row.channel!=""?params.row.channel:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'typeReseller', headerName: 'Type Reseller',  width: 150, description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"typeReseller"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { modifyTypeReseller(params.row.typeReseller,params.row.idReseller, params)}}
          > {params.row.typeReseller!=""?params.row.typeReseller:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'saleType', headerName: 'Type sales', width: 150,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"saleType"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { modifySaleType(params.row.saleType,params.row.idReseller, params)}}
          > {params.row.saleType!=""?params.row.saleType:'modifica'}
          </Button>
        </div>
      ), },
    {field: 'mobox', headerName: 'Mobox', width:100,  description:'The identification used by the person with access to the online service.',renderCell : (params) => (
        <div>
          <Button
            id={params.row.idReseller+"mobox"}
            variant="contained"
            className={"button_campagne"}
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyMobox(params.row.mobox,params.row.idReseller, params)}}
          > {params.row.mobox.toString()!=""?params.row.mobox.toString():'modifica'}
          </Button>
        </div>
      ), }

  ];






  let modifyStreet = ( street ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica street reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore street',
      inputValue: street,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.street=ret.value.toString()
            p.row.street=ret.value.toString()
            document.getElementById(idReseller+"street").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }



  let modifyName = ( name ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica nome reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore name',
      inputValue: name,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.name=ret.value.toString()
            p.row.name=ret.value.toString()
            document.getElementById(idReseller+"name").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }



  let modifyCap = ( cap ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica cap reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore cap',
      inputValue: cap,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.cap=ret.value.toString()
            p.row.cap=ret.value.toString()
            document.getElementById(idReseller+"cap").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }



  let modifyCity = ( city ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica city reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore city',
      inputValue: city,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.city=ret.value.toString()
            p.row.city=ret.value.toString()
            document.getElementById(idReseller+"city").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }



  let modifyProvince = ( province ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica province reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore province',
      inputValue: province,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.province=ret.value.toString()
            p.row.province=ret.value.toString()
            document.getElementById(idReseller+"province").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }


  let modifyRegion = ( region ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica region reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore region',
      inputValue: region,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.region=ret.value.toString()
            p.row.region=ret.value.toString()
            document.getElementById(idReseller+"region").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }


  let modifyCountry = ( country ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica country reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore country',
      inputValue: country,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.country=ret.value.toString()
            p.row.country=ret.value.toString()
            document.getElementById(idReseller+"country").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifyGeoCoords = ( geoCoords ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica geoCoords reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore geoCoords',
      inputValue: geoCoords,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.geoCoords=ret.value.toString()
            p.row.geoCoords=ret.value.toString()
            document.getElementById(idReseller+"geoCoords").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifyPhone = ( phone ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica phone reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore phone',
      inputValue: phone,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.phone=ret.value.toString()
            p.row.phone=ret.value.toString()
            document.getElementById(idReseller+"phone").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifyEmail = ( email ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica email reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore email',
      inputValue: email,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.email=ret.value.toString()
            p.row.email=ret.value.toString()
            document.getElementById(idReseller+"email").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifyChannel = ( channel ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica channel reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore channel',
      inputValue: channel,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.channel=ret.value.toString()
            p.row.channel=ret.value.toString()
            document.getElementById(idReseller+"channel").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }


  let modifyTypeReseller = ( typeReseller ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica typeReseller reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore typeReseller',
      inputValue: typeReseller,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.typeReseller=ret.value.toString()
            p.row.typeReseller=ret.value.toString()
            document.getElementById(idReseller+"typeReseller").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifySaleType = ( saleType ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica saleType reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore saleType',
      inputValue: saleType,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            item.saleType=ret.value.toString()
            p.row.saleType=ret.value.toString()
            document.getElementById(idReseller+"saleType").innerHTML=ret.value.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }

  let modifyMobox = ( mobox ,idReseller, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica mobox reseller',
      input: 'text',
      inputLabel: 'Inserire il nuovo valore mobox',
      inputValue: mobox.toString(),
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }else if(value.toString()!="true" && value.toString()!="false"){
          return "inserire true o false"
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        resellers.map((item, index) => {
          if(item.idReseller==idReseller){
            var isTrueSet = (ret.value === 'true');

            item.mobox=isTrueSet
            p.row.mobox=isTrueSet
            document.getElementById(idReseller+"mobox").innerHTML=isTrueSet.toString()
          }
        });

        setResellersData(resellers)

      }
    })
  }



  let salva = async () => {

    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Sicuro di voler salvare?',
      showCancelButton: true,

    }).then(async (ret) =>{
      if (ret.isConfirmed){

        let resellerIniziali=[]
        await resellerService.GetAllReseller().then((data) => {
          data.map((item, index) => data[index].id=item.idReseller);
          resellerIniziali=data
        });
        let resellerToUpdate=[]
        resellers.map((item,index)=>{
          resellerIniziali.map((item1,index1)=>{
            if(item.idReseller==item1.idReseller  && JSON.stringify(item)!=JSON.stringify(item1)){
              resellerToUpdate.push(item)

            }

          })
        })
        if(resellerToUpdate.length>0){

          await promoService.updateListReseller(resellerToUpdate).then((data)=>{

            if(data==1) {
              const { value: newplafond } =  Swal.fire({
                title: 'Reseller modificati' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })




            }else{
              const { value: newplafond } =  Swal.fire({
                title: "Errore nella modifica dei Reseller" ,
                showCancelButton: false,

              })
            }
          });
        }else{
          const { value: newplafond } =  Swal.fire({
            title: "Non ci sta nulla da salvare" ,
            showCancelButton: false,

          })
        }
      }
    })
  }



  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    > 
    <PerfectScrollbar>
        <Box minWidth={1050}> 
        <div className={classes.div} id='tableReseller'>
        <DataGrid
            className={classes.datagrid}
            columns={headCells} 
            rows={resellers}
            pageSize={25}
            rowsPerPageOptions={[5, 10, 20, 50]}
            showToolbar
              components={{
                Toolbar: GridToolbar,
              }}
            />
        </div> 
        </Box>
        
      </PerfectScrollbar>
      <div id='top_button'>
      <Button
        style={{marginTop:"1%"}}
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={salva}
      >
        Salva
      </Button>
      </div>
    </Card>
 
  );
};

Results.propTypes = {
  className: PropTypes.string,
  resellers: PropTypes.array.isRequired,
};

export default Results;
