import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';

import { reportService } from '../../../services/report.service';
import { authenticationService } from 'src/services/authentication.service';

import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.grey[900],
    height: 56,
    width: 56
  },
  reportButton: {
    backgroundColor: colors.red[600] 
  }
}));

const Reports = ({ className, reports, ...rest }) => {
  const classes = useStyles();

  let [isSubmitting, setIsSubmitting] = useState(false); 
  const [currentUser, setCurrentUser] = useState();
   
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])

  let downloadReport = (ReportId, ReportName) => { 
    setIsSubmitting(true);
    reportService.GetReportById(ReportId, currentUser.id, ReportName).then((data) => {  
          setIsSubmitting(false);
    });
  }

  return (
    <Grid
          container
          spacing={6}
        >



{reports.map((report) => (
// xs, sm, md, lg, and xl
<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          // justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Avatar className={classes.avatar}>
            <InsertChartIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              noWrap={false}
            >
              {report.nameReport}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
            >
            {report.descriptionReport}
            </Typography>
          </Grid>
          


        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
       
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.reportButton}
                        onClick={() => { downloadReport(report.idReport, report.nameReport) }}
                      >
                        Richiedi
                      </Button>
    
           
           
        </Box>
      </CardContent>
    </Card>
    </Grid>
    ))}
          
   
    </Grid>
  );
};

Reports.propTypes = {
  className: PropTypes.string
};

export default Reports;
