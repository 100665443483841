import React, { useEffect, useState } from 'react';
import {
  Container,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
}
  from '@material-ui/core';

import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { InputField, CheckboxField, SelectField } from '../../../../';
import { makeStyles } from '@material-ui/core/styles';
 
 
import { downloadUploadService } from '../../../../services/downloadUpload.service'; 

const headCells = [
  { field: 'idReseller', headerName: 'Id', width: 75, description: 'The identification used by the person with access to the online service.' },
  { field: 'sapCode', headerName: 'Codice SAP', width: 125, description: 'The identification used by the person with access to the online service.' },
  { field: 'shipToCode', headerName: 'Codice Ship To', width: 150, description: 'The identification used by the person with access to the online service.' },
  { field: 'name', headerName: 'Denominazione', width: 250, description: 'The identification used by the person with access to the online service.' },
  { field: 'street', headerName: 'Indirizzo', width: 200, description: 'The identification used by the person with access to the online service.' },
  { field: 'cap', headerName: 'CAP', width: 125, description: 'The identification used by the person with access to the online service.' },
  { field: 'city', headerName: 'Città', width: 200,  description: 'The identification used by the person with access to the online service.' },
  { field: 'email', headerName: 'Email', width: 200, description: 'The identification used by the person with access to the online service.' },
  { field: 'typeReseller', headerName: 'Network', width: 100, description: 'The identification used by the person with access to the online service.' },
];
 
const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(3)
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  div: {
    width: '100%',
    height: 600
  },
  datagrid: {
    fontSize: 12,
  }
}));



export default function ResellersJoined(props) {
  const {
    formField: {
      PromoInternalName,
      CompanyConventionCodePromoAttribute,
      CompanyNameConventionPromoAttribute,
      CompanyEmailDomainConventionPromoAttribute,
      StartVoucherRequestPromo,
      EndVoucherRequestPromo,
      EndVoucherRedemeedPromo,
      EndVoucherRedemeedFirstopPromo,
      StartViewPromo,
      EndViewPromo,
      EndPracticeRbo
    },
    conventionId,
    ResellersData, 
    HandleResellerChange,
    DownloadModel,
    UploadModel,
    IsUploading
  } = props;
 
  const selectionModel = ResellersData.filter((r) => r.resellerSelected).map((r) => r.id)



  const classes = useStyles();
  return (
    <Grid item xs={12} md={12} spacing={5}>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button className={classes.buttons}
          color="primary"
          variant="contained"
          onClick={() => { DownloadModel('Convention', 'Resellers') }}
        >
          Scarica modello importazione
        </Button>

        <Button className={classes.buttons}
          color="primary"
          variant="contained"
          component="label"
        >
          Importa da modello
          <input
            type="file"
            hidden
            onClick={e => (e.target.value = null)}
            onChange={(e) => UploadModel('Convention', 'Resellers', e.target.files[0])}
          />
        </Button>
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file"
          // multiple
          type="file"
        />
      </Box>
      {/* <PerfectScrollbar> */}
      <Box minWidth={1050}>
        <div className={classes.div}>

                        <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                          HandleResellerChange(newSelection.selectionModel);
                        }}     
                        disableSelectionOnClick={true}
                        selectionModel={selectionModel}
                        className={classes.datagrid}
                        columns={headCells}
                        rows={ResellersData}
                        pageSize={50}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        showToolbar
                        components={{
                          Toolbar: GridToolbar,
                        }}
                      />
       
          
          
        </div>
      </Box>

      {/* </PerfectScrollbar> */}
    </Grid> 
  );
}
