import addPracticeFormModel from './addPracticeFormModel';
const {
  formField: {
    VoucherCode,
    IdPromo,
    LongNamePromoAttribute,
    PromoExternalName,    
    IdProspect,
    ProspectName
  }
} = addPracticeFormModel;

export default {
  [VoucherCode.name]: '',
  [IdPromo.name]: '',
  [LongNamePromoAttribute.name]: '',
  [PromoExternalName.name]: '',   
  [IdProspect.name]: 0,
  [ProspectName.name]: ''
}; 


