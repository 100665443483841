import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();


  const handleAddClick = (event) => {
    navigate('add', { replace: false });
  };


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
       <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
            <Button
            color="primary"
            variant="contained"
            onClick={handleAddClick}
            >NEW CAMPAIGN
            </Button>

            </Box>
          </CardContent>
        </Card>
      </Box>

    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
