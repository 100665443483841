import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const BoxNewVoucher = ({ className,  promoData, typePromoVoucher, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  let onClick = () => {
    navigate('../vouchers/add', { replace: true });
  }
  return (
    <div>
      {typePromoVoucher&&
      <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              
            </Typography>
            <Button
                        color="secondary" 
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => { onClick() }}
                      >
                        GENERA CARTOLINA
                  </Button> 
              
          </Grid>
         
        </Grid>
        
      </CardContent>
    </Card>}
    </div>
  );
};

BoxNewVoucher.propTypes = {
  className: PropTypes.string
};

export default BoxNewVoucher;
