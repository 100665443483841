import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch, CircularProgress
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


import validationSchema from './FormModel/validationSchema';
import conventionFormModel from './FormModel/conventionFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonConventionData from './Forms/CommonConventionData';
import AwardsJoined from './Forms/AwardsJoined';
import ResellersJoined from './Forms/ResellersJoined';
import TyresJoined from './Forms/TyresJoined';
import {downloadUploadService} from "../../../services/downloadUpload.service";
import {conventionService} from "../../../services/convention.service";
import {Form, Formik} from "formik";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, conventionData,setConventionData,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let tyresToAdd=[];
  // renderCell: (data) => {return moment().format("")}
  const currentValidationSchema = validationSchema[0];

  const { formId, formField } = conventionFormModel;
  const [isUploading, setIsUploading] = React.useState(false);
  let [hasError, setHasError] = React.useState({
    value: false,
    description:''
  });


  const formDate=(data)=>{
    let array=data.split("/");
    return array[2]+"-"+array[1]+"-"+array[0]+"T01:01:01.259Z";
  }


  const salva=async ()=>{
    let form=document.getElementById("conventionForm");
    let dict={};
    dict.promoExternalName=form.PromoExternalName.value.toString();
    dict.promoInternalName= form.PromoInternalName.value.toString();
    dict.startVoucherRequestPromo= formDate(form.StartVoucherRequestPromo.value.toString());
    dict.endVoucherRequestPromo= formDate(form.EndVoucherRequestPromo.value.toString());
    dict.endVoucherRedemeedPromo= formDate(form.EndVoucherRedemeedPromo.value.toString());
    dict.endVoucherRedemeedFirstopPromo=formDate(form.EndVoucherRedemeedFirstopPromo.value.toString());
    dict.startViewPromo= formDate(form.StartViewPromo.value.toString());
    dict.endViewPromo= formDate(form.EndViewPromo.value.toString());
    dict.endPracticeRbo=formDate(form.EndPracticeRbo.value.toString());
    dict.onBekube=form.OnBekube.value.toString()=="true"?true:false

    await promoService.updatePromoForEditPromo(idCampaign,dict).then(data => {

      if(data==1) {
        const { value: newplafond } =  Swal.fire({
          title: 'Promo modificata' ,
          showCancelButton: false,
        }).then((ret) =>{
          navigate("/backoffice/campaigns");
        })


      }else if(data==-1) {
        const {value: newplafond} = Swal.fire({
          title: "PromoInternalName o PromoExternalName già esistente",
          showCancelButton: false,
        })
      }
      else{
        const { value: newplafond } =  Swal.fire({
          title: "Errore nella modifica della promo" ,
          showCancelButton: false,

        })
      }
    });




  }






  return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
    style={{

      width: '1200px',
      marginLeft: 'auto',
      marginRight: 'auto'}}
  >
    <Formik
      initialValues={{
        ['PromoInternalName']: conventionData?.PromoInternalName,
        ['PromoExternalName']: conventionData?.PromoExternalName,
        ['CompanyConventionCodePromoAttribute']: conventionData?.CompanyConventionCodePromoAttribute,
        ['CompanyNameConventionPromoAttribute']: conventionData?.CompanyNameConventionPromoAttribute,
        ['CompanyEmailDomainConventionPromoAttribute']: conventionData?.CompanyEmailDomainConventionPromoAttribute,
        ['StartVoucherRequestPromo']: conventionData?.StartVoucherRequestPromo,
        ['EndVoucherRequestPromo']: conventionData?.EndVoucherRequestPromo,
        ['EndVoucherRedemeedPromo']: conventionData?.EndVoucherRedemeedPromo,
        ['EndVoucherRedemeedFirstopPromo']: conventionData?.EndVoucherRedemeedFirstopPromo,
        ['StartViewPromo']: conventionData?.StartViewPromo,
        ['EndViewPromo']: conventionData?.EndViewPromo,
        ['EndPracticeRbo']: conventionData?.EndPracticeRbo,
        ['OnBekube']:conventionData?.OnBekube,
        ["TypePromo"]:conventionData?.TypePromo
      }}
      enableReinitialize={true}

      validationSchema={currentValidationSchema}

      //   onChange={handleChange}
    >
        <Form id='conventionForm'>
          <CommonConventionData
            formField={formField}
            conventionId={idCampaign}/>
          <p style={{marginTop: "1%"}}>TypePromo: {conventionData.TypePromo}</p>
          <Button
            style={{marginTop:"1%"}}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={salva}
          >
            Salva
          </Button>

        </Form>

    </Formik>


  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
