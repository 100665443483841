import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import DashboardView from 'src/views/dashboard/DashboardView';
import DashboardResellerView from 'src/views/dashboardReseller/DashboardResellerView';
import LoginView from 'src/views/auth/LoginView';
import LogoutView from 'src/views/auth/LogoutView';
import ChangeCreateLoginView from 'src/views/auth/ChangeCreateLoginView'; 
import NotFoundView from 'src/views/errors/NotFoundView';

import PracticeListView from 'src/views/practice/PracticeListView';
import AddPractice from 'src/views/practice/PracticeCreateEdit/AddPractice';
import EditPractice from 'src/views/practice/PracticeCreateEdit/EditPractice';

import PracticeBoListView from 'src/views/practiceBo/PracticeBoListView';
import EditPracticeBo from 'src/views/practiceBo/PracticeCreateEdit/EditPractice';


import VoucherListView from 'src/views/voucher/VoucherListView'; 
import AddVoucher from 'src/views/voucher/VoucherCreate/AddVoucher';

import ResellerListView from 'src/views/reseller/ResellerListView';
import FirstLoginView from 'src/views/auth/FirstLoginView';
import ForgotPasswordView from 'src/views/auth/ForgotPasswordView';
import SettingsView from 'src/views/settings/SettingsView';


import ConventionListView from 'src/views/convention/ConventionListView';
import AddEditConvention from 'src/views/convention/ConventionCreateEdit/AddEditConvention';


import CampaignListView from 'src/views/campaign/CampaignListView';
import AddEditCampaign from 'src/views/campaign/CampaignCreateEdit/AddEditCampaign'
import CampaignResellerList from 'src/views/campaign/CampaignResellerList'

import AwardListView from 'src/views/award/AwardListView';
import AddEditAward from 'src/views/award/AwardCreateEdit/AddEditAward';

import TyreListView from 'src/views/tyres/TyreListView';
import AddEditTyre from 'src/views/tyres/TyreCreateEdit/AddEditTyre';

import ReportView from 'src/views/report/ReportListView';
import CampaignGiustificativoListView from "./views/campaign/CampaignGiustificativoListView";
import CampaignTyreList from "./views/campaign/CampaignTyreList";
import CampaignAwardList from "./views/campaign/CampaignAwardList";
import CampaignPromoAttributeList from "./views/campaign/CampaignPromoAttributeList";
import CampaignEmailList from "./views/campaign/CampaignEmailList";
import CampaignFormList from "./views/campaign/CampaignFormList";
import CampaignTyreAsAwardList from "./views/campaign/CampaignTyreAsAwardList";
import CampaignTyreEdit from "./views/campaign/CampaignTyreEdit";
import CampaignTyreAsAwardEdit from "./views/campaign/CampaignTyreAsAwardEdit";
import CampaignResellerEdit from "./views/campaign/CampaignResellerEdit";
import CampaignPromoEdit from "./views/campaign/CampaignPromoEdit";
import CampaignAwardEdit from "./views/campaign/CampaignAwardEdit";
import CampaignPromoCreate from "./views/campaign/CampaignPromoCreate";

import MediaListView from "./views/media/MediaListView";
import AddEditMedia from "./views/media/MediaCreate/AddEditMedia";
import CampaignProspectList from "./views/campaign/CampaignProspectList";




 
const routes = (currentUser) => [
  {
    path: 'backoffice',
    element: currentUser ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard-reseller', element: (currentUser && currentUser.role ==='Reseller') ? <DashboardResellerView /> : <Navigate to="/404" />},
      { path: 'dashboard', element: (currentUser && (
        currentUser.role ==='Backoffice' ||
        currentUser.role ==='Admin' ||
        currentUser.role ==='Bridgestone')) ? <DashboardView /> : <Navigate to="/404" />},



      { path: 'media', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <MediaListView /> : <Navigate to="/404"/>},
      { path: 'mediaAdd', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditMedia /> : <Navigate to="/404"/>},




      { path: 'awards', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AwardListView /> : <Navigate to="/404"/>},
      { path: 'awards/add', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<AddEditAward /> : <Navigate to="/404" /> },
      { path: 'awards/edit/:AwardId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditAward />  : <Navigate to="/404" />},



      { path: 'tyres', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<TyreListView />  : <Navigate to="/404" />},
      { path: 'tyres/add', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<AddEditTyre />  : <Navigate to="/404" />},
      { path: 'tyres/edit/:TyresId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditTyre/>  : <Navigate to="/404" />},


 
      { path: 'conventions', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin' || currentUser.role ==='Bridgestone')) ? <ConventionListView /> : <Navigate to="/404" />},
      { path: 'conventions/add', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditConvention /> : <Navigate to="/404" />},
      { path: 'conventions/edit/:ConventionId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditConvention /> : <Navigate to="/404" />},




      { path: 'campaigns', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignListView />  : <Navigate to="/404" />},
      { path: 'campaigns/add', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<AddEditCampaign /> : <Navigate to="/404" /> },
      { path: 'campaigns/edit/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <AddEditCampaign />  : <Navigate to="/404" />},
      { path: 'campaigns/reseller/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <CampaignResellerList />  : <Navigate to="/404" />},
      { path: 'campaigns/giustificativo/:IdPromo/:IdReseller', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone'|| currentUser.role ==='Reseller')) ? <CampaignGiustificativoListView /> : <Navigate to="/404" /> },
      { path: 'campaigns/tyre/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <CampaignTyreList />  : <Navigate to="/404" />},
      { path: 'campaigns/award/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignAwardList />  : <Navigate to="/404" />},
      { path: 'campaigns/promoAttribute/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignPromoAttributeList /> : <Navigate to="/404" /> },
      { path: 'campaigns/email/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignEmailList /> : <Navigate to="/404" /> },
      { path: 'campaigns/form/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignFormList />  : <Navigate to="/404" />},
      { path: 'campaigns/tyreAsAward/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <CampaignTyreAsAwardList /> : <Navigate to="/404" /> },
      { path: 'campaigns/tyreEdit/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <CampaignTyreEdit/>  : <Navigate to="/404" />},
      { path: 'campaigns/tyreAsAwardEdit/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignTyreAsAwardEdit/> : <Navigate to="/404" /> },
      { path: 'campaigns/resellerEdit/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignResellerEdit/>  : <Navigate to="/404" />},
      { path: 'campaigns/promoEdit/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignPromoEdit/>  : <Navigate to="/404" />},
      { path: 'campaigns/awardEdit/:CampaignId', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignAwardEdit/>  : <Navigate to="/404" />},
      { path: 'campaigns/promoCreate', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ?<CampaignPromoCreate/>  : <Navigate to="/404" />},
      { path: 'campaigns/prospect/:CampaignId', element:(currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin'|| currentUser.role ==='Bridgestone')) ? <CampaignProspectList />  : <Navigate to="/404" />},



      { path: 'conventions', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin' || currentUser.role ==='Bridgestone')) ? <ConventionListView /> : <Navigate to="/404" />},



      { path: 'resellers', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin')) ? <ResellerListView /> : <Navigate to="/404" />},

       { path: 'account', element: (currentUser) ? <AccountView /> : <Navigate to="/404" />},

      { path: 'practices', element: (currentUser && currentUser.role ==='Reseller') ? <PracticeListView /> : <Navigate to="/404" />},
      { path: 'practices/add',  element: (currentUser && currentUser.role ==='Reseller') ? <AddPractice /> : <Navigate to="/404" />},
      { path: 'practices/edit/:PracticeId', element: (currentUser && currentUser.role ==='Reseller') ? <EditPractice /> : <Navigate to="/404" />},

      { path: 'practicesBo', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin')) ? <PracticeBoListView /> : <Navigate to="/404" />},
      { path: 'practicesBo/:promoSelect/:selectState', element: (currentUser && (currentUser.role ==='Backoffice' || currentUser.role ==='Admin')) ? <PracticeBoListView /> : <Navigate to="/404" />},
      { path: 'practicesBo/edit/:PracticeId/:promoSelected/:selectedState', element: (currentUser && (currentUser.role ==='Backoffice'|| currentUser.role ==='Admin')) ? <EditPracticeBo /> : <Navigate to="/404" />},


      { path: 'vouchers',  element: (currentUser && currentUser.role ==='Reseller') ? <VoucherListView /> : <Navigate to="/404" />},
      { path: 'vouchers/add', element: (currentUser && currentUser.role ==='Reseller') ? <AddVoucher /> : <Navigate to="/404" />},

      { path: 'reports', element: (currentUser) ? <ReportView /> : <Navigate to="/404" />},

      { path: 'settings', element: (currentUser && currentUser.role ==='Reseller') ? <SettingsView /> : <Navigate to="/404" />},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: !currentUser ? <LoginView /> : 
            (currentUser.role ==='Reseller' ? <Navigate to="/backoffice/dashboard-reseller"/> : <Navigate to="/backoffice/dashboard"/>)  },
      { path: 'logout', element: <LogoutView /> },
      { path: 'first-login', element: <FirstLoginView /> },
      { path: 'forgot-password', element: <ForgotPasswordView /> },
      { path: 'set-account/:tokenValidation', element: <ChangeCreateLoginView /> }, 
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: !currentUser ? <LoginView /> : 
            (currentUser.role ==='Reseller' ? <Navigate to="/backoffice/dashboard-reseller"/> : <Navigate to="/backoffice/dashboard"/>)  },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

