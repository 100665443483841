import { BehaviorSubject } from 'rxjs';

import configData from './/config.json';
import {API_URL, handleResponse} from '../helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    validate,
    checkToken,
    createPassword,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  

const guardedPromise = preventConcurrentExec();

function login(username, password) { 
    return guardedPromise("login", () => new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        };
        return fetch(`${API_URL}/Authenticate/authenticate`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(responseData));
                    currentUserSubject.next(responseData);
                    return resolve(responseData);
                }
                reject("Empty Response for auth");
            })
            .catch(error => reject(error));
    }));
} 

function validate(userName, email) {
    return guardedPromise("validate", () => new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`${API_URL}/Authenticate/validate?Username=${userName}&Email=${email}`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for auth");
            })
            .catch(error => reject(error));
        }));
}

function checkToken(validationToken) {
    return guardedPromise("checkToken", () => new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`${API_URL}/Authenticate/set-account?token=${validationToken}`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for auth");
            })
            .catch(error => reject(error));
        }));
}

function createPassword(password, confirm, token) { 
    return guardedPromise("createPassword", () => new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password, confirm, token })
        };
        return fetch(`${API_URL}/Authenticate/create-password`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for auth");
            })
            .catch(error => reject(error));
    }));
} 

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
