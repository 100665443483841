import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


import validationSchema from './FormModel/validationSchema';
import conventionFormModel from './FormModel/conventionFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonConventionData from './Forms/CommonConventionData';
import AwardsJoined from './Forms/AwardsJoined';
import ResellersJoined from './Forms/ResellersJoined';
import TyresJoined from './Forms/TyresJoined';
import {downloadUploadService} from "../../../services/downloadUpload.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, resellersData,setResellersData,resellerDataInPromo,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let resellerToAdd=[];
  // renderCell: (data) => {return moment().format("")}
  const { formId, formField } = conventionFormModel;
  const [isUploading, setIsUploading] = React.useState(false);
  let [hasError, setHasError] = React.useState({
    value: false,
    description:''
  });



  const handleResellerChange = (selectionModel) => {
    let isChanged= false;
    let tempReseller = [...resellersData];

    tempReseller.forEach(function (item, index) {
      const checkChecked = selectionModel.findIndex((s => s === item.idReseller.toString()));
      if (checkChecked===-1)
      {
        if (tempReseller[index].resellerSelected === true)
        {
          tempReseller[index].resellerSelected = false;
          isChanged = true;
        }
      }
      else
      {
        if (tempReseller[index].resellerSelected === false)
        {
          tempReseller[index].resellerSelected = true;
          isChanged = true;
        }
      }
    });
    if (isChanged)
    {
      setResellersData(tempReseller);
    }
  };







const salva=async ()=>{
  resellerToAdd=[]
  resellersData.map((item, index) =>{
      if(item.resellerSelected){
        resellerToAdd.push(item.idReseller);
      }
  });
  resellerDataInPromo.map((item,index)=>{

    resellerToAdd.map((item1,index1)=>{
      if(item.idReseller==item1){
        resellerToAdd.splice(index1, 1);
      }
    })
  });


  await promoService.addListResellerInPromoReseller(idCampaign,resellerToAdd).then((data)=>{

    if(data==1) {
      const { value: newplafond } =  Swal.fire({
        title: 'Resellers aggiunti' ,
        showCancelButton: false,
      }).then((ret) =>{
        navigate("/backoffice/campaigns/reseller/"+idCampaign);
      })


    }else{
      const { value: newplafond } =  Swal.fire({
        title: "Errore nell'aggiunta dei Resellers" ,
        showCancelButton: false,

      })
    }
    });
}



  const downloadModel = (Area, Type) => {
    downloadUploadService.DownloadModel("Convention", "Resellers").then((data) => {
    });
  }

  const uploadModel = (Area, Type, file) => {
    setHasError({ value: false, description: '' });
    setIsUploading(true);
    downloadUploadService.UploadModel(idCampaign,
      Area,
      Type,
      file).then(
      (data) => {
        const promoResellers = data.map(
          promoReseller => {
            return {
              id: promoReseller?.idReseller,
              idReseller: promoReseller?.idResellerNavigation?.idReseller,
              sapCode: promoReseller?.idResellerNavigation?.sapCode,
              name: promoReseller?.idResellerNavigation?.name,
              street: promoReseller?.idResellerNavigation?.street,
              cap: promoReseller?.idResellerNavigation?.cap,
              city: promoReseller?.idResellerNavigation?.city,
              email: promoReseller?.idResellerNavigation?.email,
              typeReseller: promoReseller?.idResellerNavigation?.typeReseller,
              resellerSelected: promoReseller?.resellerSelected,
            }
          }
        );
        setHasError({ value: false, description: '' });
        setResellersData(promoResellers);
      },

      error => {
        setHasError({ value: true, description: error });
        alert(error);
      });
    setIsUploading(false);
  }



  return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
    <ResellersJoined
      formField={formField}
      conventionId={idCampaign}
      ResellersData={resellersData}
      HandleResellerChange={handleResellerChange}
      DownloadModel={downloadModel}
      UploadModel={uploadModel}
      IsUploading={isUploading}  />
    <Button style={{margin:'1em'}}
      type="submit"
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={salva}
    >
     Salva
    </Button>
  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  resellersData: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
