export default {
  formId: 'editPracticeForm',
  formField: {
    IdPromo: {
      name: 'IdPromo',
      label: 'IdPromo',
      requiredErrorMsg: 'IdPromo obbligatorio'
    },
    PromoInternalName: {
      name: 'PromoInternalName',
      label: 'Promozione',
      requiredErrorMsg: 'Promozione obbligatoria'
    },
    PromoExternalName: {
      name: 'PromoExternalName',
      label: 'Codice promozione',
      requiredErrorMsg: 'Codice promozione obbligatorio'
    },
    ProspectName: {
      name: 'ProspectName',
      label: 'Cliente',
      requiredErrorMsg: 'Cliente obbligatorio'
    },
    VoucherCode: {
      name: 'VoucherCode',
      label: 'Codice voucher',
      requiredErrorMsg: 'Codice voucher obbligatorio'
    },
    AwardRefund: {
      name: 'AwardRefund',
      label: 'Importo rimborso',
      requiredErrorMsg: 'Importo rimborso obbligatorio'
    },
    DateIns: {
      name: 'DateIns',
      label: 'Data inserimento',
      requiredErrorMsg: 'Data inserimento obbligatoria'
    },
    PracticeNrBo: {
      name: 'PracticeNrBo',
      label: 'Numero pratica',
      requiredErrorMsg: 'Numero pratica obbligatorio'
    },
    PracticeStateBkb: {
      name: 'PracticeStateBkb',
      label: 'Stato pratica',
      requiredErrorMsg: 'Stato pratica obbligatorio'
    },
    QtaPneumaticiUno: {
      name: 'QtaPneumaticiUno',
      label: 'Numero pneumatici primo modello',
      requiredErrorMsg: 'Stato pratica obbligatorio'
    },
    QtaPneumaticiDue: {
      name: 'QtaPneumaticiDue',
      label: 'Eventuale numero pneumatici secondo modello',
      requiredErrorMsg: 'Stato pratica obbligatorio'
    },
    QtaPneumaticiTre: {
      name: 'QtaPneumaticiTre',
      label: 'Eventuale numero pneumatici terzo modello',
      requiredErrorMsg: 'Stato pratica obbligatorio'
    },
    ModelPneumaticiUno: {
      name: 'ModelPneumaticiUno',
      label: 'Primo modello pneumatici',
      requiredErrorMsg: 'Modello pneumatici obbligatorio'
    },
    ModelPneumaticiDue: {
      name: 'ModelPneumaticiDue',
      label: 'Eventuale secondo modello pneumatici',
      requiredErrorMsg: 'Modello pneumatici obbligatorio'
    },
    ModelPneumaticiTre: {
      name: 'ModelPneumaticiTre',
      label: 'Eventuale terzo modello pneumatici',
      requiredErrorMsg: 'Modello pneumatici obbligatorio'
    },
  }
};
  