import configData from './config.json';
import {API_URL, authHeader, handleResponse} from '../helpers';


export const boEmailService = {
    GetBoEmail,
    SendEmailBo
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  

const guardedPromise = preventConcurrentExec();


function GetBoEmail() {
        return guardedPromise("GetBoEmail", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/boEmail`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for resellers");
            })
            .catch(error => reject(error));
    }));
} 

function SendEmailBo(IdEmailTemplate, IdPractice) {
    return guardedPromise("SendEmailBo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/BoEmail/send-email-bo?IdPractice=${IdPractice}&IdEmailTemplate=${IdEmailTemplate}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response");
        })
        .catch(error => reject(error));
}));
}
