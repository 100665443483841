import * as Yup from 'yup';
import moment from 'moment';
import tyreFormModel from './tyreFormModel';
const {
  formField: {

    EAN,
    PROD_GRP_1,
    SUPPLIER_CODE,
    DESCRIPTION_1,
    DESCRIPTION_2,
    PROD_INFO,
    WDK_ANUB,
    WDK_BRAND,
    WDK_BRAND_TEXT,
    BRAND,
    BRAND_TEXT,
    PROD_GRP_2,
    GROUP_DESCRIPTION,
    WEIGHT,
    RIM_INCH,
    PROD_CYCLE,
    THIRD_PARTY,
    PL,
    TEL,
    EDI,
    ADHOC,
    PL_ID,
    URL_1,
    URL_2,
    URL_3,
    URL_4,
    URL_5,
    WIDTH_MM,
    WIDTH_INCH,
    ASPECT_RATIO,
    OVL_DIAMETER,
    CONSTRUCTION_1,
    CONSTRUCTION_2,
    USAGE,
    DEPTH,
    LI1,
    LI2,
    LI3DWB,
    LI4DWB,
    SP1,
    SP2,
    TLTT,
    FLANK,
    PR,
    RFD,
    SIZE_PREFIX,
    COMM_MARK,
    RIM_MM,
    RUN_FLAT,
    SIDEWALL,
    DESIGN_1,
    DESIGN_2,
    PRODUCT_TYPE,
    VEHICLE_TYPE,
    COND_GRP,
    TAX_ID,
    TAX,
    SUGGESTED_PRICE,
    GROSS_PRICE,
    GP_VALID_FROM,
    NET_VALUE,
    NV_VALID,
    RECYCLING_FEE,
    NOISE_PERFORMANCE,
    NOISE_CLASS_TYPE,
    ROLLING_RESISTANCE,
    WET_GRIP,
    EC_VEHICLE_CLASS,
    EU_DIRECTIVE_NUMBER,
    BUSINESS,
    ISWINTER
  }
} = tyreFormModel;



export default [

  Yup.object().shape({[EAN.name]:Yup.string()
  .required(`${EAN.requiredErrorMsg}`),

  [PROD_GRP_1.name]:Yup.string()
  .required(`${PROD_GRP_1.requiredErrorMsg}`),

 [SUPPLIER_CODE.name]:Yup.string()
  .required(`${SUPPLIER_CODE.requiredErrorMsg}`),

[DESCRIPTION_1.name]:Yup.string()
  .required(`${DESCRIPTION_1.requiredErrorMsg}`),

  /*[DESCRIPTION_2.name]:Yup.string()
  .required(`${DESCRIPTION_2.requiredErrorMsg}`),

   [PROD_INFO.name]:Yup.string()
  .required(`${PROD_INFO.requiredErrorMsg}`),

    [WDK_ANUB.name]:Yup.string()
  .required(`${WDK_ANUB.requiredErrorMsg}`),*/

  [WDK_BRAND.name]:Yup.string()
  .required(`${WDK_BRAND.requiredErrorMsg}`),

   [WDK_BRAND_TEXT.name]:Yup.string()
  .required(`${WDK_BRAND_TEXT.requiredErrorMsg}`),

  [BRAND.name]:Yup.string()
  .required(`${BRAND.requiredErrorMsg}`),

   [BRAND_TEXT.name]:Yup.string()
  .required(`${BRAND_TEXT.requiredErrorMsg}`),

[PROD_GRP_2.name]:Yup.string()
  .required(`${PROD_GRP_2.requiredErrorMsg}`),

[GROUP_DESCRIPTION.name]:Yup.string()
  .required(`${GROUP_DESCRIPTION.requiredErrorMsg}`),

[WEIGHT.name]:Yup.string()
  .required(`${WEIGHT.requiredErrorMsg}`),

[RIM_INCH.name]:Yup.string()
  .required(`${RIM_INCH.requiredErrorMsg}`),

 [PROD_CYCLE.name]:Yup.string()
  .required(`${PROD_CYCLE.requiredErrorMsg}`),

/*[THIRD_PARTY.name]:Yup.string()
  .required(`${THIRD_PARTY.requiredErrorMsg}`),
*/
[PL.name]:Yup.string()
  .required(`${PL.requiredErrorMsg}`),

[TEL.name]:Yup.string()
  .required(`${TEL.requiredErrorMsg}`),

[EDI.name]:Yup.string()
  .required(`${EDI.requiredErrorMsg}`),

[ADHOC.name]:Yup.string()
  .required(`${ADHOC.requiredErrorMsg}`),

/*[PL_ID.name]:Yup.string()
  .required(`${PL_ID.requiredErrorMsg}`),*/

[URL_1.name]:Yup.string()
  .required(`${URL_1.requiredErrorMsg}`),

 /*[URL_2.name]:Yup.string()
  .required(`${URL_2.requiredErrorMsg}`),

[URL_3.name]:Yup.string()
  .required(`${URL_3.requiredErrorMsg}`),

[URL_4.name]:Yup.string()
  .required(`${URL_4.requiredErrorMsg}`),

[URL_5.name]:Yup.string()
  .required(`${URL_5.requiredErrorMsg}`),*/

[WIDTH_MM.name]:Yup.string()
  .required(`${WIDTH_MM.requiredErrorMsg}`),

 /*[WIDTH_INCH.name]:Yup.string()
  .required(`${WIDTH_INCH.requiredErrorMsg}`),*/

[ASPECT_RATIO.name]:Yup.string()
  .required(`${ASPECT_RATIO.requiredErrorMsg}`),

/*[OVL_DIAMETER.name]:Yup.string()
  .required(`${OVL_DIAMETER.requiredErrorMsg}`),*/

[CONSTRUCTION_1.name]:Yup.string()
  .required(`${CONSTRUCTION_1.requiredErrorMsg}`),

/*[CONSTRUCTION_2.name]:Yup.string()
  .required(`${CONSTRUCTION_2.requiredErrorMsg}`),

[USAGE.name]:Yup.string()
  .required(`${USAGE.requiredErrorMsg}`),*/

[DEPTH.name]:Yup.string()
  .required(`${DEPTH.requiredErrorMsg}`),

[LI1.name]:Yup.string()
  .required(`${LI1.requiredErrorMsg}`),

/*[LI2.name]:Yup.string()
  .required(`${LI2.requiredErrorMsg}`),

[LI3DWB.name]:Yup.string()
  .required(`${LI3DWB.requiredErrorMsg}`),

[LI4DWB.name]:Yup.string()
  .required(`${LI4DWB.requiredErrorMsg}`),*/

[SP1.name]:Yup.string()
  .required(`${SP1.requiredErrorMsg}`),

/*[SP2.name]:Yup.string()
  .required(`${SP2.requiredErrorMsg}`),

[TLTT.name]:Yup.string()
  .required(`${TLTT.requiredErrorMsg}`),*/

[FLANK.name]:Yup.string()
  .required(`${FLANK.requiredErrorMsg}`),

 /*[PR.name]:Yup.string()
  .required(`${PR.requiredErrorMsg}`),

[RFD.name]:Yup.string()
  .required(`${RFD.requiredErrorMsg}`),

[SIZE_PREFIX.name]:Yup.string()
  .required(`${SIZE_PREFIX.requiredErrorMsg}`),

[COMM_MARK.name]:Yup.string()
  .required(`${COMM_MARK.requiredErrorMsg}`),

[RIM_MM.name]:Yup.string()
  .required(`${RIM_MM.requiredErrorMsg}`),

[RUN_FLAT.name]:Yup.string()
  .required(`${RUN_FLAT.requiredErrorMsg}`),

[SIDEWALL.name]:Yup.string()
  .required(`${SIDEWALL.requiredErrorMsg}`),*/

[DESIGN_1.name]:Yup.string()
  .required(`${DESIGN_1.requiredErrorMsg}`),

 [DESIGN_2.name]:Yup.string()
  .required(`${DESIGN_2.requiredErrorMsg}`),

[PRODUCT_TYPE.name]:Yup.string()
  .required(`${PRODUCT_TYPE.requiredErrorMsg}`),

[VEHICLE_TYPE.name]:Yup.string()
  .required(`${VEHICLE_TYPE.requiredErrorMsg}`),

/*[COND_GRP.name]:Yup.string()
  .required(`${COND_GRP.requiredErrorMsg}`),

[TAX_ID.name]:Yup.string()
  .required(`${TAX_ID.requiredErrorMsg}`),

[TAX.name]:Yup.string()
  .required(`${TAX.requiredErrorMsg}`),

[SUGGESTED_PRICE.name]:Yup.string()
  .required(`${SUGGESTED_PRICE.requiredErrorMsg}`),*/

[GROSS_PRICE.name]:Yup.string()
  .required(`${GROSS_PRICE.requiredErrorMsg}`),

[GP_VALID_FROM.name]:Yup.string()
  .required(`${GP_VALID_FROM.requiredErrorMsg}`),

/*[NET_VALUE.name]:Yup.string()
  .required(`${NET_VALUE.requiredErrorMsg}`),*/

[NV_VALID.name]:Yup.string()
  .required(`${NV_VALID.requiredErrorMsg}`),

[RECYCLING_FEE.name]:Yup.string()
  .required(`${RECYCLING_FEE.requiredErrorMsg}`),

[NOISE_PERFORMANCE.name]:Yup.string()
  .required(`${NOISE_PERFORMANCE.requiredErrorMsg}`),

[NOISE_CLASS_TYPE.name]:Yup.string()
  .required(`${NOISE_CLASS_TYPE.requiredErrorMsg}`),

[ROLLING_RESISTANCE.name]:Yup.string()
  .required(`${ROLLING_RESISTANCE.requiredErrorMsg}`),

[WET_GRIP.name]:Yup.string()
  .required(`${WET_GRIP.requiredErrorMsg}`),

[EC_VEHICLE_CLASS.name]:Yup.string()
  .required(`${EC_VEHICLE_CLASS.requiredErrorMsg}`),

[EU_DIRECTIVE_NUMBER.name]:Yup.string()
  .required(`${EU_DIRECTIVE_NUMBER.requiredErrorMsg}`),


/*[BUSINESS.name]:Yup.string()
  .required(`${BUSINESS.requiredErrorMsg}`),*/

[ISWINTER.name]:Yup.string()
  .required(`${ISWINTER.requiredErrorMsg}`),
})
];




