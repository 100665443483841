import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


import validationSchema from './FormModel/validationSchema';
import conventionFormModel from './FormModel/conventionFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonConventionData from './Forms/CommonConventionData';
import AwardsJoined from './Forms/AwardsJoined';
import ResellersJoined from './Forms/ResellersJoined';
import TyresJoined from './Forms/TyresJoined';
import {downloadUploadService} from "../../../services/downloadUpload.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, tyresData,setTyresData,tyresDataInPromo,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let tyresToAdd=[];
  // renderCell: (data) => {return moment().format("")}
  const { formId, formField } = conventionFormModel;
  const [isUploading, setIsUploading] = React.useState(false);
  let [hasError, setHasError] = React.useState({
    value: false,
    description:''
  });




  const handleTyreChange = (selectionModel) => {
    let isChanged= false;
    let tempTyre = [...tyresData];

    tempTyre.forEach(function (item, index) {
      const checkChecked = selectionModel.findIndex((s => s === item.idTyre.toString()));
      if (checkChecked===-1)
      {
        if (tempTyre[index].tyreSelected === true)
        {
          tempTyre[index].tyreSelected = false;
          isChanged = true;
        }
      }
      else
      {
        if (tempTyre[index].tyreSelected === false)
        {
          tempTyre[index].tyreSelected = true;
          isChanged = true;
        }
      }
    });
    if (isChanged)
    {
      setTyresData(tempTyre);
    }
  };

const salva=async ()=>{
  tyresToAdd=[]
  tyresData.map((item, index) =>{
      if(item.tyreSelected){
        tyresToAdd.push(item.idTyre);
      }
  });
  tyresDataInPromo.map((item,index)=>{

    tyresToAdd.map((item1,index1)=>{
      if(item.idTyre==item1){
        tyresToAdd.splice(index1, 1);
      }
    })
  });

  await promoService.addListTyreInPromoTyre(idCampaign,tyresToAdd).then((data)=>{

    if(data==1) {
      const { value: newplafond } =  Swal.fire({
        title: 'Tyres aggiunti' ,
        showCancelButton: false,
      }).then((ret) =>{
        navigate("/backoffice/campaigns/tyre/"+idCampaign);
      })


    }else{
      const { value: newplafond } =  Swal.fire({
        title: "Errore nell'aggiunta dei tyres" ,
        showCancelButton: false,

      })
    }
    });
}



  const downloadModel = (Area, Type) => {
    downloadUploadService.DownloadModel("Convention", "Tyres").then((data) => {
    });
  }

  const uploadModel = (Area, Type, file) => {
    setHasError({ value: false, description: '' });
    setIsUploading(true);
    downloadUploadService.UploadModel(idCampaign,
      Area,
      Type,
      file).then(
      (data) => {
        const promoTyres = data.map(
          promoTyre => {
            return {
              id: promoTyre?.idTyre,
              idTyre: promoTyre?.idTyre,
              supplierCode: promoTyre?.idTyreNavigation?.supplierCode,
              description1: promoTyre?.idTyreNavigation?.description1,
              brand: promoTyre?.idTyreNavigation?.brand,
              design1: promoTyre?.idTyreNavigation?.design1,
              design2: promoTyre?.idTyreNavigation?.design2,
              tyreSelected: promoTyre?.tyreSelected,
            }
          }
        );
        setHasError({ value: false, description: '' });
        setTyresData(promoTyres);
      },

      error => {
        setHasError({ value: true, description: error });
        alert(error);
      });
    setIsUploading(false);

  }



  return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
    <TyresJoined
      formField={formField}
      conventionId={idCampaign}
      TyresData={tyresData}
      HandleTyreChange={handleTyreChange}
      DownloadModel={downloadModel}
      UploadModel={uploadModel}
      IsUploading={isUploading} />
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={salva}
    >
     Salva
    </Button>
  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  tyresData: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
