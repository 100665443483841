import configData from './config.json';
import {API_URL, authHeader, handleResponse} from '../helpers';


export const promoService = { 
    GetAllCampaign,
    GetCampaignById,
    GetPromoTyres,
    GetEligiblePromoReseller,
    GetEligiblePromoAward,
    GetEligiblePromoTyre,
  GetPromoReseller,
  GetPromo,
  GetAllPromo,
  UpdatePlafondReseller,
  GetTyreByPromoId,
  GetProspectByPromoId,
  GetAwardByPromoId,
  GetPromoAttributeByIdPromo,
  GetEmailByIdPromo,
  GetFormByIdPromo,
  GetTyreAsAwardByPromoId,
  DeletePromoTyreByIdPromoTyre,
  GetTyreNotInPromo,
  AddTyreInPromoTyre,
  addListTyreInPromoTyre,
  GetResellerNotInPromo,
  AddResellerInPromoReseller,
  DeletePromoResellerByIdPromoReseller,
  addListResellerInPromoReseller,
  updatePromoForEditPromo,
  addEndUpdatePromoAWard,
  deletePromoAwardByIdPromoAward,
  DeletePromoAttributeByIdPromoAttribute,
  addPromoAttribute,
  modifyPromoAttribute,
  modifyPromoForm,
  updatePromoEmail,
  DeletePromoTyreAsAwardByIdPromoTyreAsAward,
  GetTyreAwardNotInPromo,
  AddTyreAwardInPromoTyreAsAward,
  GetEligiblePromoTyreAsAward,
  addListTyreAwardInPromoTyreAsAward,
  updateListReseller,
  GetTyre,
  GetTyreIdTyre,
  createTyre,
  updateTyre,
  createPromo,
  addPromoForm,
  addPromoEmail,
  deletePromoEmail,
  deletePromoForm
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();


function GetAllCampaign(OnlyOpenView) {
        return guardedPromise("GetAllCampaign", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/Campaign?OnlyOpenView=`+OnlyOpenView, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response for campaigns");
            })
            .catch(error => reject(error));
    }));
}

function GetAllPromo() {
  return guardedPromise("GetAllPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/GetAllPromo`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}

//simone
function GetTyreNotInPromo(idPromo) {
  return guardedPromise("GetTyreNotInPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/GetTyreNotInIdPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetTyreAwardNotInPromo(idPromo) {
  return guardedPromise("GetTyreAwardNotInPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/GetTyreAwardNotInIdPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}




//simone
function GetResellerNotInPromo(idPromo) {
  return guardedPromise("GetResellerNotInPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/getResellerNotInPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function AddTyreInPromoTyre(idPromo,idTyre) {
  return guardedPromise("AddTyreInPromoTyre", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/Promo/addTyreInPromoTyre?IdPromo=`+idPromo+"&&IdTyre="+idTyre, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function AddTyreAwardInPromoTyreAsAward(idPromo,idTyre) {
  return guardedPromise("AddTyreAwardInPromoTyreAsAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/Promo/addTyreAwardInPromoTyreAsAward?IdPromo=`+idPromo+"&&IdTyre="+idTyre, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}



//simone
function AddResellerInPromoReseller(idPromo,idReseller) {
  return guardedPromise("AddResellerInPromoReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/Promo/addResellerInPromoReseller?idPromo=`+idPromo+"&&idReseller="+idReseller, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetProspectByPromoId(idPromo) {
  return guardedPromise("GetProspectByPromoId", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/promo/GetProspectInIdPromo?IdPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetTyreByPromoId(idPromo) {
  return guardedPromise("GetTyreById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Tyre/GetTyreByIdPromo?IdPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}

//simone
function GetAwardByPromoId(idPromo) {
  return guardedPromise("GetAwardById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Award/getAwardByIdPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}

//simone
function GetTyreAsAwardByPromoId(idPromo) {
  return guardedPromise("GetTyreAsAwardById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/getTyreAsAwardByIdPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetPromoAttributeByIdPromo(idPromo) {
  return guardedPromise("GetPromoAttribute", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/getPromoAttributeFromIdPromo?IdPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetEmailByIdPromo(idPromo) {
  return guardedPromise("GetEmailByIdPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/getEmailByIdPromo?IdPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}



//simone
function GetFormByIdPromo(idPromo) {
  return guardedPromise("GetFormByIdPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/getFormByIdPromo?IdPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for campaigns");
      })
      .catch(error => reject(error));
  }));
}




function GetCampaignById(CampaignId) {
    return guardedPromise("GetCampaignById", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/campaign/`+CampaignId, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) { 
                return resolve(responseData);
            }
            reject("Empty Response for campaigns");
        })
        .catch(error => reject(error));
}));
}

function GetPromoTyres(idPromo) {
    return guardedPromise("GetPromoTyres", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };

        fetch(`${API_URL}/Promo/${idPromo}/Tyres`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) {
                    return resolve(responseData);
                }
                reject("Empty Response for promo");
            })
            .catch(error => reject(error));
    }));
}

//simone
function DeletePromoTyreByIdPromoTyre(idPromoTyre) {
  return guardedPromise("DeletePromoTyre", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/DeletePromoTyreByIdPromoTyre?IdPromoTyre=`+idPromoTyre, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}

//simone
function DeletePromoTyreAsAwardByIdPromoTyreAsAward(idPromoTyreAsAward) {
  return guardedPromise("DeletePromoTyreAsAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/DeletePromoTyreAsAwardByIdPromoTyreAsAward?IdPromoTyreAsAward=`+idPromoTyreAsAward, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}




function DeletePromoAttributeByIdPromoAttribute(idPromoAttribute) {
  return guardedPromise("DeletePromoAttribute", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/deletePromoAttributeByIdPromoAttribute?IdPromoAttribute=`+idPromoAttribute, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



function DeletePromoResellerByIdPromoReseller(idReseller,idPromo) {
  return guardedPromise("DeletePromoReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/deletePromoResellerByIdReseller?idReseller=`+idReseller+"&&idPromo="+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}







function GetEligiblePromoAward(idPromo) {
    return guardedPromise("GetEligiblePromoAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/promo/utility/eligible-promo-award/${idPromo}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for promo");
        })
        .catch(error => reject(error));
}));
}
function GetEligiblePromoReseller(idPromo) {
    return guardedPromise("GetEligiblePromoReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    fetch(`${API_URL}/promo/utility/eligible-promo-reseller/${idPromo}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for promo");
        })
        .catch(error => reject(error));
}));
}

function GetPromoReseller(idPromo) {
  return guardedPromise("GetPromoReseller", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/promo/utility/reseller/${idPromo}`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}
function GetPromo(idPromo) {
  return guardedPromise("GetPromo", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/promo/utility/promo/${idPromo}`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}
function GetEligiblePromoTyre(idPromo) {
    return guardedPromise("GetEligiblePromoTyre", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/promo/utility/eligible-promo-tyre/${idPromo}`, requestOptions)
        .then(handleResponse)
        .then(responseData => {
            if (responseData) {
                return resolve(responseData);
            }
            reject("Empty Response for promo");
        })
        .catch(error => reject(error));
}));
}


//simone
function GetEligiblePromoTyreAsAward(idPromo) {
  return guardedPromise("GetEligiblePromoTyreAsAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch(`${API_URL}/promo/utility/eligible-promo-tyreAsAward/${idPromo}`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



function UpdatePlafondReseller(idPromo,data) {

  return guardedPromise("UpdatePlafondReseller", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Campaign/SetPlafon/`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for reseller");
      })
      .catch(error => reject(error));
  }));
}


//simone
function addListTyreInPromoTyre(idPromo,data) {

  return guardedPromise("addListTyreInPromoTyre", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addListIdTyreInPromoTyre?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for reseller");
      })
      .catch(error => reject(error));
  }));
}



//simone
function addListTyreAwardInPromoTyreAsAward(idPromo,data) {

  return guardedPromise("addListTyreAwardInPromoTyreAsAward", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addListIdTyreAwardInPromoTyreAward?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for reseller");
      })
      .catch(error => reject(error));
  }));
}





//simone
function addListResellerInPromoReseller(idPromo,data) {

  return guardedPromise("addListResellerInPromoReseller", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addListIdResellerInPromoReseller?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for reseller");
      })
      .catch(error => reject(error));
  }));
}


//simone
function updatePromoForEditPromo(idPromo,data) {
  return guardedPromise("updatePromoForEditPromo", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/updatePromoForEditPromo?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}

//simone
function addEndUpdatePromoAWard(idPromo,data) {
  return guardedPromise("updatePromoForEditPromo", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addEndUpdatePromoAWard?idPromo=`+idPromo, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}

function deletePromoAwardByIdPromoAward(idPromoAward) {
  return guardedPromise("deletePromoAwardByIdPromoAward", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/deletePromoAwardByIdPromoAward?idPromoAward=`+idPromoAward, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



//simone
function addPromoAttribute(data) {

  return guardedPromise("addPromoAttribute", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addPromoAttribute`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



//simone
function modifyPromoAttribute(data) {

  return guardedPromise("modifyPromoAttribute", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/modifyPromoAttribute`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}

//simone
function modifyPromoForm(data) {

  return guardedPromise("modifyPromoForm", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/modifyPromoForm`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}




//simone
function addPromoForm(data) {

  return guardedPromise("addPromoForm", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addPromoForm`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



//simone
function addPromoEmail(data) {

  return guardedPromise("addPromoEmail", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addPromoEmail`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}




//simone
function updatePromoEmail(data) {

  return guardedPromise("updatePromoEmail", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/updatePromoEmail`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



//simone
function updateListReseller(data) {
  return guardedPromise("updateListReseller", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/updateListReseller`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for updateReseller");
      })
      .catch(error => reject(error));
  }));
}



//simone
function GetTyre() {
  return guardedPromise("GetTyre", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Tyre`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for tyre");
      })
      .catch(error => reject(error));
  }));
}


//simone
function GetTyreIdTyre(idTyre) {
  return guardedPromise("GetTyre", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Tyre/${idTyre}`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for tyre");
      })
      .catch(error => reject(error));
  }));
}


//simone
function updateTyre(data) {
  return guardedPromise("updateTyre", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Tyre/updateTyre`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for tyre");
      })
      .catch(error => reject(error));
  }));
}



//simone
function createTyre(data) {
  return guardedPromise("createTyre", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Tyre/addTyre`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for tyre");
      })
      .catch(error => reject(error));
  }));
}


//simone
function createPromo(data) {
  return guardedPromise("createPromo", () => new Promise((resolve, reject) => {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',  ...authHeader()},
      body: JSON.stringify(data)
    };

    fetch(`${API_URL}/Promo/addPromo`, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));

}


//simone
function deletePromoEmail(idPromoEmail) {
  return guardedPromise("DeletePromoEmail", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/deletePromoEmail?idPromoEmail=`+idPromoEmail, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}



//simone
function deletePromoForm(idPromoForm) {
  return guardedPromise("Delete PromoForm", () => new Promise((resolve, reject) => {
    const requestOptions = { method: 'GET', headers: authHeader() };

    fetch(`${API_URL}/Promo/deletePromoForm?idPromoForm=`+idPromoForm, requestOptions)
      .then(handleResponse)
      .then(responseData => {
        if (responseData) {
          return resolve(responseData);
        }
        reject("Empty Response for promo");
      })
      .catch(error => reject(error));
  }));
}
