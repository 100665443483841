import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link
} from '@material-ui/core';


import moment from 'moment';
import { Formik, Form } from 'formik';

import { useParams, useNavigate } from 'react-router-dom';

import validationSchema from './FormModel/validationSchema';
import campaignFormModel from './FormModel/campaignFormModel';
import formInitialValues from './FormModel/formInitialValues';


import CommonCampaignData from './Forms/CommonCampaignData';
// import DossierAwards from './Forms/DossierAwards';  
// import ResellersJoined from './Forms/Resellers';
// import TyresJoined from './Forms/Tyres'; 
// import ReviewConvention from './Forms/ReviewConvention'; 


import { promoService } from '../../../services/promo.service';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errors: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
}));

const steps = ['General', 'Awards', 'Reseller', 'Tyre', 'Riepilogo e conferma'];
const { formId, formField } = campaignFormModel;


function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <CommonCampaignData formField={formField} />;
    // case 1:
    //   return <Awards formField={formField} />;
    // case 2:
    //   return <ResellerJoined formField={formField} />;
    // case 3:
    //  return <Tyres formField={formField} />;
    // case 4:
    //   return <Review formField={formField}/>;
    default:
      return <div>Not Found</div>;
  }
}

export default function AddEditConvention() {
  const navigate = useNavigate();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  let [campaign, setCampaign] = useState({});

  let [hasError, setHasError] = React.useState({
    value: false,
    description: ''
  });

  useEffect(() => {
    if (!isAddMode) {
      promoService.GetCampaignById(CampaignId).then(data => {
        console.log(data);
        setCampaign({ awardName: data.awardName, awardDescription: data.awardDescription });
      });
    }
    else {

    }

  }, [])


  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _handleSubmit(values, actions) {

    actions.setSubmitting(true);
    if (isLastStep) {
      _submitForm(values, actions);
    }
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _submitForm(fields, { setStatus, setSubmitting }) {
    // setStatus();
    if (isAddMode) {
      createCampaign(fields, setSubmitting);
    } else {
      updateCampaign(CampaignId, fields, setSubmitting);
    }
  }

  function createCampaign(fields, setSubmitting) {
    promoService.CreateCampaign(fields)
      .then(() => {
        setSubmitting(false);
        // alertService.success('Award added', { keepAfterRouteChange: true });
        setActiveStep(activeStep + 1);
      })
      .catch(error => {
        setSubmitting(false);
      });
  }

  function updateCampaign(id, fields, setSubmitting) {
    promoService.UpdateCampaign(id, fields)
      .then(() => {
        setSubmitting(false);
        // alertService.success('Award added', { keepAfterRouteChange: true });
        setActiveStep(activeStep + 1);
      })
      .catch(error => {
        setSubmitting(false);
      });
  }

  function _endFlow() {
    if (isAddMode) {
      navigate('..', { replace: false });
    } else {
      navigate('../..', { replace: false });
    } 
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  CAMPAIGN SAVED
                        </Typography>
                <div className={classes.wrapper}>
                  <Button
                    onClick={_endFlow}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}>CLOSE
                          </Button>
                </div>
              </React.Fragment>
            ) : (
              <Formik
                //  initialValues={{
                //   ['AwardName']: award.awardName,
                //   ['AwardDescription']: award.awardDescription 
                // }}
                initialValues={formInitialValues}
                enableReinitialize={true}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form id={formId}>

                    {_renderStepContent(activeStep)}

                    <div className={classes.buttons}>

                      {hasError.value && (
                        <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                      )}

                      {activeStep !== 0 && (
                        <Button onClick={_handleBack} className={classes.button}>
                          Precedente
                        </Button>
                      )}

                      <div className={classes.wrapper}>


                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {isLastStep ? 'Confirm' : 'Next'}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
}
