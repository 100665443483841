import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignAwardList = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);

  const [awardData, setAwardData] = useState([]);
  const navigate = useNavigate();
  var dict = [];
  const [currentUser, setCurrentUser] = useState();


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;

    promoService.GetPromo(CampaignId).then(data => {
      setCampaignsSingleData(data);
    });


    promoService.GetAwardByPromoId(CampaignId).then((data) => {
      data.map((item, index) => data[index].id=item.idPromoAward);
      data.map((item,index)=>dict.push({"id":item.idPromoAward, "idAward": item.idAward, "awardDescription":item.idAwardNavigation.awardDescription, "awardName": item.idAwardNavigation.awardName,
      "awardValue":item.awardValue}));
      setAwardData(dict);
    });

  }, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}

        <Box mt={3}>
          <Results award={awardData} idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignAwardList;


   
