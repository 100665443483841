import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';



import { authenticationService } from '../../services/authentication.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  let [forgotPassword, setForgotPassword] = useState({
    userName: '',
    email: '' 
  });

  let [isSubmitting, setIsSubmitting] = useState(false); 
  
  let [hasError, setHasError] = useState({
    value: false,
    description:''
  });

  let [isOk, setIsOk] = useState({
    value: false,
    description:''
  });

  
  let handleSubmit = (event) => {
    setIsSubmitting(true);
    event.preventDefault(); 
    authenticationService.validate(forgotPassword.userName, forgotPassword.email)
        .then(
            user => {
              console.log('validation  ok'); 
              setIsSubmitting(false);
              setIsOk({value:true, description: 'Controlla la tua e-mail, abbiamo spedito le istruzioni per il recupero della password'});
              
              // navigate('/', { replace: true });
            },
            error => {
              setHasError({value:true, description: error});
              console.log('errore', hasError.value, hasError.description);
              setIsSubmitting(false);
            }
        );
        
    
  }

  let handleChange = (e) => { 
    let name = e.target.name;
    let value = e.target.value;
    forgotPassword[name] = value;
    setForgotPassword(forgotPassword); 
  }

  return (
    <Page
      className={classes.root}
      title="Password dimenticata"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Password dimenticata
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Inserisci il tuo codice SAP e la tua email per ricevere le istruzioni
                  </Typography>
                </Box> 
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="userName"
                    label="Username (Codice SAP)"
                    name="userName"
                    autoComplete="userName"
                    autoFocus
                    onChange={handleChange}
                  />
                <TextField                  
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"                  
                  name="email"
                  onChange={handleChange}
                  type="email"
                /> 
                 {hasError.value && (
                      <div className="error">{hasError.description}</div>
                    )}
                    {isOk.value && (
                      <div className="error">{isOk.description}</div>
                    )}
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >  
                </Box> 
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Invia
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Hai un accout?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Accedi
                  </Link>
                </Typography>
              </form>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPassword;
