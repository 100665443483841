import React, { useEffect, useState } from 'react';
import {
  Box, Button,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {authenticationService} from "../../../services/authentication.service";
import {useNavigate} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignListView = () => {
  const classes = useStyles();
  const [campaignsData, setCampaignsData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user);
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;
    promoService.GetAllCampaign(false).then((data) => {
      data.map((item, index) => data[index].id=item.idPromo);
      setCampaignsData(data);
    });
  }, [currentUser])

  const goToCreate=()=>{
    navigate('promoCreate', { replace: false })
  }

  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToCreate}
        >
          Create Campagne
        </Button>
        {/* <Toolbar />*/}
        <Box mt={3}>
          <Results campaigns={campaignsData} />
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignListView;


   
