import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, form,setFormData,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}



  const headCells = [
    {field: 'id', headerName: 'idPromoForm',  width: 150, description:'The internal idPromoForm.'},
    {field: 'json',   headerName: 'json',  width: 300, description:'The internal json.',renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyPromoForm(params.row.json,params.row.id, params)}}
          > {params.row.json}
          </Button>
        </div>
      ), },
    {field: 'Cancella', headerName: 'Cancella', width: 150, description:'Cancella PromoForm',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancForm(params.row.id) }}
          >Cancella
          </Button>
        </div>
      ), },

  ];


  let modifyPromoForm = async ( json ,idPromoForm, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo JSON',
      inputValue: json,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( async (ret) =>{
      if (ret.isConfirmed){
        if(isJson(ret.value)) {
          p.row.json = ret.value;
          let newJson=ret.value.toString();
          newJson=JSON.stringify(JSON.parse(newJson))
          //newJson=JSON.parse(newJson)
          //let dict = []
          //dict.push({"id": idPromoForm, "json": ret.value})
          //setFormData(dict)
          let dati={}
          dati.idPromoForm=idPromoForm
          dati.idPromo=idCampaign
          dati.json=newJson
          await promoService.modifyPromoForm(dati).then((data)=>{

            if(data==1) {
              const { value: newplafond } =  Swal.fire({
                title: 'PromoForm modificato' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })




            }else if(data==-1){
              const { value: newplafond } =  Swal.fire({
                title: "PromoForm da modificare non trovato" ,
                showCancelButton: false,

              })
            }else{
              const { value: newplafond } =  Swal.fire({
                title: "Errore nella modifica del PromoForm" ,
                showCancelButton: false,

              })
            }
          });

        }else{
          const { value: newplafond } =  Swal.fire({
            title: "Non è un file JSON" ,
            showCancelButton: false,

          })
        }

      }
    })
  }

  let addPromoForm = async () => {

    Swal.fire({
      showCancelButton:true,
      title:"Add PromoForm for Promo "+idCampaign,
      html:`Json:<input id="json" type="text">`,

      preConfirm: async function() {
        let json= document.getElementById('json').value;
        if(json=="" || !isJson(json)){
          const { value: newplafond } =  Swal.fire({
            title: 'Inserire un json valido' ,
            showCancelButton: false,
          })
        }else{
          json=JSON.stringify(JSON.parse(json))
          let dati={}
          dati.idPromo=idCampaign
          dati.json=json
          await promoService.addPromoForm(dati).then((data) => {
            if(data==1) {
              const { value: newplafond } =  Swal.fire({
                title: 'PromoForm aggiunto' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })
            }
            else if(data==-1) {
              const {value: newplafond} = Swal.fire({
                title: "PromoForm già esistente per questa campagna",
                showCancelButton: false,

              })
            }else{
              const { value: newplafond } =  Swal.fire({
                title: 'Errore' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })

            }


          });




        }
      }
    })

  }





  let cancForm= async (idPromoForm)=>{

    await promoService.deletePromoForm(idPromoForm).then(data => {
      if(data==1) {
        const {value: newplafond} = Swal.fire({
          title: 'PromoForm Cancellato',
          showCancelButton: false,
        }).then((ret) => {
          window.location.reload();
        })

      }else{
        const { value: newplafond } =  Swal.fire({
          title: "Errore nella cancellazione del PromoForm" ,
          showCancelButton: false,

        })
      }


    });
  }






  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {addPromoForm()}}
          >
            Add PromoForm
          </Button>
        </div>
        <div id='TableInCampaign'>
      <DataGrid
          columns={headCells}
          rows={form}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  form: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
