import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {conventionService} from "../../../services/convention.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignTyreAsAwardEdit = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const navigate = useNavigate();
  var dict = [];
  const [currentUser, setCurrentUser] = useState();

  let [tyresData, setTyresData] = useState([]);
  let [tyresDataInPromo, setTyresDataInPromo] = useState([]);
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])



  useEffect(() => {

    if (!currentUser) return;
    if (!(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) return;



    promoService.GetTyreAsAwardByPromoId(CampaignId).then((data) => {
      data.map((item, index) => data[index].id=item.idPromoTyreAsAward);
      data.map((item,index)=>dict.push({"id":item.idPromoTyreAsAward, "idTyre": item.idTyreNavigation.idTyre}));
      setTyresDataInPromo(dict);
    });


    promoService.GetPromo(CampaignId).then(data => {
      setCampaignsSingleData(data);
    });


  promoService.GetEligiblePromoTyreAsAward(CampaignId)
    .then((data) => {
      const promoTyres = data.map(
        promoTyre => {
          return {
            id: promoTyre?.idTyre,
            idTyre: promoTyre?.idTyre,
            supplierCode: promoTyre?.idTyreNavigation?.supplierCode,
            description1: promoTyre?.idTyreNavigation?.description1,
            brand: promoTyre?.idTyreNavigation?.brand,
            design1: promoTyre?.idTyreNavigation?.design1,
            design2: promoTyre?.idTyreNavigation?.design2,
            tyreSelected: promoTyre?.tyreSelected==true?true:false,
          }
        }
      );
      setTyresData(promoTyres);
    });


}, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}

        <Box mt={3}>
          <Results tyresData={tyresData} setTyresData={setTyresData} tyresDataInPromo={tyresDataInPromo} idCampaign={parseInt(CampaignId)}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignTyreAsAwardEdit;


   
