import * as Yup from 'yup';
import moment from 'moment';
import awardFormModel from './awardFormModel';
const {
  formField: {
    AwardName,
    AwardDescription 
  }
} = awardFormModel;



export default [
  Yup.object().shape({
    [AwardName.name]: Yup.string()
          .required(`${AwardName.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [AwardDescription.name]: Yup.string()
          .required(`${AwardName.requiredErrorMsg}`),
  })
];




