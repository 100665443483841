import React,  { useState }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {at} from 'lodash';

import {
  Avatar,
  Card,
  colors,
  CardContent,
  makeStyles,
  withStyles,
  Grid,
  Box,
  Typography,
  InputAdornment
} from '@material-ui/core';
 
import AccountCircle from '@material-ui/icons/AccountCircle';
import ImageIcon from '@material-ui/icons/Image';
import CodeIcon from '@material-ui/icons/Code';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';




import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';




const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },
  grid: {
    marginTop: theme.spacing(6)
  },
  // toggleButtonGroup: {
  //   padding: theme.spacing(1),
  //   margin: theme.spacing(1),
  //   '&:not(:first-child)': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  //   '&:first-child': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  // }, 
  // toggleButton: {
  //   padding: theme.spacing(1),
  //   margin: theme.spacing(2),
  //   borderWidth: 2,
  //   borderColor: colors.common.black,
  //   '&:not(:first-child)': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  //   '&:first-child': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  // },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
      borderColor: colors.common.black,
      borderWidth: 2,

    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
      borderColor: colors.common.black,
      borderWidth: 2,
    },
    '&:selected': {
      borderRadius: theme.shape.borderRadius,
      borderColor: colors.common.black,
      borderWidth: 2,
    },
  },
}))(ToggleButtonGroup);


export default function AwardSelection(props) {
  const {
    formField: { 
      VoucherCode,
      IdPromo,
      LongNamePromoAttribute,
      PromoExternalName,    
      IdProspect,
      ProspectName
    },
      awardList,
      award,
      onToggle,
  } = props;

  const classes = useStyles();    
  const navigate = useNavigate();

  console.log('asdasdasdasdas');

  console.log(awardList);
  const groupAwards = awardList.reduce((group, awardDescription) => {
    group[awardDescription.awardDescription] = group[awardDescription.awardDescription] ?? [];
    group[awardDescription.awardDescription].push(awardDescription);
    return group;
  }, {});
  console.log(groupAwards);


  return (
    <Grid item xs={12} md={12} spacing={5}>
    <Typography variant="h5" className={classes.typografy}>
      VALORE VOUCHER
    </Typography>
    <Grid container spacing={3} className={classes.grid}>


     <Grid item xs={12} sm={6}> 
     <InputField
            name={LongNamePromoAttribute.name}
            label={LongNamePromoAttribute.label}
            fullWidth  
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <ImageIcon />
            </InputAdornment>
          ),
            readOnly: true,
          }}
          variant="outlined"/> 
       </Grid>

     <Grid item xs={12} sm={6}> 
<InputField
       name={PromoExternalName.name}
       label={PromoExternalName.label}
       fullWidth
       variant="outlined"
     InputProps={{startAdornment: (
       <InputAdornment position="start">
         <ImageIcon />
       </InputAdornment>
     ),
       readOnly: true,
     }}
     /> 
</Grid>

<Grid item xs={12} sm={6}> 
     <InputField
            name={VoucherCode.name}
            label={VoucherCode.label}
            fullWidth
            variant="outlined"
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <CodeIcon />
            </InputAdornment>
          ),
            readOnly: true,
          }}
          /> 
     </Grid> 

     
     <Grid item xs={12} sm={6}> 
     <InputField
            name={ProspectName.name}
            label={ProspectName.label}
            fullWidth
            variant="outlined"
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
            readOnly: true,
          }}
          /> 
     </Grid>




  

     <Grid item xs={12} md={12} spacing={5}></Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" gutterBottom>
            SELEZIONARE IL PREMIO PER IL QUALE SI DESIDERA CREARE LA PRATICA
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>


            {Object.keys(groupAwards).map(key => {
              return (
                <div><h2>{key}</h2>
                <StyledToggleButtonGroup
                  value={award}
                  exclusive
                  onChange={onToggle}
                  className={classes.toggleButtonGroup}
                >

                  {groupAwards[key].map((al) => (
                    <ToggleButton value={al.idPromoAward}
                                  className={classes.toggleButton}
                    >
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          {al.awardName}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                        >
                          {al.awardValue} €
                        </Typography>
                      </Grid>
                    </ToggleButton>
                  ))}

                </StyledToggleButtonGroup>
                </div>
              );
            })}



    </Grid>
    </Grid>
    </Grid>
  );
}


