import conventionFormModel from './conventionFormModel';
const {
  formField: {
    PromoInternalName,
    PromoExternalName, 
    CompanyConventionCodePromoAttribute,
    CompanyNameConventionPromoAttribute,
    CompanyEmailDomainConventionPromoAttribute,
    StartVoucherRequestPromo,
    EndVoucherRequestPromo,
    EndVoucherRedemeedPromo,
    EndVoucherRedemeedFirstopPromo,
    StartViewPromo,
    EndViewPromo,
    EndPracticeRbo
  }
} = conventionFormModel;
 
export default {
  [PromoInternalName.name]: '',
  [PromoExternalName.name]: '',
  [CompanyConventionCodePromoAttribute.name]: '',
  [CompanyNameConventionPromoAttribute.name]: '',
  [CompanyEmailDomainConventionPromoAttribute.name]: '',
  [StartVoucherRequestPromo.name]: '',
  [EndVoucherRequestPromo.name]: '',
  [EndVoucherRedemeedPromo.name]: '',
  [EndVoucherRedemeedFirstopPromo.name]: '',
  [StartViewPromo.name]: '',
  [EndViewPromo.name]: '',
  [EndPracticeRbo.name]: '',
};

 