import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";
import {resellerService} from "../../../services/reseller.service";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const CampaignResellerList = () => {
  const classes = useStyles();
  const { CampaignId } = useParams();
  const isAddMode = !CampaignId;
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignsSingleData, setCampaignsSingleData] = useState([]);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();


  if (isAddMode){
    navigate('/', { replace: false });
  }


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])

  useEffect(() => {


    /*promoService.GetPromoReseller(CampaignId).then((data) => {
      data.map((item, index) =>{
        data[index].id=item.idReseller
        data[index].sapCode=parseInt(item.idResellerNavigation.sapCode)
        data[index].shipToCode=parseInt(item.idResellerNavigation.shipToCode)
        data[index].dealer=item.idResellerNavigation.name
      });
      console.log("getPrompoReseller")
      console.log(data)
      setCampaignsData(data);
    });*/
    promoService.GetPromo(CampaignId).then(data => {
      console.log("getPromo")
      console.log(data)
      setCampaignsSingleData(data);
    });


  }, [currentUser]);
  return (
    <Page
      className={classes.root}
      title="Campaign"
    >
      <Container maxWidth={false}>
        { <Toolbar campaign={campaignsSingleData} />}
        <Box mt={3}>
          <Results campaigns={campaignsData} idCampaign={parseInt(CampaignId)} currentUser={currentUser}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignResellerList;


   
