import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';


 
import Avatar from '@material-ui/core/Avatar'; 
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

 


import { authenticationService } from '../../services/authentication.service';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="www.bridgestone.eu">
        Bridgestone
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
 
const useStyles = makeStyles((theme) => ({ 
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(static/images/home.jpg)` ,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2) 
  }
}));

    const LoginView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    
    let [account, setAccount] = useState({
      username: '',
      password: '' 
    });

    
    let [hasError, setHasError] = useState({
      value: false,
      description:''
    });

    
    let handleSubmit = (event) => {
      event.preventDefault();
      console.log(account.username);
      console.log(account.password);
      authenticationService.login(account.username, account.password)
          .then(
              user => {
                console.log('login ok');
                //   const { from } = this.props.location.state || { from: { pathname: "/" } };
                //   this.props.history.push(from);
                navigate('/', { replace: true });
              },
              error => {
                setHasError({value:true, description: error});
                console.log('errore', hasError.value, hasError.description);
              }
          );
    }

    let handleChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      account[name] = value;
      setAccount(account);
      console.log(account);
    }

    return ( 
  <Page>
  <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username (Codice SAP)"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    // value={this.state.username}
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    // value={this.state.password}
                    onChange={handleChange}

                    helperText=''
                    
                  />
              
                    {hasError.value && (
                      <div className="error">{hasError.description}</div>
                    )}

                  
          
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Login
                  </Button>

                
                  <Box mt={5}>
                
                  </Box>

                  <Grid container>
                    <Grid item xs>
                    <Link
                        component={RouterLink}
                        to="/forgot-password"
                        variant="h6"
                        >
                          Password dimenticata?
                          {' '}
                          </Link>


                      


                    </Grid>
                  </Grid>


                  <Grid container>
                    <Grid item xs>
                    <Link
                        component={RouterLink}
                        to="/first-login"
                        variant="h6"
                        >
                          Primo accesso o password scaduta?
                          {' '}
                          </Link>
                      </Grid>
                  </Grid>



                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>

      </Page>
    );
};

export default LoginView;
