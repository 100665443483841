import React, { useState, useEffect } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

function AutocompleteField(props) {
  const { label, data, ...rest } = props;
  const [field, meta, helper] = useField(props);  
  const { value } = field;

  const { setValue } = helper;
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <Autocomplete
      name={field.name}
      options={data}
      value={selectedValue}
      getOptionLabel={(option) => option.description}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      renderInput={(params) => <TextField
        {...params}
        helperText={_renderHelperText()}
        error={meta.touched && meta.error && true}
        label={label} 
        variant="outlined"        
        inputProps={{
          ...params.inputProps
      }} />}
    />
  );
}

AutocompleteField.defaultProps = {
  data: []
};

AutocompleteField.propTypes = {
  data: PropTypes.array.isRequired
};

export default AutocompleteField;