import * as Yup from 'yup';
import moment from 'moment';
import conventionFormModel from './conventionFormModel';
const {
  formField: {
    PromoInternalName,
    PromoExternalName,
    CompanyConventionCodePromoAttribute,
    CompanyNameConventionPromoAttribute,
    CompanyEmailDomainConventionPromoAttribute,
    StartVoucherRequestPromo,
    EndVoucherRequestPromo,
    EndVoucherRedemeedPromo,
    EndVoucherRedemeedFirstopPromo,
    StartViewPromo,
    EndViewPromo,
    EndPracticeRbo
  }
} = conventionFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;


export default [
  Yup.object().shape({
    [PromoInternalName.name]: Yup.string()
      .required(`${PromoInternalName.requiredErrorMsg}`),
    // [PromoExternalName.name]: Yup.string()
    //   .required(`${PromoExternalName.requiredErrorMsg}`),
    [CompanyConventionCodePromoAttribute.name]: Yup.string()
      .required(`${CompanyConventionCodePromoAttribute.requiredErrorMsg}`),
    [CompanyNameConventionPromoAttribute.name]: Yup.string()
      .required(`${CompanyNameConventionPromoAttribute.requiredErrorMsg}`),
    //[CompanyEmailDomainConventionPromoAttribute.name]: Yup.string()
     // .required(`${CompanyEmailDomainConventionPromoAttribute.requiredErrorMsg}`),
    [StartVoucherRequestPromo.name]: Yup.date()
      .required(`${StartVoucherRequestPromo.requiredErrorMsg}`),
    [EndVoucherRequestPromo.name]: Yup.date()
      .required(`${EndVoucherRequestPromo.requiredErrorMsg}`),
    [EndVoucherRedemeedPromo.name]: Yup.date()
      .required(`${EndVoucherRedemeedPromo.requiredErrorMsg}`),
    [EndVoucherRedemeedFirstopPromo.name]: Yup.date()
      .required(`${EndVoucherRedemeedFirstopPromo.requiredErrorMsg}`),
    [StartViewPromo.name]: Yup.string()
      .required(`${StartViewPromo.requiredErrorMsg}`),
    [EndViewPromo.name]: Yup.string()
      .required(`${EndViewPromo.requiredErrorMsg}`),
    [EndPracticeRbo.name]: Yup.string()
      .required(`${EndPracticeRbo.requiredErrorMsg}`),
  }),
];

// ,
// [zipcode.name]: Yup.string()
//   .required(`${zipcode.requiredErrorMsg}`)
//   .test(
//     'len',
//     `${zipcode.invalidErrorMsg}`,
//     val => val && val.length === 5
//   ),
// [country.name]: Yup.string()
//   .nullable()
//   .required(`${country.requiredErrorMsg}`)

// Yup.object().shape({
//   [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
//   [cardNumber.name]: Yup.string()
//     .required(`${cardNumber.requiredErrorMsg}`)
//     .matches(visaRegEx, cardNumber.invalidErrorMsg),
//   [expiryDate.name]: Yup.string()
//     .nullable()
//     .required(`${expiryDate.requiredErrorMsg}`)
//     .test('expDate', expiryDate.invalidErrorMsg, val => {
//       if (val) {
//         const startDate = new Date();
//         const endDate = new Date(2050, 12, 31);
//         if (moment(val, moment.ISO_8601).isValid()) {
//           return moment(val).isBetween(startDate, endDate);
//         }
//         return false;
//       }
//       return false;
//     }),
//   [cvv.name]: Yup.string()
//     .required(`${cvv.requiredErrorMsg}`)
//     .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
// })

