import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { promoService } from '../../../services/promo.service';
import {useNavigate, useParams} from "react-router-dom";
import {practiceService} from "../../../services/practice.service";
import { authenticationService } from 'src/services/authentication.service';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },


}));



const CampaignGiustificativoListView = () => {
  const classes = useStyles();
  const { IdPromo } = useParams();
  const {IdReseller}=useParams();
  const [practiceData, setPractice] = useState([]);
  const [resellerData,setResellerData]= useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingTable, setIsFetchingTable] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    const user = authenticationService.currentUserValue;
    setCurrentUser(user);
  }, [])




  useEffect(() => {
    if (!currentUser){
      return;
    }
    if ((currentUser.role != 'Reseller' || currentUser.id!=IdReseller) && !(currentUser.role == 'Admin' || currentUser.role == "Backoffice" || currentUser.role == "Bridgestone")) {
      alert("User non reseller oppure IdReseller differente da User.id");
      navigate("/backoffice/dashboard-reseller");
      return;
    };
    setIsFetching(true);
    practiceService.GetGiustificativoRetail(IdPromo,IdReseller).then((data) => {
      data.map((item, index) => data[index].id=item.nPratica);
      console.log(data);
      setPractice(data);

    });
    practiceService.GetGiustificativo(IdPromo,IdReseller).then((data) => {
      console.log("get giustificativo "+JSON.stringify(data))
      setResellerData(data);
      setIsFetching(false);
    });

  }, [currentUser]);
  return (
    <Page

      className={classes.root}
      title="Campaign"
    >


      <Container maxWidth={false}>
        {/* <Toolbar />*/}
        <Box mt={10}>
          <Results
            practiceData={practiceData}
            isFetching={isFetching}
            resellerData={resellerData}
            setIsFetchingTable={setIsFetchingTable}
            isFetchingTable={isFetchingTable}
            currentUser={currentUser}
            IdPromo={IdPromo}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CampaignGiustificativoListView;


   
