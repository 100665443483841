import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link,
  TextField
} from '@material-ui/core';

 

import moment from 'moment';
import { Formik, Form } from 'formik';
import { useFormikContext } from 'formik';
import { useForm } from "react-hook-form";

import { useParams, useNavigate } from 'react-router-dom';

import validationSchema from './FormModel/validationSchema';
import awardFormModel from './FormModel/awardFormModel';
import formInitialValues from './FormModel/formInitialValues';


import AwardData from './Forms/AwardData'; 


import { awardService } from '../../../services/award.service';
import { alertService } from '../../../services/alert.service';

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(1)
    },
  }));

const steps = ['Awards data']; 
const { formId, formField } = awardFormModel;


function _renderStepContent(step) {
  console.clear();
    switch (step) {
      case 0:
        return <AwardData formField={formField}/>;
      default:
        return <div>Not Found</div>;
    }
  }

export default function AddEditAward() {
    const navigate = useNavigate();
    const { AwardId } = useParams(); 
    const isAddMode = !AwardId;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    // const { setValue, register, setFieldValue } = useForm();
    let [award, setAward] = React.useState({});
    
 

    let [hasError, setHasError] = React.useState({
        value: false,
        description:''
    });

  
  useEffect(() => {
      if (!isAddMode) 
      {
        awardService.GetAwardById(AwardId).then(data => 
            {
                setAward({awardName: data.awardName, awardDescription: data.awardDescription});
                // console.clear();
                // console.log('start');
                // console.log(formInitialValues);                
                // console.log(formInitialValues.AwardName);
                // formInitialValues.AwardName = data.awardName;
                // console.log(formInitialValues.AwardName);
                // console.log('end');
                //setValue("name", data.awardName); 
            });
      }
      else
      {
       
      }
    
  }, [])

 
  // async function _submitForm(values, actions) {
  //   actions.setSubmitting(false);

  //   ConventionService.postDataConventionRegistration(values)
  //   .then(
  //       r => {
  //         setHasError({value:false, description: ''});
  //         console.log(r);
  //         setActiveStep(activeStep + 1);
  //         actions.setTouched({});
  //         actions.setSubmitting(false);  
  //       },
  //       error => {
  //         setHasError({value:true, description: error});
  //         console.log('errore', hasError.value, hasError.description);
  //         setActiveStep(activeStep);
  //         actions.setTouched({});
  //         actions.setSubmitting(false);  
  //       }
  //   );
  // }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _handleSubmit(values, actions) {
    
    actions.setSubmitting(true);
    if (isLastStep) {
      _submitForm(values, actions);
    } 
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _submitForm(fields, { setStatus, setSubmitting }) { 
        // setStatus();
         if (isAddMode) {
          createAward(fields, setSubmitting);
        } else {
          updateAward(AwardId, fields, setSubmitting);
        }
    }

    function createAward(fields, setSubmitting) {
      awardService.CreateAward(fields)
            .then(() => {
              setSubmitting(false);
              // alertService.success('Award added', { keepAfterRouteChange: true });
              setActiveStep(activeStep + 1); 
            })
            .catch(error => {
              setSubmitting(false); 
          });
    }

    function updateAward(id, fields, setSubmitting) {
        awardService.UpdateAward(id, fields)
            .then(() => {
              setSubmitting(false);
              // alertService.success('Award added', { keepAfterRouteChange: true });
              setActiveStep(activeStep + 1); 
            })
            .catch(error => {
                setSubmitting(false); 
            }); 
    }

    function _endFlow() {
      if (isAddMode) {
        navigate('..', { replace: false });
      } else {
        navigate('../..', { replace: false });
      }

       
    }
    
 
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1> */}
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            AWARD SAVED
                          </Typography> 
                          <div className={classes.wrapper}>
                            <Button
                              onClick={_endFlow}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}>CLOSE
                            </Button>
                          </div>
                    </React.Fragment>
            ) : (
              <Formik
               initialValues={{
                ['AwardName']: award.awardName,
                ['AwardDescription']: award.awardDescription 
              }}
              // initialValues={formInitialValues}
              enableReinitialize={true}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  
                  {_renderStepContent(activeStep)} 
                  
                  <div className={classes.buttons}>

                  {hasError.value && (
                      <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                    )}
 
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Precedente
                      </Button>
                    )}
                    
                    <div className={classes.wrapper}>

                   
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? 'Confirm' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
}
