

 

import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';   


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));
  
    
const Results = ({ className, awards, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const headCells = [
    {field: 'idAward', headerName: 'Id', width: 100, description:'The id used for awards.'},
    {field: 'awardName', headerName: 'Name',  flex: 1, description:'The name of the award.'},
    {field: 'awardDescription', headerName: 'Description', flex: 1,  description:'The description of the award.'},
    {field: 'Edit', headerName: 'Edit', width: 150, renderCell: (params) => (
         <strong>
          {params.value}
     
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }} 
             onClick={() => { onEdit(params.row.idAward) }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    ];  

    let onEdit = (AwardId) => { 
      navigate('edit/'+ AwardId, { replace: false }); 
    }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    > 
    <PerfectScrollbar>
        <Box minWidth={1050}>
        <div id='allAward'>
        <DataGrid fontSize='7'
            columns={headCells} 
            rows={awards}
            pageSize={50}
            rowsPerPageOptions={[5, 10, 20, 50]}
            showToolbar
            
            // components={{
            //   Toolbar: GridToolbar,
            // }}
            />
        </div> 
        </Box>
        
      </PerfectScrollbar>
      
    </Card>
  );
};


Results.propTypes = {
  className: PropTypes.string,
  awards: PropTypes.array.isRequired
};

export default Results;
