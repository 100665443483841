import configData from './config.json';
import {API_URL, authHeader, handleResponse} from '../helpers';

export const dashboardService = {
    GetDashboardSummary 
};


function preventConcurrentExec() {
    const pendingPromises = Object.create(null);
    return function(promiseKey, asyncFunction) {
      return pendingPromises[promiseKey]
        || (pendingPromises[promiseKey] = asyncFunction().finally(() => delete pendingPromises[promiseKey]));
    }
  }
  
const guardedPromise = preventConcurrentExec();

function GetDashboardSummary() {
        return guardedPromise("GetDashboardSummary", () => new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET', headers: authHeader() };
    
        fetch(`${API_URL}/dashboard`, requestOptions)
            .then(handleResponse)
            .then(responseData => {
                if (responseData) { 
                    return resolve(responseData);
                }
                reject("Empty Response");
            })
            .catch(error => reject(error));
    }));
} 
