import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { authenticationService } from 'src/services/authentication.service';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatarLime: {
    backgroundColor: colors.lime[600],
    height: 56,
    width: 56
  }, 
  avatarBlu: {
    backgroundColor: colors.blue[600],
    height: 56,
    width: 56
  }, 
  avatarGreen: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  }, 
  avatarOrange: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }, 
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
})); 
const TotalPracticeBusiness = ({ className, 
  title, icon, business, 
  totalPractices, totalPracticesNew, totalPracticesVerify,
   ...rest }) => {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h5"
            >
              {title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {totalPractices}
            </Typography>
          </Grid>
          <Grid item>
            {business=='auto'&&
              <Avatar className={classes.avatarLime}>
                {icon}
              </Avatar>}
            {business=='truck'&&
              <Avatar className={classes.avatarBlu}>
                {icon}
              </Avatar>}
            {business=='agri'&&
              <Avatar className={classes.avatarGreen}>
                {icon}
              </Avatar>}
            {business=='moto'&&
              <Avatar className={classes.avatarOrange}>
                {icon}
              </Avatar>}
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
           {(currentUser?.role=="Backoffice" || currentUser?.role=="Admin") &&
          <Typography
              color="textSecondary"
              gutterBottom
              variant="h5"
            >
              Di cui nuove: {totalPracticesNew}
            </Typography>} 
        </Box>

        <Box
          mt={2}
          display="flex"
          alignItems="center"
        > 
          {(currentUser?.role=="Backoffice" || currentUser?.role=="Admin") &&
          <Typography
              color="textSecondary"
              gutterBottom
              variant="h5"
            >
              Di cui da verificare: {totalPracticesVerify}
            </Typography>}
           
            
        </Box>
      </CardContent>
    </Card>
  );
};

TotalPracticeBusiness.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  totalPracticeRetail: PropTypes.number,
  diffVsYesterdayRetail: PropTypes.number
};

export default TotalPracticeBusiness;
 