import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, tyre,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}





  const headCells = [
    {field: 'id', headerName: 'Id',  width: 100, description:'The internal Id.'},
    {field: 'Cognome', headerName: 'Cognome',  width: 200, description:'The internal Cognome.'},
    {field: 'Nome', headerName: 'Nome',  width: 200, description:'The internal Nome.'},
    {field: 'email',   headerName: 'email',  width: 300, description:'The internal email.'},
    {field: 'Telefonino',   headerName: 'Telefonino',  width: 150, description:'The internal CF.'},
    {field: 'CF',   headerName: 'CF',  width: 200, description:'The internal iswinter.'},
    {field: 'Voucher',   headerName: 'Voucher',  width: 150, description:'The internal Voucher.'}


  ];





return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>

      <div id='TableInCampaign'>

      <DataGrid
          columns={headCells}
          rows={tyre}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>

  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  tyre: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
