import React,  { useState }  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'; 
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { InputField } from '../../../../FormFields';
import { DatePickerField } from '../../../../FormFields';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  typografy: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginTop: theme.spacing(1)
  },
}));


export default function AwardData(props) {
  const {
    formField: {     
      AwardName,
      AwardDescription 
   }
  } = props; 

  const classes = useStyles();
  const navigate = useNavigate();
    
 
  return (
    <Grid item xs={12} md={12} spacing={5}>
    {/* <Typography variant="h6" className={classes.typografy}>
      DATI GENERALI CONVENZIONE
    </Typography> */}
    {/* <Typography variant="h8" gutterBottom>
      INSERISCI I TUOI DATI PER RICEVERE IL VOUCHER
    </Typography>  */}
 

    <Grid container spacing={3} className={classes.grid}>
           <Grid item xs={12} sm={6}> 
              <InputField name={AwardName.name} label={AwardName.label} fullWidth />
            </Grid>
            {/* <h1>{props.AddOrEdit ? 'Add' : 'Edit'}</h1> */}
            <Grid item xs={12} sm={6}>
              <InputField name={AwardDescription.name} label={AwardDescription.label} fullWidth />
            </Grid>
      </Grid> 
</Grid>
  );
}

