import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  colors,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid'; 

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    div: {
      // backgroundColor: theme.palette.background.dark, 
      // paddingBottom: theme.spacing(3),
      // paddingTop: theme.spacing(3),
      width: '100%',
      height: 880
    },
    datagrid: { 
      fontSize: 12, 
    },
    editButton: {
      backgroundColor: colors.red[600] 
    }
  }));
 
  

const Results = ({ className, practices, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  

  const headCells = [
    {field: 'Edit', headerName: ' ', width: 100, renderCell: (params) => (  

      <strong>

{(params?.row?.showButtonModifica) ? (
        <Button
        variant="contained"
        color="primary"
        size="small"
        
        className={classes.editButton}
         onClick={() => { onEdit(params.row.idPractice) }}
      >
        MODIFICA
      </Button>
) : ( <React.Fragment>
            
        </React.Fragment>)} 
       {}  
       
     </strong>
   ),
 },
    {field: 'practiceNrBo', headerName: 'N. PRATICA', width: 130, description:'Il numero della pratica'},
    {field: 'practiceStateBkb', headerName: 'STATO', width: 160, description:'Lo stato della pratica'}, 
    {field: 'tyrePresent', headerName: 'PNEUMATICI CARICATI', width: 200, description:'Indica se sono stati inseriti gli pneumatic'},
    {field: 'attachPresent', headerName: 'ALLEGATI CARICATI', width: 200, description:'Indica se sono stati inseriti allegati'},
    {field: 'dateIns', type: 'dateTime',  headerName: 'DATA INSERIMENTO',  width: 200, description:'La data di inserimento della pratica'},
    {field: 'name', headerName: 'NOMINATIVO', width: 175, description:'Il nominativo del cliente della pratica' },
    {field: 'denomination', headerName: 'DENOMINAZIONE', width: 175, description:'La ragione sociale del cliente della pratica' },
    {field: 'email', headerName: 'EMAIL', width: 275, description:'La mail fornita dal cliente'},
    {field: 'promo', headerName: 'PROMO', width: 250, description:'La promozione' },
    {field: 'awardRefund', headerName: 'RIMBORSO', width: 120, description:'Il premio della pratica'}
     
  ];  





    let onEdit = (idPractice) => { 
      navigate('edit/'+ idPractice, { replace: false }); 
    }

    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      > 
      <PerfectScrollbar>
          <Box minWidth={1050}>
          {/* <div style={{ height:100, width: '100%'}}> */}
          
          <div className={classes.div}>
          <DataGrid  
              className={classes.datagrid}
              columns={headCells} 
              rows={practices}
              pageSize={20}
              rowsPerPageOptions={[5, 10, 20, 50]}
              showToolbar
              components={{
                Toolbar: GridToolbar,
              }} 
              />
          </div> 
          </Box>
          
        </PerfectScrollbar>
        
      </Card>
    );
  };


Results.propTypes = {
  className: PropTypes.string,
  practices: PropTypes.array.isRequired
};

export default Results;
