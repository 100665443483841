import { Navigate } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Container,
  Button,
  Paper,
  Card,
  CircularProgress,
  colors,
  Checkbox,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Switch, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core';

import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { SelectField } from '../../../FormFields';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  div: {
    // backgroundColor: theme.palette.background.dark,
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    width: '100%',
    height: 880
  },
  datagrid: {
    fontSize: 12,
  },
  editButton: {
    backgroundColor: colors.red[600]
  },
  typografy: {
    marginBottom: theme.spacing(3)
  },

  grid: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const Results = ({
  className, practices,promo,promoSelected,handlePromo, practiceState, selectedPracticeStateIds, handleSelectOne, isFetching,isFetchingPromo, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dataselect = promo;

  const sortModel=[
    {
      field: 'dateIns',
      sort: 'desc',
    },
  ];

  const headCells = [
    {
      field: 'Edit',
      headerName: ' ',
      width: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.editButton}
            // style={{ marginLeft: 16 }}
            onClick={() => { onEdit(params.row.idPractice); }}
          >
            MODIFICA
          </Button>
        </strong>
      ),
    },
    {
      field: 'practiceNrBo', headerName: 'N. PRATICA', width: 130, description: 'Il numero della pratica'
    },
    {
      field: 'practiceState', headerName: 'STATO', width: 160, description: 'Lo stato della pratica'
    },
    {
      field: 'tyrePresent', headerName: 'PNEUMATICI CARICATI', width: 200, description: 'Indica se sono stati inseriti gli pneumatic'
    },
    {
      field: 'attachPresent', headerName: 'ALLEGATI CARICATI', width: 200, description: 'Indica se sono stati inseriti allegati'
    },
    {
      field: 'dateIns', type: 'dateTime', headerName: 'DATA INSERIMENTO', width: 200, description: 'La data di inserimento della pratica'
    },
    {
      field: 'name', headerName: 'NOMINATIVO', width: 175, description: 'Il cliente della pratica'
    },
    {
      field: 'email', headerName: 'EMAIL', width: 275, description: 'La mail fornita dal cliente'
    },
    {
      field: 'promo', headerName: 'PROMO', width: 250, description: 'La promozione'
    },
    {
      field: 'awardRefund', headerName: 'RIMBORSO', width: 120, description: 'Il premio della pratica'
    }

  ];

  let onEdit = (PromoId) => {
    navigate("/backoffice/practicesBo/edit/"+PromoId+"/"+promoSelected+"/"+selectedPracticeStateIds, { replace: false });
  };


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >



      <div id={"selectPromo"} style={{display:'flex'}}>
        <Typography variant="h4" className={classes.typografy} style={{margin: "2vh 2vh 2vh 2vh"}}>
          Select promo:
        </Typography>
        {!isFetchingPromo
        && (
          <Select
            onChange={handlePromo}
            defaultValue={promoSelected}
          >
              {dataselect.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label=='TUTTE'? item.label : item.label+" n:"+item.n_nuove+" v:"+item.n_verificate}
                </MenuItem>
              ))}

          </Select>

          )}
      </div>




      <PerfectScrollbar>

        <Grid item xs={12} md={12}  className={classes.grid}>
          <Typography variant="h4" className={classes.typografy}>
            Filtro in base agli stati:
          </Typography>
          {practiceState.map((stateChecked) => (

            <FormControlLabel
              disabled={isFetching}
              control={(
                <Checkbox
                  enabled
                  checked={stateChecked.selected}
                  checked={selectedPracticeStateIds.indexOf(stateChecked.id) !== -1}
                  onChange={(event) => handleSelectOne(event, stateChecked.id)}
                  value="true"
                />
        )}
              label={stateChecked.description}
            />
          ))}
        </Grid>

        <Box minWidth={1050}>
          {/* <div style={{ height:100, width: '100%'}}> */}

          <div className={classes.div} id='tablePratiche'>
            {isFetching
              && (
              <Container className={classes.circularProgress}>

                <CircularProgress
                  size={48}
                />
              </Container>
              )}

            {!isFetching
              && (
              <DataGrid
                className={classes.datagrid}
                columns={headCells}
                rows={practices}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20, 50]}
                showToolbar

                sortModel={sortModel}

                components={{
                  Toolbar: GridToolbar,
                }}

              />



              )}

          </div>
        </Box>

      </PerfectScrollbar>

    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  practices: PropTypes.array.isRequired,
  promo: PropTypes.array.isRequired
};

export default Results;
