import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Toolbar,
  AppBar,
  Link
} from '@material-ui/core';


import moment from 'moment';
import { Formik, Form } from 'formik';

import { useParams, useNavigate } from 'react-router-dom';

import addPracticeValidationSchema from './FormModel/addPracticeValidationSchema';
import addPracticeFormModel from './FormModel/addPracticeFormModel';
import addPracticeFormInitialValues from './FormModel/addPracticeFormInitialValues';

import VoucherCheck from './Forms/VoucherCheck';
import AwardSelection from './Forms/AwardSelection';



import { voucherService } from '../../../services/voucher.service';
import { alertService } from '../../../services/alert.service';
import { authenticationService } from '../../../services/authentication.service';
import { practiceService } from 'src/services/practice.service';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errors: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
}));

const steps = ['Codice voucher', 'Scelta premio']; 
const { formId, formField } = addPracticeFormModel;




export default function AddPractice() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const currentValidationSchema = addPracticeValidationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const isCodeCheckStep = activeStep === 0;

    const [currentUser, setCurrentUser] = useState();
    
  useEffect(() => { 
    const user = authenticationService.currentUserValue;
    setCurrentUser(user)
  }, [])

 

    let [hasError, setHasError] = React.useState({
        value: false,
        description:''
    });

  let [voucherData, setVoucherData] = React.useState({});
  let [voucherCode, setVoucherCode] = React.useState();

  const [award, setAward] = React.useState('0');
  


  function onToggleAward(event, value)   {

    console.log(value);

    setAward(value); 
    }


  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <VoucherCheck formField={formField} />;
      case 1:
        return <AwardSelection formField={formField} awardList={voucherData?.award} onToggle={onToggleAward} award={award}/>;
      // case 2:
      //   return <ResellerJoined formField={formField} />;
      // case 3:
      //  return <Tyres formField={formField} />;
      // case 4:
      //   return <Review formField={formField}/>;
      default:
        return <div>Not Found</div>;
    }
  }

    async function _submitForm(values, actions) {
      if (award=='0')
      {
        setHasError({value:true, description: 'Selezionare il premio'}); 
        actions.setTouched({});
        actions.setSubmitting(false);             
      }
     else
     {
      actions.setSubmitting(false);
      practiceService.CreatePractice(values, currentUser.id, award)
      .then(
          r => {
            setHasError({value:false, description: ''});
            console.log(r);
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);  
          },
          error => {
            setHasError({value:true, description: error});
            console.log('errore', hasError.value, hasError.description);
            setActiveStep(activeStep);
            actions.setTouched({});
            actions.setSubmitting(false);  
          }
      ); 
     }
     
    }
  
    function _handleSubmit(values, actions) { 
      if (isLastStep) {
        _submitForm(values, actions);
      } 
      else if (isCodeCheckStep) {
        voucherService.GetVoucherByCode(values.VoucherCode,currentUser.id)
          .then(
              r => {
                setHasError({value:false, description: ''});
                setVoucherData(r); 
                setVoucherCode(values.VoucherCode);
                setActiveStep(activeStep + 1);
                actions.setTouched({});
                actions.setSubmitting(false);  
              },
              error => {
                setHasError({value:true, description: error});
                console.log('errore', hasError.value, hasError.description);
                setActiveStep(activeStep);
                actions.setTouched({});
                actions.setSubmitting(false);  
              }
          );
      }
      else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
    function _handleBack() {
      setActiveStep(activeStep - 1);
    }

function _endFlow() {
  navigate('..', { replace: false }); 
}

    
return (
  <React.Fragment>
    <CssBaseline />
     <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
                  <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                          PRATICA CREATA CORRETTAMENTE
                        </Typography> 
                        <div className={classes.wrapper}>
                          <Button
                            onClick={_endFlow}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}>CLOSE
                          </Button>
                        </div>
                  </React.Fragment>
          ) : (
            <Formik
             initialValues={{ 
              ['IdPromo']: voucherData?.promo?.idPromo,
              ['LongNamePromoAttribute']: voucherData?.promo?.promoInternalName,
              ['PromoExternalName']: voucherData?.promo?.promoExternalName, 
              ['IdProspect']: voucherData?.prospect?.idProspect,         
              ['ProspectName']: voucherData?.prospect?.firstName + ' ' + voucherData?.prospect?.lastName,              
              ['VoucherCode']: voucherCode
            }} 
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                
                {_renderStepContent(activeStep)} 
                
                <div className={classes.buttons}>

                {hasError.value && (
                    <Typography color="secondary" className={classes.errors}>{hasError.description}</Typography>
                  )}

                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Precedente
                    </Button>
                  )}
                  
                  <div className={classes.wrapper}>

                 
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? 'CONFERMA' : 'PROSSIMO'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          )}
        </React.Fragment>
      </Paper>
      {/* <Copyright /> */}
    </main>
  </React.Fragment>
);
}
