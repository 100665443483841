import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {}
}));

const PracticeByDay = ({ className,  
  nrPracticesMinus4DayAuto,
  nrPracticesMinus3DayAuto,
  nrPracticesMinus2DayAuto,
  nrPracticesMinus1DayAuto,
  nrPracticesMinusTodayAuto,
  nrPracticesMinus4DayTruck,
  nrPracticesMinus3DayTruck,
  nrPracticesMinus2DayTruck,
  nrPracticesMinus1DayTruck,
  nrPracticesTodayTruck,
  nrPracticesMinus4DayAgri,
  nrPracticesMinus3DayAgri,
  nrPracticesMinus2DayAgri,
  nrPracticesMinus1DayAgri,
  nrPracticesTodayAgri,
  nrPracticesMinus4DayMoto,
  nrPracticesMinus3DayMoto,
  nrPracticesMinus2DayMoto,
  nrPracticesMinus1DayMoto,
  nrPracticesTodayMoto,  
  ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  var getDaysArray = function() {
    var end = new Date();    
    var start = new Date();    
    start.setDate(end.getDate()-4); 
    for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(moment(dt).format("DD/MM/YYYY"));
    } 
    return arr;
};

  const data = {
    datasets: [
      {
        backgroundColor: colors.lime[600],
        data: [nrPracticesMinus4DayAuto,
          nrPracticesMinus3DayAuto,
          nrPracticesMinus2DayAuto,
          nrPracticesMinus1DayAuto,
          nrPracticesMinusTodayAuto],
        label: 'Auto'
       },
       {
        backgroundColor: colors.blue[600],
        data: [nrPracticesMinus4DayTruck,
          nrPracticesMinus3DayTruck,
          nrPracticesMinus2DayTruck,
          nrPracticesMinus1DayTruck,
          nrPracticesTodayTruck],
        label: 'Truck'
       },
       {
        backgroundColor: colors.green[600],
        data: [nrPracticesMinus4DayAgri,
          nrPracticesMinus3DayAgri,
          nrPracticesMinus2DayAgri,
          nrPracticesMinus1DayAgri,
          nrPracticesTodayAgri],
        label: 'Agri'
       },
      {
        backgroundColor: colors.orange[600],
        data: [,
          nrPracticesMinus4DayMoto,
          nrPracticesMinus3DayMoto,
          nrPracticesMinus2DayMoto,
          nrPracticesMinus1DayMoto,
          nrPracticesTodayMoto],
        label: 'Moto'
      }
    ], 
    labels: getDaysArray()
  };

 


  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        // action={(
        //   <Button
        //     endIcon={<ArrowDropDownIcon />}
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days
        //   </Button>
        // )}
        title="Giornaliero pratiche"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

PracticeByDay.propTypes = {
  className: PropTypes.string,
  nrPracticesMinus4Day: PropTypes.number,
  nrPracticesMinus3Day: PropTypes.number,
  nrPracticesMinus2Day: PropTypes.number,
  nrPracticesMinus1Day: PropTypes.number,
  nrPracticesToday: PropTypes.number
};

export default PracticeByDay;
 