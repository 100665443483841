export default {
  formId: 'tyreForm',
  formField: {
    EAN: {
      name: 'EAN',
      label: 'EAN',
      requiredErrorMsg: 'EAN is required'
    },
    PROD_GRP_1: {
      name: 'PROD_GRP_1',
      label: 'PROD_GRP_1',
      requiredErrorMsg: 'PROD_GRP_1 is required'
    },
    SUPPLIER_CODE: {
      name: 'SUPPLIER_CODE',
      label: 'SUPPLIER_CODE',
      requiredErrorMsg: 'SUPPLIER_CODE is required'
    },
    DESCRIPTION_1: {
      name: 'DESCRIPTION_1',
      label: 'DESCRIPTION_1',
      requiredErrorMsg: 'DESCRIPTION_1 is required'
    },
    DESCRIPTION_2: {
      name: 'DESCRIPTION_2',
      label: 'DESCRIPTION_2',
      requiredErrorMsg: 'DESCRIPTION_2 is required'
    },
    PROD_INFO: {
      name: 'PROD_INFO',
      label: 'PROD_INFO',
      requiredErrorMsg: 'PROD_INFO is required'
    },
    WDK_ANUB: {
      name: 'WDK_ANUB',
      label: 'WDK_ANUB',
      requiredErrorMsg: 'WDK_ANUB is required'
    },
    WDK_BRAND: {
      name: 'WDK_BRAND',
      label: 'WDK_BRAND',
      requiredErrorMsg: 'WDK_BRAND is required'
    },
    WDK_BRAND_TEXT: {
      name: 'WDK_BRAND_TEXT',
      label: 'WDK_BRAND_TEXT',
      requiredErrorMsg: 'WDK_BRAND_TEXT is required'
    },
    BRAND: {
      name: 'BRAND',
      label: 'BRAND',
      requiredErrorMsg: 'BRAND is required'
    },
    BRAND_TEXT: {
      name: 'BRAND_TEXT',
      label: 'BRAND_TEXT',
      requiredErrorMsg: 'BRAND_TEXT is required'
    },
    PROD_GRP_2: {
      name: 'PROD_GRP_2',
      label: 'PROD_GRP_2',
      requiredErrorMsg: 'PROD_GRP_2 is required'
    },
    GROUP_DESCRIPTION: {
      name: 'GROUP_DESCRIPTION',
      label: 'GROUP_DESCRIPTION',
      requiredErrorMsg: 'GROUP_DESCRIPTION is required'
    },
    WEIGHT: {
      name: 'WEIGHT',
      label: 'WEIGHT',
      requiredErrorMsg: 'WEIGHT is required'
    },
    RIM_INCH: {
      name: 'RIM_INCH',
      label: 'RIM_INCH',
      requiredErrorMsg: 'RIM_INCH is required'
    },
    PROD_CYCLE: {
      name: 'PROD_CYCLE',
      label: 'PROD_CYCLE',
      requiredErrorMsg: 'PROD_CYCLE is required'
    },
    THIRD_PARTY: {
      name: 'THIRD_PARTY',
      label: 'THIRD_PARTY',
      requiredErrorMsg: 'THIRD_PARTY is required'
    },
    PL: {
      name: 'PL',
      label: 'PL',
      requiredErrorMsg: 'PL is required'
    },
    TEL: {
      name: 'TEL',
      label: 'TEL',
      requiredErrorMsg: 'TEL is required'
    },
    EDI: {
      name: 'EDI',
      label: 'EDI',
      requiredErrorMsg: 'EDI is required'
    },
    ADHOC: {
      name: 'ADHOC',
      label: 'ADHOC',
      requiredErrorMsg: 'ADHOC is required'
    },
    PL_ID: {
      name: 'PL_ID',
      label: 'PL_ID',
      requiredErrorMsg: 'PL_ID is required'
    },
    URL_1: {
      name: 'URL_1',
      label: 'URL_1',
      requiredErrorMsg: 'URL_1 is required'
    },
    URL_2: {
      name: 'URL_2',
      label: 'URL_2',
      requiredErrorMsg: 'URL_2 is required'
    },
    URL_3: {
      name: 'URL_3',
      label: 'URL_3',
      requiredErrorMsg: 'URL_3 is required'
    },
    URL_4: {
      name: 'URL_4',
      label: 'URL_4',
      requiredErrorMsg: 'URL_4 is required'
    },
    URL_5: {
      name: 'URL_5',
      label: 'URL_5',
      requiredErrorMsg: 'URL_5 is required'
    },
    WIDTH_MM: {
      name: 'WIDTH_MM',
      label: 'WIDTH_MM',
      requiredErrorMsg: 'WIDTH_MM is required'
    },
    WIDTH_INCH: {
      name: 'WIDTH_INCH',
      label: 'WIDTH_INCH',
      requiredErrorMsg: 'WIDTH_INCH is required'
    },
    ASPECT_RATIO: {
      name: 'ASPECT_RATIO',
      label: 'ASPECT_RATIO',
      requiredErrorMsg: 'ASPECT_RATIO is required'
    },
    OVL_DIAMETER: {
      name: 'OVL_DIAMETER',
      label: 'OVL_DIAMETER',
      requiredErrorMsg: 'OVL_DIAMETER is required'
    },
    CONSTRUCTION_1: {
      name: 'CONSTRUCTION_1',
      label: 'CONSTRUCTION_1',
      requiredErrorMsg: 'CONSTRUCTION_1 is required'
    },
    CONSTRUCTION_2: {
      name: 'CONSTRUCTION_2',
      label: 'CONSTRUCTION_2',
      requiredErrorMsg: 'CONSTRUCTION_2 is required'
    },
    USAGE: {
      name: 'USAGE',
      label: 'USAGE',
      requiredErrorMsg: 'USAGE is required'
    },
    DEPTH: {
      name: 'DEPTH',
      label: 'DEPTH',
      requiredErrorMsg: 'DEPTH is required'
    },
    LI1: {
      name: 'LI1',
      label: 'LI1',
      requiredErrorMsg: 'LI1 is required'
    },
    LI2: {
      name: 'LI2',
      label: 'LI2',
      requiredErrorMsg: 'LI2 is required'
    },
    LI3DWB: {
      name: 'LI3(DWB)',
      label: 'LI3(DWB)',
      requiredErrorMsg: 'LI3(DWB) is required'
    },
    LI4DWB: {
      name: 'LI4(DWB)',
      label: 'LI4(DWB)',
      requiredErrorMsg: 'LI4(DWB) is required'
    },
    SP1: {
      name: 'SP1',
      label: 'SP1',
      requiredErrorMsg: 'SP1 is required'
    },
    SP2: {
      name: 'SP2',
      label: 'SP2',
      requiredErrorMsg: 'SP2 is required'
    },
    TLTT: {
      name: 'TL/TT',
      label: 'TL/TT',
      requiredErrorMsg: 'TL/TT is required'
    },
    FLANK: {
      name: 'FLANK',
      label: 'FLANK',
      requiredErrorMsg: 'FLANK is required'
    },
    PR: {
      name: 'PR',
      label: 'PR',
      requiredErrorMsg: 'PR is required'
    },
    RFD: {
      name: 'RFD',
      label: 'RFD',
      requiredErrorMsg: 'RFD is required'
    },
    SIZE_PREFIX: {
      name: 'SIZE_PREFIX',
      label: 'SIZE_PREFIX',
      requiredErrorMsg: 'SIZE_PREFIX is required'
    },
    COMM_MARK: {
      name: 'COMM_MARK',
      label: 'COMM_MARK',
      requiredErrorMsg: 'COMM_MARK is required'
    },
    RIM_MM: {
      name: 'RIM_MM',
      label: 'RIM_MM',
      requiredErrorMsg: 'RIM_MM is required'
    },
    RUN_FLAT: {
      name: 'RUN_FLAT',
      label: 'RUN_FLAT',
      requiredErrorMsg: 'RUN_FLAT is required'
    },
    SIDEWALL: {
      name: 'SIDEWALL',
      label: 'SIDEWALL',
      requiredErrorMsg: 'SIDEWALL is required'
    },
    DESIGN_1: {
      name: 'DESIGN_1',
      label: 'DESIGN_1',
      requiredErrorMsg: 'DESIGN_1 is required'
    },
    DESIGN_2: {
      name: 'DESIGN_2',
      label: 'DESIGN_2',
      requiredErrorMsg: 'DESIGN_2 is required'
    },
    PRODUCT_TYPE: {
      name: 'PRODUCT_TYPE',
      label: 'PRODUCT_TYPE',
      requiredErrorMsg: 'PRODUCT_TYPE is required'
    },
    VEHICLE_TYPE: {
      name: 'VEHICLE_TYPE',
      label: 'VEHICLE_TYPE',
      requiredErrorMsg: 'VEHICLE_TYPE is required'
    },
    COND_GRP: {
      name: 'COND_GRP',
      label: 'COND_GRP',
      requiredErrorMsg: 'COND_GRP is required'
    },
    TAX_ID: {
      name: 'TAX_ID',
      label: 'TAX_ID',
      requiredErrorMsg: 'TAX_ID is required'
    },
    TAX: {
      name: 'TAX',
      label: 'TAX',
      requiredErrorMsg: 'TAX is required'
    },
    SUGGESTED_PRICE: {
      name: 'SUGGESTED_PRICE',
      label: 'SUGGESTED_PRICE',
      requiredErrorMsg: 'SUGGESTED_PRICE is required'
    },
    GROSS_PRICE: {
      name: 'GROSS_PRICE',
      label: 'GROSS_PRICE',
      requiredErrorMsg: 'GROSS_PRICE is required'
    },
    GP_VALID_FROM: {
      name: 'GP_VALID_FROM',
      label: 'GP_VALID_FROM',
      requiredErrorMsg: 'GP_VALID_FROM is required'
    },
    NET_VALUE: {
      name: 'NET_VALUE',
      label: 'NET_VALUE',
      requiredErrorMsg: 'NET_VALUE is required'
    },
    NV_VALID: {
      name: 'NV_VALID',
      label: 'NV_VALID',
      requiredErrorMsg: 'NV_VALID is required'
    },
    RECYCLING_FEE: {
      name: 'RECYCLING_FEE',
      label: 'RECYCLING_FEE',
      requiredErrorMsg: 'RECYCLING_FEE is required'
    },
    NOISE_PERFORMANCE: {
      name: 'NOISE_PERFORMANCE',
      label: 'NOISE_PERFORMANCE',
      requiredErrorMsg: 'NOISE_PERFORMANCE is required'
    },
    NOISE_CLASS_TYPE: {
      name: 'NOISE_CLASS_TYPE',
      label: 'NOISE_CLASS_TYPE',
      requiredErrorMsg: 'NOISE_CLASS_TYPE is required'
    },
    ROLLING_RESISTANCE: {
      name: 'ROLLING_RESISTANCE',
      label: 'ROLLING_RESISTANCE',
      requiredErrorMsg: 'ROLLING_RESISTANCE is required'
    },
    WET_GRIP: {
      name: 'WET_GRIP',
      label: 'WET_GRIP',
      requiredErrorMsg: 'WET_GRIP is required'
    },
    EC_VEHICLE_CLASS: {
      name: 'EC_VEHICLE_CLASS',
      label: 'EC_VEHICLE_CLASS',
      requiredErrorMsg: 'EC_VEHICLE_CLASS is required'
    },
    EU_DIRECTIVE_NUMBER: {
      name: 'EU_DIRECTIVE_NUMBER',
      label: 'EU_DIRECTIVE_NUMBER',
      requiredErrorMsg: 'EU_DIRECTIVE_NUMBER is required'
    },

    BUSINESS: {
      name: 'BUSINESS',
      label: 'BUSINESS',
      requiredErrorMsg: 'BUSINESS is required'
    },

    ISWINTER: {
      name: 'ISWINTER',
      label: 'ISWINTER',
      requiredErrorMsg: 'ISWINTER is required'
    }
  }
};

