import { Navigate } from 'react-router';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  Avatar,
  Box,
  Button,
  Paper,
  Card,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography, 
  FormControlLabel,
  Switch
} from '@material-ui/core';
 
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {promoService} from "../../../services/promo.service";


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }));



function getMyDate(params) {
    //alert(params);
    var year = params.substring(0,4);
    console.log('year= ', year);
    var month = params.substring(5,7);
    console.log('month= ', month);
    var day = params.substring(8,10);
    console.log('day= ', day);
    var date = new Date(year, month-1, day);
    console.log('date= ', date);
    var dateMoment = moment(date).format("DD/MM/YYYY");
    console.log('dateMoment ', dateMoment);
    //return date; 
    return new Date(2020, 1, 1);
  }




const Results = ({ className, email ,setEmailData,idCampaign, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // renderCell: (data) => {return moment().format("")}



  const headCells = [
    {field: 'idPromoEmail', headerName: 'idPromoEmail',  width: 200, description:'The internal idPromoEmail.'},
    {field: 'cc',   headerName: 'cc',  width: 350, description:'The internal cc.',renderCell : (params) => (
        <div>
          <Button
            id="cc"
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyCC(params.row.cc,params.row.idPromoEmail, params)}}
          > {params.row.cc}
          </Button>
        </div>
      ), },
    {field: 'from',   headerName: 'from',  width: 350, description:'The internal from.',renderCell : (params) => (
        <div>
          <Button
            id="from"
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyFrom(params.row.from,params.row.idPromoEmail,params)}}
          > {params.row.from}
          </Button>
        </div>
      ), },
    {field: 'subject',   headerName: 'subject',  width: 300, description:'The internal subject.',renderCell : (params) => (
        <div>
          <Button
            id="subject"
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifySubject(params.row.subject,params.row.idPromoEmail,params)}}
          > {params.row.subject}
          </Button>
        </div>
      ), },
    {field: 'typeEmail',   headerName: 'Type email',  width: 200, description:'The internal typeEmail.',renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            id="typeEmail"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyTypeEmail(params.row.typeEmail,params.row.idPromoEmail ,params)}}
          > {params.row.typeEmail}
          </Button>
        </div>
      ), },
    {field: 'message',   headerName: 'message',  width: 400, description:'The internal message.',renderCell : (params) => (
        <div>
          <Button
            id="message"
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {  modifyMessage(params.row.message,params.row.idPromoEmail, params)}}
          > {params.row.message}
          </Button>
        </div>
      ), },
    {field: 'Cancella', headerName: 'Cancella', width: 150, description:'Cancella PromoEmail',  renderCell : (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => { cancEmail(params.row.idPromoEmail) }}
          >Cancella
          </Button>
        </div>
      ), },
  ];

  let modifyCC = ( cc ,idPromoEmail, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo cc',
      inputValue: cc,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        email.map((item, index) => {
          if(item.idPromoEmail==idPromoEmail){
            item.cc=ret.value.toString()
            p.row.cc=ret.value.toString()
            document.getElementById("cc").innerHTML=ret.value.toString()
          }
        });

        setEmailData(email)
        console.log(email)

      }
    })
  }


  let modifyFrom = ( from ,idPromoEmail, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo from',
      inputValue: from,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        email.map((item, index) => {
          if(item.idPromoEmail==idPromoEmail){
            item.from=ret.value.toString()
            p.row.from=ret.value.toString()
            document.getElementById("from").innerHTML=ret.value.toString()
          }
        });

        setEmailData(email)
        console.log(email)

      }
    })
  }


  let modifySubject = ( subject ,idPromoEmail, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo subject',
      inputValue: subject,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        email.map((item, index) => {
          if(item.idPromoEmail==idPromoEmail){
            item.subject=ret.value.toString()
            p.row.subject=ret.value.toString()
            document.getElementById("subject").innerHTML=ret.value.toString()
          }
        });

        setEmailData(email)
        console.log(email)

      }
    })
  }

  let modifyTypeEmail = ( typeEmail ,idPromoEmail, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo typeEmail',
      inputValue: typeEmail,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        email.map((item, index) => {
          if(item.idPromoEmail==idPromoEmail){
            item.typeEmail=ret.value.toString()
            p.row.typeEmail=ret.value.toString()
            document.getElementById("typeEmail").innerHTML=ret.value.toString()
          }
        });

        setEmailData(email)
        console.log(email)

      }
    })
  }

  let modifyMessage = ( message ,idPromoEmail, p) => {
    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Modifica ParameterValue',
      input: 'textarea',
      inputLabel: 'Inserire il nuovo message',
      inputValue: message,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'inserire almeno un valore'
        }
      }
    }).then( (ret) =>{
      if (ret.isConfirmed){
        email.map((item, index) => {
          if(item.idPromoEmail==idPromoEmail){
            item.message=ret.value.toString()
            p.row.message=ret.value.toString()
            document.getElementById("message").innerHTML=ret.value.toString()
          }
        });

        setEmailData(email)
        console.log(email)

      }
    })
  }



  let addPromoEmail = async () => {

    Swal.fire({
      showCancelButton:true,
      title:"Add PromoEmail for Promo "+idCampaign,
      html:`From:<input id="From" type="text"></br>
      CC: <input id="CC" type="text"></br>
      Subject: <input id="Subject" type="text"></br>
      TypeEmail: <input id="TypeEmail" type="text"></br>
      Message: <input id="Message" type="text">`,

      preConfirm: async function() {
        let from= document.getElementById('From').value;
        let cc= document.getElementById('CC').value;
        let subject= document.getElementById('Subject').value;
        let typeEmail= document.getElementById('TypeEmail').value;
        let message= document.getElementById('Message').value;
        if(from=="" || cc=="" || subject=="" || typeEmail=="" || message==""  ){
          const { value: newplafond } =  Swal.fire({
            title: 'Inserire tutti i valori' ,
            showCancelButton: false,
          })
        }else{

          let dati={}
          dati.idPromo=idCampaign
          dati.from=from
          dati.cc=cc
          dati.subject=subject
          dati.typeEmail=typeEmail
          dati.message=message
          await promoService.addPromoEmail(dati).then((data) => {
            if(data==1) {
              const { value: newplafond } =  Swal.fire({
                title: 'PromoEmail aggiunta' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })
            }
            else if(data==-1) {
              const {value: newplafond} = Swal.fire({
                title: "PromoEmail già esistente per questa campagna",
                showCancelButton: false,

              })
            }else{
              const { value: newplafond } =  Swal.fire({
                title: 'Errore' ,
                showCancelButton: false,
              }).then((ret) =>{
                window.location.reload();
              })

            }


          });




        }
      }
    })

  }


  let cancEmail= async (idPromoEmail)=>{

    await promoService.deletePromoEmail(idPromoEmail).then(data => {
      if(data==1) {
        const {value: newplafond} = Swal.fire({
          title: 'PromoEmail Cancellata',
          showCancelButton: false,
        }).then((ret) => {
          window.location.reload();
        })

      }else{
        const { value: newplafond } =  Swal.fire({
          title: "Errore nella cancellazione della PromoEmail" ,
          showCancelButton: false,

        })
      }


    });
  }

  let salva = async () => {

    const MySwal = withReactContent(Swal);

    const { value: newplafond } =  Swal.fire({
      title: 'Sicuro di voler salvare?',
      showCancelButton: true,

    }).then(async (ret) =>{
      if (ret.isConfirmed){

        await promoService.updatePromoEmail(email[0]).then((data)=>{

          if(data==1) {
            const { value: newplafond } =  Swal.fire({
              title: 'PromoEmail modificata' ,
              showCancelButton: false,
            }).then((ret) =>{
              window.location.reload();
            })




          }else if(data==-1){
            const { value: newplafond } =  Swal.fire({
              title: "PromoEmail da modificare non trovata" ,
              showCancelButton: false,

            })
          }else{
            const { value: newplafond } =  Swal.fire({
              title: "Errore nella modifica della PromoEmail" ,
              showCancelButton: false,

            })
          }
        });
      }
    })
  }

  return (

  <Card
    className={clsx(classes.root, className)}
    {...rest}
  >
  <PerfectScrollbar>
      <Box minWidth={1050}>
        <div id='top_button'>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // style={{ marginLeft: 16 }}
            onClick={() => {addPromoEmail()}}
          >
            Add PromoEmail
          </Button>
        </div>
      <div id='TableInConvention'>
      <DataGrid
          columns={headCells}
          rows={email}
          pageSize={50}
          rowsPerPageOptions={[50, 100, 200, 500]}
          showToolbar
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          />
      </div>
      </Box>

    </PerfectScrollbar>
    <div id='top_button'>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={salva}
      >
        Salva
      </Button>
    </div>
  </Card>
);
};


Results.propTypes = {
  className: PropTypes.string,
  email: PropTypes.array.isRequired,
  idCampaign: PropTypes.number.isRequired
};

export default Results;
